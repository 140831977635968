<script setup>
    import IconChevronDown from '~icons/my-icons/chevron-down';
    import Search from '~icons/my-icons/search';
    import CrossIcon from '~icons/my-icons/button-cross';
    import Fuse from 'fuse.js';
</script>

<template>
    <div v-click-outside-element="close" class="relative min-h-[48px] flex items-center cursor-pointer w-full select-none bg-white border border-bd rounded">
        <div @click="toggleExpanded" class="flex items-center w-full min-h-[48px]">
            <div class="w-full flex flex-wrap gap-2 py-2">
                <div v-for="user in sortedActiveClients" :key="user.uid" class="font-medium cursor-pointer flex items-center bg-gray-50 border border-gray-200 px-4 py-[6px] rounded ml-2">
                    <div class="flex items-center" @click.stop="$emit('handleRemoveUser', user)">
                        <CrossIcon class="w-3 h-3 text-gray-700" />
                        <p class="text-gray-600 pl-2 text-xs">{{ getClientName(user) }}</p>
                    </div>
                </div>
            </div>
            <IconChevronDown :class="isActive && 'rotate-180'" class="h-[20px] w-[20px] mr-3"/>
        </div>
        <div :class="!isActive && 'hidden'" class="w-full absolute left-[12px] top-[50px] z-40">
            <div class="bg-white flex row border border-gray-900 w-full" >
                <div class="flex items-center border border-b-[#DFDFDF]">
                    <Search class="ml-4 mr-2 my-3"></Search>
                    <input type="text" placeholder="Search" class="focus:border-none outline-none w-full " ref="searchbox"
                    @input="query = $event.target.value" />
                </div>
                <ul  
                    class="pl-2 w-full max-h-[204px] overflow-x-hidden overflow-y-auto z-40 bg-white">
                    <li 
                        v-for="client in filterClients"
                        :key="client.item.clientId"
                        class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate"
                        @click="handleClicked(client.item)"
                        :class="isActiveLabel(client.item.clientId) ? 'bg-brand-primary text-white hover:text-gray-900' : ''" 
                        >
                        <p @click="onClick">
                            {{ client.item.name }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['activeClients', 'allClients', 'handleRemoveUser', 'handleAddUser', 'selectedUsers', 'addUser', 'removeUser', 'isActiveLabel'],
        data: function() {
            return {
                accordionData: this.data ? this.data : null,
                isActive: false,
                query: this.propsQuery ? this.propsQuery : '',
            }
        },
        watch: {
            isActive(newShowModal) {
                if (newShowModal) {
                    this.$nextTick(() => {
                        this.$refs.searchbox.focus();
                    });
                }
            }
        },
        computed: {
            filterClients() {
                const fuse = new Fuse(this.allClients, {
                    keys: ['name']
                })
                if (this.query && this.query.length > 0) {
                    return fuse.search(this.query || "");
                }
                return this.allClients.map(val => ({
                    item: Object.assign(val, {}),
                    matches: [],
                    score: 1
                }));
            },
            sortedActiveClients() {
                return this.activeClients.sort((a, b) => this.getClientName(a).localeCompare(this.getClientName(b)));
            }
        },
        mounted() {
        },
        methods: {
            getClientName(id) {
                return this.allClients.find(client => client.clientId == id)?.name || "Client";
            },
            handleClicked(client) {
                this.$emit('handleAddUser', client.clientId);
            },
            close(){
                this.isActive = false
            },
            toggleExpanded(){
                this.isActive = !this.isActive
            },
        }
    }

</script>


<style lang="scss" scoped>

</style>