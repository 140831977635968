
<script setup>
    import DefaultUserIcon from '~icons/my-icons/user';
</script>

<template>
    <div class="font-bold text-white" :class="fontTextSize ? fontTextSize : ''">
        {{userInitials}}
    </div>
</template>

<script>

    export default {
        props: ['name', 'textSize'],
        data: function() {
            return {
                avatarName: this.name ? this.name : ' ',
                fontTextSize: this.textSize ? this.textSize : 'text-lg'
            }
        },
        computed: {
            userInitials() {
                const initString = this.avatarName;
                const name = initString.split(' ');
                return name.length > 1 ? `${name[0].charAt(0).toUpperCase()}${name[1].charAt(0).toUpperCase()}` : `${name[0].charAt(0).toUpperCase()}${name[0].charAt(1).toUpperCase()}`
            }
        },
        watch: {
            name: function(newVal, oldVal) {
                this.avatarName = newVal;
            }
        },
    }

</script>


<style lang="scss" scoped>

</style>