
<script setup>
    import UserAvatar from './UserAvatar.vue';
</script>

<template>
    <div v-if="type" class="flex items-center justify-between">
        <router-link class="flex items-center" v-if="userId" :to="{ name: type == 'clientPageAdmin' ? 'Admin Users Client Page - User profile' : 'Admin Contacts Client Page - User profile', params: { userid: userId }}">
            <span class="mr-4 overflow-hidden bg-black rounded-full bg-center bg-cover inline-flex items-center justify-center"
                :style="{ backgroundImage: `url(${imgSrc})` }"
                :class="[size == 's' ? 'w-[36px] h-[36px] text-xs' : 'w-[64px] h-[64px] text-lg']"
            >
                <UserAvatar v-if="!imgSrc" :name="topText" :text-size="size == 's' ? 'text-xs' : ''" />
            </span>
            <div>
                <span :class="[size == 's' ? 'mb-0' : 'mb-1']" class="text-grey-900 text-xs block">{{topText}}</span>
                <span class="block text-grey-400 text-xs">{{bottomText}}</span>
            </div>
        </router-link>
        <div v-else class="flex items-center">
            <span class="mr-4 overflow-hidden bg-black rounded-full bg-center bg-cover inline-flex items-center justify-center"
                :style="{ backgroundImage: `url(${imgSrc})` }"
                :class="[size == 's' ? 'w-[36px] h-[36px]' : 'w-[64px] h-[64px]']"
            >
                <UserAvatar v-if="!imgSrc" :name="topText" :text-size="size == 's' ? 'text-xs' : ''" />
            </span>
            <div>
                <span :class="[size == 's' ? 'mb-0' : 'mb-1']" class="text-grey-900 text-xs block">{{topText}}</span>
                <span class="block text-grey-400 text-xs">{{bottomText}}</span>
                <span class="block text-grey-400 text-xs">{{userId}}</span>
            </div>
        </div>
    </div>
    <div v-else class="flex items-center justify-between">
        <router-link class="flex items-center" v-if="userId" :to="{ name: 'Admin - User profile', params: { userid: userId }}">
            <span class="mr-4 overflow-hidden bg-black rounded-full bg-center bg-cover inline-flex items-center justify-center"
                :style="{ backgroundImage: `url(${imgSrc})` }"
                :class="[size == 's' ? 'w-[36px] h-[36px] text-xs' : 'w-[64px] h-[64px] text-lg']"
            >
                <UserAvatar v-if="!imgSrc" :name="topText" :text-size="size == 's' ? 'text-xs' : ''" />
            </span>
            <div>
                <span :class="[size == 's' ? 'mb-0' : 'mb-1']" class="text-grey-900 text-xs block">{{topText}}</span>
                <span class="block text-grey-400 text-xs">{{bottomText}}</span>
            </div>
        </router-link>
        <div v-else class="flex items-center">
            <span class="mr-4 overflow-hidden bg-black rounded-full bg-center bg-cover inline-flex items-center justify-center"
                :style="{ backgroundImage: `url(${imgSrc})` }"
                :class="[size == 's' ? 'w-[36px] h-[36px]' : 'w-[64px] h-[64px]']"
            >
                <UserAvatar v-if="!imgSrc" :name="topText" :text-size="size == 's' ? 'text-xs' : ''" />
            </span>
            <div>
                <span :class="[size == 's' ? 'mb-0' : 'mb-1']" class="text-grey-900 text-xs block">{{topText}}</span>
                <span class="block text-grey-400 text-xs">{{bottomText}}</span>
                <span class="block text-grey-400 text-xs">{{userId}}</span>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['imageUrl', 'topText', 'bottomText', 'userId', 'size', 'type'],
        data: function() {
            return {
                imgSrc: this.imageUrl,
                topText: this.topText ? this.topText : '',
                bottomText: this.bottomText ? this.bottomText : '',
                userId: this.userId ? this.userId : null,
            }
        },
        methods: {
            
        }
    }

</script>


<style lang="scss" scoped>

</style>