<script setup>
    
</script>

<template>
    <div class="flex c-bar-overview w-full items-center" :class="[this.styling ? 'c-bar-overview--' + this.styling : '']">
        <div class="c-bar-overview__container bg-grey-100 rounded-full flex-1 flex flex-col overflow-hidden">
            <div class="c-bar-overview__bar-wrapper h-[26px] relative flex">
                <div ref="mainBar" 
                    :class="[barDataExceed > 0 ? 'rounded-l' : 'rounded-full']"
                    class="bg-green-600 c-bar-overview__bar h-[26px] overflow-hidden min-w-[64px] text-white font-bold w-[64px] px-4 py-1 justify-end inline-flex items-center transition-all duration-700"
                >
                    <span class="z-10 text-xs" v-if="barDataExceed == 0">{{barDataUsed}}</span>
                </div>
                <span v-if="barDataExceed > 0" class="w-[2px] bg-transparent"></span>
                <div v-if="barDataExceed > 0" ref="exceedBar" class="c-bar-overview__bar min-w-[64px] rounded-r h-[26px] bg-red-400 w-16 text-right px-4 inline-flex items-center justify-end text-white font-bold transition-all duration-700 delay-500">
                    <span class="z-10 text-xs">{{barDataExceed}}</span>
                </div>
            </div>
        </div>
        <div class="flex items-center flex-shrink-0 ml-6">
            <div class="c-bar-overview__total text-center px-1 min-w-[140px]">
                <div class="c-bar-overview__total__label block text-grey-600 text-xs">Retainer</div>
                <div class="c-bar-overview__total__number flex items-center text-center justify-center w-full">
                    <span class="block text-lg font-medium">{{barDataAllowed}}hr<span v-if="barDataAllowed > 1">s</span> 
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';

    export default {
        data() {
            return {
                barDataUsed: 0,
                barDataAllowed: 30,
                barDataExceed: 0
            }
        },
        props: ['data'],
        created() {
            const allowed = this.data?.hours ? this.data?.hours : 30;
            this.barDataAllowed = allowed;
            this.barDataUsed = this.data?.hoursUsed ? this.data?.hoursUsed : 0;
            if(this.data?.hoursUsed > allowed) {
                this.barDataExceed = (this.data?.hoursUsed - allowed).toFixed(2);
            }
        },
        mounted() {
            setTimeout(() => {
                // Animate bars
                if(this.barDataExceed > 0) {
                    const exceededPercentageToTarget = (this.barDataExceed / this.barDataUsed) * 100;
                    this.$refs.mainBar ? this.$refs.mainBar.style.width = 100 - exceededPercentageToTarget + '%' : '';
                    this.$refs.exceedBar ? this.$refs.exceedBar.style.width = 'calc(' + exceededPercentageToTarget + '% - 2px)' : '';         // NOTE: 2px for the divider
                } else {
                    const percentageToRetainer = (this.barDataUsed / this.barDataAllowed) * 100;
                    this.$refs.mainBar ? this.$refs.mainBar.style.width = percentageToRetainer + '%' : '';
                }
                this.initTooltip(this.$refs.mainBar, 'Billable');
                this.initTooltip(this.$refs.exceedBar, 'Exceeded');
            }, 100);
        },
        methods: {
            initTooltip(ref, content) {
                tippy(ref, {
                    content: content,
                    placement: 'top'
                });
            }
        }
    }

</script>


<style lang="scss" scoped>
    .tippy-content {
        padding: 1rem;
        margin: 0;
    }
</style>