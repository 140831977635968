<script setup>
import DataBarSimple from "./DataBarSimple.vue";
import TaskList from "./TaskList.vue";
</script>

<template>
    <div class="mb-4 border-b border-bd last:border-0 last:mb-0">
        <div class="px-12 py-4 flex items-center">
            <div class="max-w-[200px] w-full">
                <div class="text-lg font-medium">{{ retainer?.name }}</div>
            </div>
            <div class="flex items-center flex-1 ml-10 justify-between">
                <DataBarSimple :data="retainer" />
                <div>
                    <button @click="handleAccordion" v-if="retainer?.taskList"
                        class="w-10 h-10 border-2 rounded-full border-grey-900 text-3xl flex items-center justify-center ml-6">
                        <span v-if="!isActive">+</span>
                        <span v-else>-</span>
                    </button>
                </div>
            </div>
        </div>
        <TaskList v-if="isActive && retainer?.taskList" :data="retainer.taskList" />
    </div>
</template>

<script>

export default {
    props: ['retainerData'],
    data: function () {
        return {
            barData: this.retainerData ? this.retainerData.hours : {},
            retainer: this.retainerData ? this.retainerData : {},
            isActive: false,
        }
    },
    created() {
    },
    methods: {
        handleAccordion() {
            this.isActive = !this.isActive;
        }
    }
};

</script>


<style lang="scss" scoped></style>