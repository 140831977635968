<script setup>
    import IconMenu from '~icons/my-icons/chevron-down';
    import CheveronDown from '~icons/my-icons/chevron-down'
</script>

<template>
    <div ref="dropdown" class="relative js-dropdown flex">
        <span v-if="isDisabled" :class="dropdownClass" class="cursor-default">
            <p v-if="text">{{ text }}</p>
            <IconMenu v-else class="w-3 h-3" />
        </span>
        <router-link v-if="!isDisabled" class="inline-block" type="button" :class="dropdownClass" :to="to">
            <p v-if="text">{{ text }}</p>
            <IconMenu v-else class="w-3 h-3" />
        </router-link>
        <button v-if="text == 'Edit' || text == 'View report'" @click="handleDropdown" class="flex cursor-pointer border  border-l-0 h-[30px] border-grey-200 items-center rounded-r bg-grey-50 py-4 px-2">
            <div>
                <CheveronDown class="h-3 w-3" />
            </div>
        </button>
        <div ref="dropdown" :class="open ? 'flex' : 'hidden'" class="absolute text-sm right-0 top-full z-[10] bg-white border flex-col rounded w-40 js-dropdown__menu" @click="closeDropdown">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            dropdownClass: "",
            data: {},
            to: {},
            type: "",
            text: "",
            isDisabled: false
        },
        data: function () {
            return {
                open: false,
                accordionData: this.data ? this.data : null
            }
        },
        methods: {
            handleDropdown() {
                this.open = !this.open;
            },
            closeDropdown() {
                this.open = false;
            },
            closeOutside(event) {
                const targetElement = this.$refs.dropdown;
                if (targetElement && !targetElement.contains(event.target)) {
                    this.open = false;
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.closeOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeOutside);
        }
    }

</script>
