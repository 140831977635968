<script setup>

    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import Loader from "../objects/Loader.vue";
    import { getAuth, tenantDatabase, getCurrentTeanantID } from "../../tenant";
    import Tick from '~icons/my-icons/tick';
    import axios from "axios";
    import BgImg from '../../assets/images/login-bg.png';
    import { ChevronLeftIcon } from '@heroicons/vue/outline';
    
	import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
  <div class="pt-20 pb-20 flex-1 min-h-screen flex items-center w-full bg-cover sm:bg-auto bg-no-repeat bg-center bg-purple-900" :style="{ backgroundImage: `url('${BgImg}')`}">
    <Container class="flex-1 w-full">
        <div class="border border-bd rounded-lg border-solid p-8 sm:p-16 bg-white u-content-tight w-full">
            <form v-if="!isSubmitted" ref="form" @submit="submitForm">
                <h1 class="text-2xl font-bold mb-16 text-center">
                    Forgot Password
                </h1>
                <div class="mb-4 relative">
                    <label>
                        <input required @input="(e) => email = e.target.value" :value="email" id="email" type="email" class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Email address" /> 
                    </label>
                </div>
                <div class="mb-4 text-red-500 text-sm" v-if="formError.length > 0">
                    {{formError}}
                </div>
                <button class="mt-4 py-3 px-10 bg-orange-500 rounded-lg text-white w-full" type="button" @click="submitForm" :disabled="isLoading">
                    <span class="flex justify-center items-center" v-if="isLoading">
                        <span class="mr-2">Loading...</span>
                        <Loader />
                    </span>
                    <span v-else>
                        Submit
                    </span>
                </button>
                <router-link class="flex items-center mt-8 text-link text-sm" :to="{ name: 'Login'}">
                    <ChevronLeftIcon class="w-4 h-4 " />
                    <p class="ml-1">Go back</p>
                </router-link>
            </form>
            <div v-else class="w-full flex-1">
                <h1 class="text-2xl font-bold mb-12 text-center">
                    <span class="text-white mr-3 rounded-full bg-green-600 w-10 h-10 inline-flex items-center justify-center text-lg">
                        <Tick />
                    </span>
                    Email sent
                </h1>
                <p>An email containing a link to reset your password has been sent to the email address that you have entered.</p>
                <p class="mt-4">Please follow the steps provided in the email to reset your password.</p>
                <p class="mt-4">Click <router-link class="underline text-blue-500" :to='{name: "Login"}'>here</router-link> to go back to the Login page.</p>
            </div>
        </div>
    </Container>
  </div>
</template>

<script>

    export default {
        data() {
            return {
                email: "",
                formError: '',
                isLoading: false,
                isSubmitted: false
            }
        },
        mounted() {},
        methods: {
            async submitForm(e) {
                let tenantId = await getCurrentTeanantID()
                e.preventDefault();
                this.formError = '';
                const form = this.$refs.form;
                if(form.reportValidity()) {

                    this.isLoading = true;
                
                    axios.get(`${this.$root.apiBaseUrl}/account/forgot-password?email=${this.email}&tenantid=${tenantId}`, {
                        headers: {                                
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        LogAuditLog({
                                type: "User",
                                object: "ForgotPassword",
                                action: "run",
                                data: {
                                        email: this.email
                                }
                        })
                        this.isLoading = false;
                        this.isSubmitted = true;
                    }).catch(err => {
                        this.isLoading = false;
                        this.formError = 'An error has occured. Please check that you have entered a valid and existing email address and try again.';
                    });
                
                }
            }
        }
  }
</script>

<style lang="scss" scoped>
    .u-content-tight {
        max-width: 562px;
        margin: 0 auto;
    }
</style>
