<script setup>
    import initCurrentUserSession from '../../helpers/InitCurrentUserSession';
    import { getAuth } from "../../tenant";
    import { signOut } from 'firebase/auth';
    import store from '../../store';
</script>

<template>
  <slot v-if="loaded"></slot>
</template>

<script>
    export default {
        data() {
            return {
                loaded: false,
                logoutClicked: false
            }
        },
        async mounted() {
            
            const auth = await getAuth();
            const once = auth.onAuthStateChanged(user => {
                if(user) {  
                    const existingExpiry = parseInt(window.localStorage.getItem('webcodaRetainerPortalExpTime'));
                    const timeNow = new Date();
                    if(!existingExpiry) {
                        this.continue(user);
                    } else {
                        timeNow < new Date(existingExpiry) ? this.continue(user) : this.logout(true);
                    }
                } else {
                    this.isRedirect = true;
                    this.logout();
                }
            });
            once();
        },
        methods: {
            continue(user) {
                window.localStorage.setItem('webcodaRetainerPortalExpTime', user.stsTokenManager.expirationTime);
                if(Object.keys(store.getters.getUserState).length === 0) {
                    initCurrentUserSession(this).then(res => {
                        this.loaded = true;
                    });
                } else {
                    this.loaded = true;
                }
            },
            async logout(isExpired) {
                
                const routeName = this.$route.name;
                const url = new URL(window.location.href);
                const retainerId = url.searchParams.get("retainerId");
                const date = url.searchParams.get("date");
                const auth = await getAuth();
                signOut(auth).then(() => {
                    sessionStorage.setItem("latest-url-before-logout", window.location.pathname)
                    store.commit('updateUserState', {});
                    store.commit('updateAllProjects', []);
                    store.commit('updateRetainerDetails', null);
                    store.commit('updateAppSettings', null);
                    store.commit('updateViewAsClient', null);
                    if(isExpired) {
                        this.$router.push({
                            name: "Login",
                            params: {
                                notificationType: 'alert',
                                notification: 'Session expired. Please log in again to continue.'
                            },
                            query: { redirect: routeName }  
                        });
                    } else {
                        const routerObj = {
                            name: "Login",
                            params: {
                                notificationType: 'success',
                                notification: 'You have been logged out of the portal.'
                            },
                            query: {}
                        }

                        this.isRedirect ? routerObj.query.redirect = routeName : '';
                        retainerId ? routerObj.query.retainerId = retainerId : '';
                        date ? routerObj.query.date = date : '';

                        setTimeout(()=> {
                            this.$router.push(routerObj);
                        }, 1);
                    }
                });
            }
        }
    }
</script>
