<script setup>
    import store from "../../store";
    import { getAuth, tenantDatabase } from "../../tenant";
    import { getApp } from "firebase/app";
    import { getFunctions, httpsCallable } from "firebase/functions";
    import { addDays } from 'date-fns'
    import { getDatabase, ref, update, push, child } from "firebase/database";

    import IconWarning from '~icons/my-icons/warning'

</script>

<template>
    <div class="relative o-dropdown js-dropdown flex">
        <button  type="button">
            <IconWarning class="mr-1"/>
        </button>
        <ul 
            ref="userOptionsDropdown" 
            class="o-dropdown__list min-w-[140px] box-shadow absolute mt-2 top-full right-0 border border-bd rounded text-left z-10 pointer-events-none"
            :class="disabled ? 'bg-bd' : 'bg-white'"
        >
            <li class="border-b border-bd last:border-none pointer-events-none">
                <p class="py-2 px-3 block w-full text-left font-bold text-gray-900 !pointer-events-none">Snooze alert for:</p>
            </li>
            <li class="border-b border-bd last:border-none pointer-events-auto cursor-pointer">
                <button class="py-2 px-3 block w-full text-left text-gray-600 font-medium" @click="saveChanges(clientId, 7)" :class="disabled && 'disabled'">7 days</button>
            </li>
            <li class="border-b border-bd last:border-none pointer-events-auto cursor-pointer">
                <button class="py-2 px-3 block w-full text-left text-gray-600 font-medium cursor-pointer" @click="saveChanges(clientId, 30)" :class="disabled && 'disabled'">30 days</button>
            </li>
            <li class="border-b border-bd last:border-none pointer-events-auto cursor-pointer">
                <button class="py-2 px-3 block w-full text-left text-gray-600 font-medium cursor-pointer" @click="saveChanges(clientId, 90)" :class="disabled && 'disabled'">90 days</button>
            </li>
            <li class="border-b border-bd last:border-none pointer-events-auto cursor-pointer">
                <button class="py-2 px-3 block w-full text-left text-gray-600 font-medium cursor-pointer" @click="saveChanges(clientId, 365)" :class="disabled && 'disabled'">1 year</button>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        props: ['userData', 'hideDelete', 'hideResendEmail', 'disabled', 'clientId', 'snoozeType'],
        created() {
        },
        async mounted() {
            const auth = await getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.authUserUID = user.uid;
                }
            });
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown') === null) {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                }
            });
        },
        computed: {
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin'
            },
            devMode() {
                return this.$root.env === "development"
            },
            isSelf() {
                return this.authUserUID === this.parseUserId
            },
            isBothAdmin() {
                const currentUser = store.getters.getUserState;
                const viewAsClient = store.getters.getViewAsClient;
                if(viewAsClient) {
                    return false;
                }
                return currentUser.permission === 'admin' || currentUser.permission === 'client-admin';
            },
            showResendConfirmButton() {
                if(this.enableResendConfirmation) {
                    return true;
                } else {
                    if(this.parseUserData.userSettings?.isVerified || this.parseUserData.userSettings?.lastLoginTime) {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
        },
        methods: {
            handleVerifyUser() {
                const { parseUserId } = this;
                const app = getApp();
                const functions = getFunctions(app);
                const verifyUser = httpsCallable(functions, "verifyUser");
                verifyUser({ uid: parseUserId, data: { uid: parseUserId } }).then(result => {
                    alert('User now verified');
                }).catch(err => {
                    alert('Error');
                    console.log(err)
                });
            },
            deleteClick(e) {
                e.target.closest('.js-dropdown').classList.remove('is-active');
                this.$emit('handleDeleteModal', this.userData);
            },
            handleOpenDropdown(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            saveChanges(clientId, clickedDays) {
                const db = tenantDatabase();
                const clientRef = ref(db, `clients/${clientId}`);

                if(this.snoozeType == 'quote'){
                    update(clientRef, {
                        snoozeQuoteRateAlert: addDays(new Date(), clickedDays)
                    });
                } else if(this.snoozeType == 'nextMeeting') {
                        update(clientRef, {
                        snoozeNextMeetingAlert: addDays(new Date(), clickedDays)
                    });
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .o-dropdown {
        &.is-active {
            .o-dropdown__list {
                display: block;
            }
        }
        &__list {
            display: none;
        }
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

</style>