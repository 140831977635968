<script setup>
import { getAuth, tenantDatabase } from "@/tenant";
import axios from "axios";
import Editor from '@tinymce/tinymce-vue'
import Datepicker from '@vuepic/vue-datepicker';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";

import { getDatabase, get, child, ref, onValue, update, query, orderByChild, orderByKey, equalTo } from "firebase/database";

import Expander from '@/components/objects/Expander.vue';
import DashboardEditItem from '@/components/DashboardEditItem.vue';

import updateClientDetails from '@/helpers/UpdateClientDetails';
import updateUserManagedClients from '@/helpers/UpdateUserManagedClients';
import staticData from '@/data/staticData.js';
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp, getApp } from "firebase/app";

import generateUUID from '@/helpers/UidGeneratorHelper';


import store from "@/store";

import GetFormattedClient from "@/helpers/GetFormattedClient";
import LogAuditLog from "@/helpers/logAuditLog";

import ConfirmationDialog from '@/components/containers/ConfirmationDialog.vue';

import CircleTick from '~icons/my-icons/green-circle-tick';
import UserAvatar from '@/components/objects/UserAvatar.vue';

import IconHappinessVeryHappy from '~icons/my-icons/happiness-v-happy';
import IconHappinessHappy from '~icons/my-icons/happiness-happy';
import IconHappinessModerate from '~icons/my-icons/happiness-moderate';
import IconHappinessSad from '~icons/my-icons/happiness-sad';
import IconHappinessVerySad from '~icons/my-icons/happiness-v-sad';

import { Skeletor } from "vue-skeletor";
</script>

<template>
    <div
        class="fixed top-0 left-0 w-screen min-h-screen h-full overflow-x-hidden overflow-y-auto bg-grey-900/40 animate-fade-in z-[100]">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="w-[800px] bg-grey-50 ml-auto min-h-screen flex flex-col z-[2] relative animate-slide-in">
            <div class="px-6 py-5 border-b border-grey-200 bg-white sticky top-0 z-50">
                <h3 v-if="!isLoading" class="text-lg" :class="!details.name && 'font-bold'"><span class="font-bold"
                        v-if="details.name">Client: </span>{{ details.name ? details.name : "Add new client" }}</h3>
                <Skeletor v-else class="rounded" width="500" height="28" />
            </div>
            <div class="border-b border-grey-200 flex bg-white sticky top-[69px] z-50">
                <template v-if="!isLoading">
                    <button class="flex-1 py-4 px-5 text-sm text-center relative" type="button"
                        :class="{ 'after:bottom-0 after:absolute after:w-full after:left-0 after:h-[3px] after:bg-orange-500 after:block font-bold': activePanel === 'details' }"
                        @click="activePanel = 'details'">Client details</button>
                    <button v-if="details.clientId"
                        class="disabled:cursor-not-allowed disabled:shadow-inner disabled:bg-grey-100 disabled:text-grey-300 flex-1 py-4 px-5 text-sm text-center relative"
                        type="button"
                        :class="{ 'after:bottom-0 after:absolute after:w-full after:left-0 after:h-[3px] after:bg-orange-500 after:block font-bold': activePanel === 'Key contacts' }"
                        @click="activePanel = 'Key contacts'">Key contacts</button>
                    <button
                        class="disabled:cursor-not-allowed disabled:shadow-inner disabled:bg-grey-100 disabled:text-grey-300 flex-1 py-4 px-5 text-sm text-center relative"
                        type="button"
                        :class="{ 'after:bottom-0 after:absolute after:w-full after:left-0 after:h-[3px] after:bg-orange-500 after:block font-bold': activePanel === 'services' }"
                        @click="activePanel = 'services'">Services</button>
                </template>

                <template v-else>
                    <Skeletor class="rounded my-4 mx-20 border-b-2" width="100%" height="20" />
                    <Skeletor class="rounded my-4 mx-20" width="100%" height="20" />
                </template>
            </div>
            <div class="p-6" v-if="activePanel === 'loading'">
                <Skeletor class="rounded" width="100%" height="600" />
                <Skeletor class="rounded mt-4" width="100%" height="300" />
            </div>
            <div class="p-6" v-if="activePanel === 'details'">
                <Expander :title="'Details'" :opened="true">
                    <div class="text-sm">
                        <DashboardEditItem v-model:val="details.name" :init-value="details.name" theme="dark"
                            name="name" label="Client name *" input-type="text" />
                            <div v-if="clientNameError" class="flex ml-[125px] pl-4 mb-2 text-error text-sm">{{ clientNameError }}</div>
                        <DashboardEditItem v-model:val="details.description" :init-value="details.description"
                            theme="dark" name="description" label="Description" input-type="textarea" />
                        <DashboardEditItem v-model:val="details.logo" :init-value="details.logo" theme="dark"
                            name="logo" label="Logo" input-type="file" />
                        <DashboardEditItem v-model:val="details.urls" :init-value="details.urls" theme="dark"
                            name="urls" label="URL(s)" input-type="url" />
                        <DashboardEditItem v-model:val="details.rate" :init-value="details.rate" theme="dark"
                            name="rate" label="Quote rate <br>(per hour)" input-type="number" />
                        <fieldset class="mb-3 last:mb-0">
                            <div class="flex">
                                <legend class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold">
                                    Client happiness
                                </legend>
                                <div class="inline-flex p-3 mb-3 last:mb-0 border border-grey-200 rounded">
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="v-sad" v-model="details.happiness" name="happiness" type="radio"
                                            class="sr-only peer" value="v-sad" />
                                        <label for="v-sad"
                                            class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessVerySad />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="sad" v-model="details.happiness" name="happiness" type="radio"
                                            class="sr-only peer" value="sad" />
                                        <label for="sad"
                                            class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessSad />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="moderate" v-model="details.happiness" name="happiness" type="radio"
                                            class="sr-only peer" value="moderate" />
                                        <label for="moderate"
                                            class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessModerate />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="happy" v-model="details.happiness" name="happiness" type="radio"
                                            class="sr-only peer" value="happy" />
                                        <label for="happy"
                                            class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessHappy />
                                        </label>
                                    </div>
                                    <div class="inline-flex mr-1 last:mr-0">
                                        <input id="v-happy" v-model="details.happiness" name="happiness" type="radio"
                                            class="sr-only peer" value="v-happy" />
                                        <label for="v-happy"
                                            class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                                            <IconHappinessVeryHappy />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div id="notes" class="mb-3 last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">
                                Notes</div>
                            <div class="flex-1">
                                <Editor v-model="details.notes"
                                    api-key="2m9gvnt4gi3wlqn57wwmcn24llv29udji8c93chueqi45q6k" :init="{
                    menubar: false,
                    plugins: 'lists link',
                    toolbar: 'link bullist',
                    min_height: 200,
                    height: 200
                }" />
                            </div>
                        </div>
                        <div id="goals" class="mb-3 last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">
                                Goals</div>
                            <div class="flex-1">
                                <Editor v-model="details.goals"
                                    api-key="2m9gvnt4gi3wlqn57wwmcn24llv29udji8c93chueqi45q6k" :init="{
                    menubar: false,
                    plugins: 'lists link',
                    toolbar: 'link bullist',
                    min_height: 200,
                    height: 200
                }" />
                            </div>
                        </div>
                    </div>
                </Expander>
                <Expander :title="'Client meetings'" class="mt-4" :opened="true">
                    <div class="border-b border-grey-200 pb-4">
                        <DashboardEditItem v-model:val="details.meetingFrequency" :init-value="details.meetingFrequency"
                            theme="dark" name="meetingFrequency" label="Meeting frequency goal" input-type="select"
                            :select-list="staticData.dateFrequencies" />
                    </div>
                    <div class="py-4 text-grey-500 text-sm">
                        Numerous future meetings can be added, but only one historic meeting is stored to measure
                        against meeting
                        frequency set above.
                    </div>
                    <div class="flex mb-4 last:mb-0 border border-blue-100 rounded p-4 bg-blue-50">
                        <div class="min-w-[109px] grow-0 shrink basis-0 text-xs mr-3 inline-block font-bold mt-4">
                            Meeting</div>
                        <div>
                            <div v-for="(item, index) in details.meetings" :key="index"
                                class="grid grid-cols-5 gap-2 mb-2 last:mb-0">
                                <div class="col-span-2">
                                    <Datepicker :autoApply="true" v-model="item.date" :enableTimePicker="false"
                                        :format="'dd MMMM yyyy'"
                                        :minDate="new Date(new Date().setFullYear(new Date().getFullYear() - 1))"
                                        :maxDate="new Date(new Date().setFullYear(new Date().getFullYear() + 1))"
                                        :clearable="false" :menuClassName="'c-datepicker--dropdown'" hide-input-icon
                                        placeholder="Date..." />
                                </div>
                                <div class="col-span-2">
                                    <Datepicker v-model="item.time" :is-24="false" :time-picker="true" :autoApply="true"
                                        :enableTimePicker="true" :clearable="false" :minutes-increment="5"
                                        :menuClassName="'c-datepicker--dropdown'" hide-input-icon
                                        placeholder="Time..." />
                                </div>
                                <div class="">
                                    <button :disabled="details.meetings.length == 1" @click="removeMeeting(index)"
                                        type="button"
                                        class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-t border-grey-200">
                        <button @click="addNewMeeting()"
                            class="font-medium inline-flex items-center justify-center mt-4 border border-grey-900 rounded py-2 px-4 text-sm"
                            type="button">Add new meeting</button>
                    </div>
                </Expander>
            </div>

            <div v-if="details.clientId && activePanel === 'Key contacts'" class="p-6 space-y-4 mb-56">
                <Expander :title="'Internal users'" class="" :opened="true">
                    <div class="py-4 text-grey-500 text-sm border-b border-gray-100">
                        Designate which user is responsible internally for account management for this client.
                    </div>
                    <DashboardEditItem
                        theme="dark"
                        name="accountManagers"
                        label="Account managers"
                        input-type="userSelect"
                        userSelectType="admin"
                        :allUsers="mappedClientManagers"
                        :isActiveLabel="isActiveLabelManager"
                        :activeUsers="mappedActiveClientManagers"
                        :clientId="details.clientId"
                        @handleAddUser="userId => addUserClientManager(userId)"
                        @handleRemoveUser="userId => removeUserClientManager(userId)" />
                </Expander>
                <Expander :title="'Contact types'" class="" :opened="true">
                    <div class="py-4 mb-4 text-grey-500 text-sm border-b border-gray-100">
                        Designate key contacts for this client.
                    </div>
                    <DashboardEditItem theme="dark"
                        name="primaryContacts"
                        label="Primary contacts"
                        input-type="clientSelect"
                        :filterUsers="mappedClientUsers"
                        :isActiveLabel="isActiveLabelPrimary"
                        :filteredClientAccounts="filteredClientContacts('primary-contact')"
                        @handleAddUser="userId => addUser(userId, 'primary-contact')"
                        @handleRemoveUser="userId => removeUser(userId, 'primary-contact')" />
                    <DashboardEditItem theme="dark" name="accountsContacts" label="Accounts contacts"
                        input-type="clientSelect"
                        :filterUsers="mappedClientUsers"
                        :isActiveLabel="isActiveLabelAccounts"
                        :filteredClientAccounts="filteredClientContacts('accounts-contact')"
                        @handleAddUser="userId => addUser(userId, 'accounts-contact')"
                        @handleRemoveUser="userId => removeUser(userId, 'accounts-contact')" />
                    <DashboardEditItem
                        theme="dark"
                        name="itContacts"
                        label="IT Contacts"
                        input-type="clientSelect"
                        :filterUsers="mappedClientUsers"
                        :isActiveLabel="isActiveLabelItContacts"
                        :filteredClientAccounts="filteredClientContacts('it-contact')"
                        @handleAddUser="userId => addUser(userId, 'it-contact')"
                        @handleRemoveUser="userId => removeUser(userId, 'it-contact')" />
                </Expander>
            </div>
            <div v-if="activePanel === 'services'" class="p-6">
                <Expander :title="'Retainers'" class="" :opened="true">
                    <div class="border-t border-grey-200 mt-6 pt-6 first:pt-0 first:mt-0 first:border-none"
                        v-for="(retainer, retainerIndex) in details.retainers" :key="retainer.id">
                        <div class="w-full">
                            <DashboardEditItem v-model:val="retainer.id" :init-value="retainer.id" theme="dark"
                                @onChange="(id) => updateRetainerPlansPreset(id, retainerIndex)"
                                :name='"retainerId-" + retainerIndex'
                                label="Connect to Asana Project (to sync retainer hours)" input-type="select"
                                :select-list="formattedRetainers" type="column" />
                        </div>
                        <div class="relative mb-4">
                            <CircleTick v-if="retainer.retainerRate && retainer.retainerRate?.length > 0"
                                class="absolute right-0 top-1/2 text-orange-500 mr-3 w-6 text-[20px] flex-shrink-0" />
                            <DashboardEditItem v-model:val="retainer.retainerRate" :init-value="retainer.retainerRate"
                                theme="dark" :full-width="true" :name='"retainerRate-" + retainerIndex'
                                label="Override default rate (per hour)" input-type="number" />
                        </div>
                        <div class="space-x-3 flex border-b border-grey-200 mb-4 pb-1">
                            <DashboardEditItem v-model:val="retainer.value" :init-value="retainer.value" theme="dark"
                                :full-width="true" :name='"value-" + retainerIndex' label="Monthly value"
                                input-type="text" />
                            <DashboardEditItem v-model:val="retainer.startDate" :init-value="retainer.startDate"
                                theme="dark" :full-width="true" :name='"startDate-" + retainerIndex' label="Start date"
                                input-type="date" />
                            <DashboardEditItem v-model:val="retainer.contractExpiry"
                                :init-value="retainer.contractExpiry" theme="dark" :full-width="true"
                                :name='"contractExpiry-" + retainerIndex' label="Contract expiry" input-type="date" />
                        </div>
                        <div class="flex flex-col mb-4 last:mb-0 border border-blue-100 rounded p-4 bg-blue-50"
                            v-for="(plan, planIndex) in retainer.plans" :key="planIndex">
                            <div class="grid grid-cols-3 gap-2 mb-2 last:mb-0 mt-4">
                                <div>
                                    <DashboardEditItem :name="`date-from-${retainerIndex}-${planIndex}`"
                                        label="Rollover current plan from" inputType="date"
                                        v-model:val="plan.rolloverFrom" :initValue="plan.rolloverFrom"
                                        :menuClassName="'c-datepicker--dropdown'" type="column" theme="dark" />
                                </div>
                                <div>
                                    <DashboardEditItem v-model:val="plan.monthlyHours" :init-value="plan.monthlyHours"
                                        theme="dark" :name="`monthly-hours-${retainerIndex}-${planIndex}`"
                                        label="Monthly hours" input-type="text" type="column" />
                                </div>
                                <div>
                                    <DashboardEditItem v-model:val="plan.rolloverHours" :init-value="plan.rolloverHours"
                                        theme="dark" :name="`rollover-${retainerIndex}-${planIndex}`"
                                        label="Max rollover hours" input-type="text" type="column" />
                                </div>
                            </div>
                            <div>
                                <DashboardEditItem v-model:val="plan.comment" :init-value="plan.comment" theme="dark"
                                    :name="`retainer-comment-${retainerIndex}-${planIndex}`" label="Comment"
                                    input-type="textarea" type="column" />
                            </div>
                            <div>
                                <button
                                    class="mt-6 bg-grey-50 border border-grey-200 rounded px-3 py-1.5 text-xs flex items-center justify-center"
                                    @click="removeRetainerPlan(retainerIndex, planIndex)">Remove Retainer Plan</button>
                            </div>
                        </div>
                        <button @click="addNewRetainerPlan(retainerIndex)" type="button"
                            class="font-medium inline-flex items-center justify-center mt-4 border-2 border-grey-900 rounded py-2 px-4 text-sm">
                            Add plan
                        </button>
                        <div class="text-right">
                            <button @click="removeRetainer(retainer.id)" type="button"
                                class="text-sm transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-4">
                                Delete Retainer
                            </button>
                        </div>
                    </div>

                    <div class="border-t border-grey-200 mt-4">
                        <button @click="addNewRetainer()" type="button"
                            class="font-medium inline-flex items-center justify-center mt-4 border-2 border-grey-900 rounded py-2 px-4 text-sm">
                            Add retainer
                        </button>
                    </div>

                </Expander>
                <ConfirmationDialog :isActive="deleteModalActive"
                    headerText="Deleting this retainer won't take effect until you save changes." descriptionText=""
                    confirmButtonText="Delete retainer" @handleModalClose="handleDeleteRetainerModalClose"
                    @handleModalConfirm="handleDeleteRetainerModalConfirm" />
                <!-- <Expander
                    :title="'Hosting'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'UAT'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Security scan'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Site audit'"
                    class="mt-4"
                >
                
                </Expander>
                <Expander
                    :title="'Staging'"
                    class="mt-4"
                >
                
                </Expander> -->
            </div>
            <div class="px-6 py-3 border-t border-grey-200 bg-white text-right sticky bottom-0 z-[2] mt-auto">
                <button @click="closeEditClient" type="button" class="mr-6 text-blue-500 font-medium">Cancel</button>
                <button v-if="!disableSave" @click="saveChanges" :disabled="disableSave" type="button"
                    class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                    <span v-if="!isEditClient">Add client</span>
                    <span v-else >Save</span>
                </button>
                <button v-else :disabled="disableSave" type="button"
                    class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                    <span v-if="!isEditClient">Add client</span>
                    <span v-else >Save</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

const defaultPlansObject = {
    comment: "",
    monthlyHours: 0,
    rolloverFrom: "",
    rolloverHours: 0
};

const defaultRetainerObject = {
    id: null,
    plans: [
        JSON.parse(JSON.stringify(defaultPlansObject))
    ]
};

const defaultDetailsObject = {
    name: "",
    description: "",
    logo: "",
    urls: "",
    rate: 0,
    happiness: "moderate",
    notes: "",
    goals: "",
    meetingFrequency: "",
    meetings: [
        {
            date: '',
            time: ''
        }
    ],
    retainers: [
        // defaultRetainerObject
    ],
    lastEditDate: "",
    accountManagers: [],
    users: []
}


export default {
    data: function () {
        return {
            activePanel: 'loading',
            retainerList: [],
            allLinkedContacts: [],
            allAdminUsers: [],
            internalUsers: [],
            details: defaultDetailsObject,
            deleteModalActive: false,
            selectedRetainerId: 0,
            isLoading: true,
            managedClient: [],
            previousRate: 0,
            clientNameError: '',
            disableSave: false,
        }
    },
    watch: {
        "details.name": {
            handler: function (val) {
                this.disableSave = val ? false : true;
            },
            deep: true
        }
    },
    created() {
    },

    computed: {
        mappedClientUsers() {
            return this.allLinkedContacts.map(user => {
                return {
                    uid: user?.uid,
                    firstName: user?.userSettings?.firstName,
                    lastName: user?.userSettings?.lastName,
                    label: `${user?.userSettings?.firstName} ${user?.userSettings?.lastName}`,
                    email: user?.userSettings?.email,
                };
            });
        },
        isEditClient() {
            return this.$route.params?.clientId ? true : false;
        },
        mappedClientManagers() {
            return this.allAdminUsers.filter(user => {
                return user?.userSettings?.permission === "admin"
            }).map(user => {
                return {
                    uid: user?.uid,
                    firstName: user?.userSettings?.firstName,
                    lastName: user?.userSettings?.lastName,
                    label: `${user?.userSettings?.firstName} ${user?.userSettings?.lastName}`,
                    email: user?.userSettings?.email,
                    // Check if the user is managing the client
                    isManagingClient: user?.userSettings?.managedClient && user?.userSettings?.managedClient?.hasOwnProperty(this?.details?.clientId),
                };
            });
        },
        mappedActiveClientManagers() {
            const users = [];
            this.details?.accountManagers?.forEach(uid => {
                const user = this?.allAdminUsers?.find(user => user?.uid === uid);
                if(user) {
                    users.push({
                        uid: user?.uid,
                        firstName: user?.userSettings?.firstName,
                        lastName: user?.userSettings?.lastName,
                        email: user?.userSettings?.email,
                        // Check if the user is managing the client
                        isManagingClient: user?.userSettings?.managedClient && user?.userSettings?.managedClient?.hasOwnProperty(this?.details?.clientId),
                    });
                }
            })
            return users;
        },
        formattedRetainers() {
            return this.retainerList.map(item => {
                return {
                    value: item.id,
                    label: item.projectName
                }
            });
        },
    },
    async mounted() {
        $('.js-dropdown').find('.js-dropdown__menu').removeClass('is-opened');
        this.resetForm();
        await this.getRetainerList();
        await this.fetchRetainerUsers()

        this.previousRate = this.details.rate;

        const meetings = this.details.meetings ? this.details.meetings : [{ date: '', time: '' }]
        const retainers = this.details.retainers ? this.details.retainers : [];

        const logo = this.details.logo ? this.details.logo : '';
        const clone = JSON.parse(JSON.stringify(this.details));

        const db = tenantDatabase();
        const dbRef = ref(tenantDatabase());

        const usersRef = ref(db, 'users/');

        const formattedRetainers = await Promise.all(Object.keys(retainers || {}).map(async (retainerI) => {
            const retainer = retainers[retainerI]
            let newRetainer = Object.assign({}, retainer);

            const res = await get(child(dbRef, `retainers/${retainerI}`))
            const data = res.val();

            if (data) {
                newRetainer.id = retainerI
                newRetainer.retainerRate = data.retainerRate
                newRetainer.plans = data.plans
                newRetainer.value = data.value
                newRetainer.contractExpiry = data.contractExpiry
                newRetainer.startDate = data.startDate
            }
            return newRetainer;
        }))

        this.isLoading = false;

        this.activePanel = this.$route.params.activePanel || 'details';


        this.details = { ...clone, meetings, logo, retainers: JSON.parse(JSON.stringify(formattedRetainers)) };
        // Convert this.details.accountManagers to an array of uids
        if (this.details && this.details.accountManagers) {
            this.details.accountManagers = Object.keys(this.details.accountManagers);
        }

        if (this.details && this.details.users && this.details.clientId) {
            this.details.users = Object.keys(this.details.users);
        }

        $('body').addClass('overflow-hidden');

        if (this.$route.params.clientId) {
            const clientUsersRef = query(ref(db, 'users'), orderByChild("client"), equalTo(this.$route.params.clientId));

            onValue(clientUsersRef, snapshot => {
                let rawData = snapshot.val();
                if (rawData) {
                    let formattedData = Object.keys(rawData).map((key) => {
                        return {
                            uid: key,
                            userSettings: rawData[key]
                        }
                    });
                    this.allLinkedContacts = formattedData;
                }
                this.isLoading = false;
            });
        }

        const adminUsersRef = query(ref(db, 'users'), orderByChild("permission"), equalTo('admin'));

        onValue(adminUsersRef, snapshot => {
            let rawData = snapshot.val();
            if (rawData) {
                let formattedData = Object.keys(rawData).map((key) => {
                    return {
                        uid: key,
                        userSettings: rawData[key]
                    }
                });
                this.allAdminUsers = formattedData;
            }
            this.isLoading = false;
        });

         
        //sort retainer's plans by Date (rolloverFrom) descending
        this.details?.retainers?.forEach(retainer => {
            retainer.plans?.sort((a, b) => {
                return new Date(b.rolloverFrom) - new Date(a.rolloverFrom);
            });
        });
    },
    methods: {
        scrollToElement() {
            const scrollToElement = this.$route.params.scrollTo;
            if (scrollToElement === 'notes' || scrollToElement === 'goals') {
                this.$nextTick(() => {
                    const element = document.querySelector(`#${scrollToElement}`);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            }
        },
        filteredClientContacts(type) {
            const filteredUsers = this.allLinkedContacts?.filter(user => {

                // Find the user in this.details.users
                let userObj = this?.details?.users?.find(obj => obj.uid === user?.uid);

                // If userObj exists and has userTypes, use them, otherwise use user.userSettings.accountSettings
                let userTypes = userObj?.userTypes || user?.userSettings?.accountSettings;

                return user?.userSettings?.client === this.details.clientId
                    && userTypes?.includes(type); // Check if the userTypes of the user include the type
            });

            return filteredUsers?.map(user => {
                return {
                    uid: user?.uid,
                    firstName: user?.userSettings?.firstName,
                    lastName: user?.userSettings?.lastName,
                    email: user?.userSettings?.email,
                };
            });
        },
        isActiveLabelManager(user) {
            return this?.details?.accountManagers?.includes(user)
        },
        isActiveLabelPrimary(user) {
            return this?.filteredClientContacts('primary-contact').map(filteredUid => filteredUid.uid).includes(user);
        },
        isActiveLabelAccounts(user) {
            return this?.filteredClientContacts('accounts-contact').map(filteredUid => filteredUid.uid).includes(user);
        },
        isActiveLabelItContacts(user) {
            return this?.filteredClientContacts('it-contact').map(filteredUid => filteredUid.uid).includes(user);
        },
        addUser(id, userType) {
            if (!this.details.users) {
                this.details.users = []; // Initialize `users` as an empty array
            }

            this.details.users[id] = true

            if (!this.allLinkedContacts.find(user => user.uid === id).userSettings?.accountSettings) {
                this.allLinkedContacts.find(user => user.uid === id).userSettings.accountSettings = [];
            }

            this.allLinkedContacts.find(user => user.uid === id).userSettings.accountSettings.push(userType);
        },
        removeUser(id, userType) {
            this.allLinkedContacts.find(user => user.uid === id).userSettings.accountSettings = this.allLinkedContacts.find(user => user.uid === id).userSettings.accountSettings.filter(a => a !== userType);

        },
        addUserClientManager(uid) {

            if (!this.details.accountManagers) {
                this.details.accountManagers = [];
            }

            if (this.details.accountManagers.includes(uid)) {
                this.removeUserClientManager(uid)
                return;
            }

            this.details.accountManagers.push(uid);
        },
        removeUserClientManager(uid) {
            this.details.accountManagers = this.details.accountManagers.filter(a => a !== uid);
        },
        async updateRetainerPlansPreset(id, index) {
            const db = tenantDatabase();
            const retainersRef = ref(db, 'retainers/' + id);
            onValue(retainersRef, (snapshot) => {
                const data = snapshot.val();

                this.details.retainers[index].plans = JSON.parse(JSON.stringify(data?.plans || [])) || []
                this.details.retainers[index].value = data?.value || ""
                this.details.retainers[index].contractExpiry = data?.contractExpiry || ""
                this.details.retainers[index].startDate = data?.startDate || ""
            });

        },
        async fetchRetainerUsers() {
            if (this.$route.params.clientId) {
                this.details = await GetFormattedClient(this.$route.params.clientId);
            }
        },
        addNewMeeting() {
            if (this.details.meetings) {
                this.details.meetings.push({
                    date: '',
                    time: ''
                });
            } else {
                this.details.meetings = []
            }
        },
        addNewRetainer() {
            if (this.details.retainers) {
                this.details.retainers.push({...defaultRetainerObject});
            } else {
                this.details.retainers = [{...defaultRetainerObject}]
            }
        },
        addNewRetainerPlan(retainer) {
            if (this.details.retainers[retainer].plans) {
                this.details.retainers[retainer].plans.push(JSON.parse(JSON.stringify(defaultPlansObject)));
            } else {
                this.details.retainers[retainer].plans = [JSON.parse(JSON.stringify(defaultPlansObject))]
            }
        },
        handleDeleteRetainerModalClose() {
            this.deleteModalActive = false;
        },
        handleDeleteRetainerModalConfirm() {
            this.deleteModalActive = false;
            this.details.retainers = this.details.retainers.filter(a => {
                return a.id !== this.selectedRetainerId
            });
        },
        removeRetainer(id) {
            if (id > 0) {
                this.deleteModalActive = true;
                this.selectedRetainerId = id;
            }
            else {
                this.details.retainers = this.details.retainers.filter(a => {
                    return a.id !== id;
                });
            }
        },
        removeMeeting(index) {
            this.details.meetings.splice(index, 1);
        },
        removeRetainerPlan(retainerIndex, planIndex) {
            this.details.retainers[retainerIndex].plans.splice(planIndex, 1);
        },
        async getRetainerList() {
            const auth = await getAuth();
            await axios({
                method: 'get',
                url: `${this.$root.apiBaseUrl}/project/list`,
                headers: {
                    'authorization': auth.currentUser.accessToken,
                    'timezone': this.$root.apiTimezone
                }
            }).then(res => {
                this.retainerList = res.data || [];
            }).catch(err => {
                console.error('An error occurred while fetching retainer list:', err);
            });
        },
        async saveChanges() {
            this.clientNameError = this.details.name ? '' : 'Client name is required.';

            this.disableSave = true;
            if (this.clientNameError) {
                return;
            }
            try {
                const db = tenantDatabase();
                //const app = getApp();
                //const functions = getFunctions(app);

                const lastQuoteEditDate = !this.isEditClient ? new Date() : parseFloat(this.previousRate) !== parseFloat(this.details.rate) ? new Date() : this.details.lastQuoteEditDate || new Date();

                const RetainerListData = JSON.parse(JSON.stringify(this.details.retainers)).map(a => {
                    const retainerId = a?.id || 0;
                    return {
                        ...a,
                        retainerId
                    }
                });

                const submitRetainerListData = this.details.retainers.reduce((acc, val) => {
                    acc[val.id] = true
                    return acc
                }, {});

                const accountManagers = this.details.accountManagers || [];

                // Convert accountManagers back to its original schema
                const accountManagersObject = accountManagers.reduce((obj, userId) => {
                    obj[userId] = true;
                    return obj;
                }, {});

                const accountUsers = this.details.users || [];
                let accountUsersUidTypeObject = {}

                if (this.details.clientId) {

                    accountUsersUidTypeObject = accountUsers.reduce((obj, userObj) => {
                        if (userObj.uid && userObj.userTypes) {
                            obj[userObj.uid] = userObj.userTypes;
                        }
                        return obj;
                    }, {});

                }

                const clientId = this.details.clientId;

                const allUsers = this.allLinkedContacts || []; // Assuming allUsers is an array of all user UIDs
                
                const allAdmins = this.details.accountManagers || [];

                // Loop over the allUsers array and update each user

                allUsers.forEach(async (user) => {
                    const uid = user.uid; // Extract the uid from the user object

                    const userRef = ref(db, `users/${uid}`);
                    const snapshot = await get(userRef);

                    if (snapshot.exists()) {

                        // await update(userRef, {
                        //     [`managedClient/${clientId}`]: accountManagers.includes(uid) ? true : null
                        // });

                        if (user.userSettings && user.userSettings.client === this.details.clientId && user.userSettings.accountSettings) {
                            accountUsersUidTypeObject[user.uid] = user.userSettings.accountSettings;
                        }

                        if (this.details.clientId === user?.userSettings?.client) {
                            await update(userRef, {
                                accountSettings: accountUsersUidTypeObject[uid] ? accountUsersUidTypeObject[uid] : null
                            });
                            LogAuditLog({
                                type: "User",
                                object: "AccountType",
                                action: "edit",
                                data: {
                                    accountSettings: accountUsersUidTypeObject[uid] ? accountUsersUidTypeObject[uid] : null,
                                    clientId: this.details.clientId,
                                    id: uid
                                }
                            })
                        }

                    }
                });

                if (clientId) {
                    const assignedUsers = await get(query(ref(db, 'users'), orderByChild(`responsibilities/${clientId}`), equalTo(true)));
                    const currentAdmins = assignedUsers.val()

                    const adminData = currentAdmins ? Object.keys(currentAdmins).reduce((acc, user) => {
                        acc[`${user}/responsibilities/${clientId}`] = null
                        return acc
                    }, {}) : {}

                    allAdmins.forEach(async (user) => {
                        adminData[`${user}/responsibilities/${clientId}`] = true
                    });
                }

                let imageUrl = ""; // default to an empty string
                if (this.details.logo && this.details.logo instanceof File) {
                    const file = this.details.logo;
                    const fileName = generateUUID() + '-' + file.name;
                    const fullPath = `images/${fileName}`;
                    this.submitting = true;
                    // Upload to storage
                    const storage = getStorage();
                    const storageReference = storageRef(storage, fullPath);
                    const snapshot = await uploadBytes(storageReference, file);
                    imageUrl = await getDownloadURL(snapshot.ref);
                } else if (this.details.logo && !this.details.logo.includes('undefined')) {
                    imageUrl = this.details.logo; // use the current logo if it's not a file and does not contain 'undefined'
                }

                const submitData = {
                    ...this.details,
                    lastEditDate: new Date(),
                    lastQuoteEditDate,
                    meetings: this.details.meetings ? this.details.meetings.filter(a => a.date) : [],
                    retainers: submitRetainerListData,
                    logo: imageUrl,
                    accountManagers: accountManagersObject,
                    users: this.details?.users ? this.details?.users.reduce((acc, uid) => {
                        return {
                            ...acc,
                            [uid]: true
                        }
                    }, {}) : {}
                }

                delete submitData.contacts;

                await updateClientDetails(this.isEditClient ? this.details.clientId : null, submitData);

                const retainerUpdates = {}

                RetainerListData.map(retainer => {
                    retainerUpdates[`/retainers/${retainer?.id}/retainerRate`] = retainer.retainerRate || [];
                    retainerUpdates[`/retainers/${retainer?.id}/plans`] = retainer.plans || [];
                    retainerUpdates[`/retainers/${retainer?.id}/value`] = retainer.value || "";
                    retainerUpdates[`/retainers/${retainer?.id}/contractExpiry`] = retainer.contractExpiry !== "1 January 1970" || retainer.contractExpiry !== "Invalid Date" ? retainer.contractExpiry || "" : "";
                    retainerUpdates[`/retainers/${retainer?.id}/startDate`] = retainer.startDate !== "1 January 1970" || retainer.startDate !== 'Invalid Date' ? retainer.startDate || "" : "";

                    LogAuditLog({
                        type: "Retainer",
                        object: "Retainer",
                        action: "edit",
                        data: {
                            ...retainer,
                            clientId: clientId
                        }
                    })
                });

                await update(ref(db), retainerUpdates);

                this.$notify({
                    title: "",
                    text: `Client ${this.isEditClient ?  'updated':'created'} successfully`,
                    duration: 5000,
                });

                this.resetForm();

                $('body').removeClass('overflow-hidden');
                if ((window.history.length) > 0) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({ name: 'Admin - Beta clients' });
                }
            } catch (error) {
                console.error('An error occurred while saving changes:', error);
            }
        },
        closeEditClient() {
            this.resetForm();
            $('body').removeClass('overflow-hidden')
            this.$router.go(-1)
        },
        resetForm() {
            this.details = defaultDetailsObject;
            this.details.meetings = [{ date: '', time: '' }];
            this.details.retainers = [defaultRetainerObject];
            this.activePanel = 'details';
        }
    }
};

</script>


<style lang="scss">
.tox.tox-tinymce {
    border-color: theme('colors.grey-200');
    border-width: 1px;
    border-radius: 4px;
}
</style>