// helper.js
import { getAuth } from "../tenant";

export async function authTokenUpdate(callback) {
  const auth = await getAuth();
  const unsubscribe = auth.onAuthStateChanged(async user => {
    if (user) {
      const tokenResult = await auth.currentUser.getIdTokenResult(true);
      return tokenResult.claims || {};

      // auth.currentUser.getIdTokenResult(true).then(tokenResult => {
      //   callback(tokenResult.claims ? tokenResult.claims : {});
      // });
    }
  });
  return unsubscribe;
}