

<script setup>

    import IconHappinessHappy from '~icons/my-icons/happiness-happy';
    import IconHappinessSad from '~icons/my-icons/happiness-sad';
    import IconHappinessVerySad from '~icons/my-icons/happiness-v-sad';
    import IconHappinessVeryHappy from '~icons/my-icons/happiness-v-happy';
    import IconHappinessModerate from '~icons/my-icons/happiness-moderate';

</script>

<template>
    <IconHappinessVeryHappy v-if="value === 'v-happy'"/>
    <IconHappinessHappy v-if="value === 'happy'"/>
    <IconHappinessModerate v-if="value === 'moderate'"/>
    <IconHappinessSad v-if="value === 'sad'"/>
    <IconHappinessVerySad v-if="value === 'v-sad'"/>
</template>

<script>

    export default {
        props: {
            value: { type: String, required: true }
        }
    };

</script>