<script setup>
    import PermissionsValidation from "@/components/containers/PermissionsValidation.vue";
    import store from '@/store/index';
</script>

<template>
    <PermissionsValidation v-if="isWebcodaAdmin" :requiredRoles="['admin']">
        <router-view></router-view>
    </PermissionsValidation>
</template>

<script>
    export default {
        computed: {
            isWebcodaAdmin() {
                const currentUser = store.getters.getUserState;
                return currentUser.permission === 'admin'
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>