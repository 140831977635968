<script setup>
    import { getAuth, tenantDatabase } from "@/tenant";
    import { getDatabase, ref, update, get, child } from "firebase/database";
    import axios from 'axios';
    import store from '../../../../store';
    import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';
</script>

<template>
  <PermissionsValidation :hideSpinner="true" :noMessage="'true'" :requiredRoles="['admin']" class="h-full">
  <div class="ml-[196px] relative scroll-smooth">
    <div class="relative w-full p-10">
        <h1 class="text-4xl text-red-500">DEVELOPER PAGES DO NOT TOUCH ANYTHING</h1>
        <div class="grid grid-cols-3 gap-12 mt-12">
          <div>
            <h2 class="text-2xl">User retainer reformatting</h2>
            <p>Removes all retainers from clients</p>
            <p>Removes the <code class="bg-gray-700 text-white p-1 rounded inline-block">receive-monthly-report</code> and <code class="bg-gray-700 text-white p-1 rounded inline-block">can-add-users</code> field</p>
            <p>This also removes the <code class="bg-gray-700 text-white p-1 rounded inline-block">team</code> field</p>
            <p>Converts <code class="bg-gray-700 text-white p-1 rounded inline-block">webcoda-user</code> to <code class="bg-gray-700 text-white p-1 rounded inline-block">admin</code></p>
            <p>Converts <code class="bg-gray-700 text-white p-1 rounded inline-block">client-admin</code> to <code class="bg-gray-700 text-white p-1 rounded inline-block">client-user</code></p>
            <button @click.prevent="formatUserRetainerPermissions" class="mt-2 py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-sm">Format user retainers</button>
          </div>
          <div>
            <h2 class="text-2xl">Clients retainer reformatting</h2>
            <p>Sets all <code class="bg-gray-700 text-white p-1 rounded inline-block">0: { id: 1, retainerId: 1 }</code> to <code class="bg-gray-700 text-white p-1 rounded inline-block">1: { "source": "everhour" }</code>, additionally from security rules, it removes data duplication</p>
            <button @click="formatClientsPermissions" class="mt-2 py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-sm">Format clients retainers</button>
          </div>
        </div>
      </div>
    </div>
  </PermissionsValidation>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
        formatUserRetainerPermissions() {
            const db = tenantDatabase();
            const dbRef = ref(db);
            get(child(dbRef, `users`)).then(async (snapshot) => {
                const data = snapshot.val();

                const formattedData = Object.entries(data).reduce((obj, [key, user]) => {
                  user.retainer = {}
                  user.team = null
                  user.accountSettings = user.accountSettings ? user.accountSettings.filter(setting => setting !== "receive-monthly-report").filter(setting => setting !== "can-add-users") : []

                  let newPermission
                  switch (user.permission) {
                    case "webcoda-user":
                      newPermission = "admin"
                      break;
                    case "client-user":
                      newPermission = "client-admin"
                      break;
                    default:
                      newPermission = user.permission
                      break;
                  }

                  obj[key] = {
                    ...user,
                    permission: newPermission
                  }

                  return obj
                }, {})

                await update(ref(db), {
                  'users': formattedData
                });
            });
        },
        formatClientsPermissions() {
            const db = tenantDatabase();
            const dbRef = ref(db);

            get(child(dbRef, `clients`)).then(async (snapshot) => {
                const data = snapshot.val();

                console.log(data)

                const formattedData = Object.entries(data).reduce((obj, [key, client]) => {
                  const retainers = client.retainers ? client.retainers.reduce((accumulator, currentValue) => {
                    accumulator[currentValue.id] = true

                    return accumulator
                  }, {})
                    :
                  {}
                  obj[key] = {
                    ...client,
                    retainer: {},
                    retainers
                  }

                  return obj
                }, {})

                console.log(formattedData)
                await update(ref(db), {
                  'clients': formattedData
                });
            });
        },
    }
  }
</script>
