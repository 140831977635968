<template>
    <div v-if="counts == 0" class="items-center justify-center">
        <div class="px-12 pt-6 pb-4 text-center font-medium">
            No {{ pageName }} found.
        </div>
        <div v-if="searchTerm" class="px-12 mb-6 text-center font-medium">
            <button class="border rounded-md border-gray-200 bg-gray-50 p-1.5 px-4" @click="handleFullReset()"> Reset filters</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        counts: {
            type: Number,
            required: true
        },
        searchTerm: {
            type: String,
            required: true
        },
        handleFullReset: {
            type: Function,
            required: true
        },
        pageName: {
            type: String,
            required: true
        }
    }
}
</script>
