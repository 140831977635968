<script setup>

    import Container from '../containers/Container.vue';
    import LightTick from '~icons/my-icons/light-tick';
    import Close from '~icons/my-icons/cross';

</script>

<template>
    <div v-if="notificationActive" :class="[styleType === 'fixed' ? 'absolute bottom-12 left-1/2 -translate-x-1/2': '']" class="absolute bottom-4 left-1/2 -translate-x-1/2 z-50 mx-auto bg-green-200 rounded-[4px] px-3 py-4 my-8 flex items-center">
        <span class="flex items-center" v-if="notificationType.length > 0">
            <div class="text-white mr-3 rounded-full w-7 h-7 inline-flex items-center justify-center text-sm">
                <LightTick class="w-6 h-6" />
            </div>
            <div v-if="renderDefaultNotification.main" class="font-bold text-gray-900 mr-3 flex-shrink-0">{{renderDefaultNotification.main}}</div>
            <div class="pl-2 text-xs">{{renderDefaultNotification.desc}}</div>
        </span>
        <!--<button class="ml-auto mr-0 font-medium text-[9px] text-green-800" @click="closeNotification"><Close/></button>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                notificationType: this.notificationType,
                newUserEmail: this.email,
                notificationActive: this.notificationType.length > 0 ? true : false,
                styleType: this.type ? this.type : ''
            }
        },
        props: ['notificationType', 'type', 'newUserEmail'],
        computed: {
            renderDefaultNotification() {
                const { notificationType, newUserEmail } = this;
                switch (notificationType) {
                    case 'deleteUser':
                        return {
                            main: 'User deleted',
                            desc: 'User successfully deleted.'
                        }
                    case 'createUser': 
                        return {
                            main: 'Contact added',
                            desc: 'Registration email sent to ' + newUserEmail
                        }
                    case 'sendReportSuccess': 
                        return {
                            main: 'Success',
                            desc: 'Report has been successfully sent.'
                        }
                    case '400': 
                        return {
                            main: '400 Error',
                            desc: 'An error has occured. Please try again later or let an administrator know.'
                        }
                    case 'Success': 
                        return {
                            main: 'Success',
                            desc: 'Changes have been saved successfully.'
                        }
                    case 'SuccessSync': 
                        return {
                            main: 'Success',
                            desc: 'Monthly snapshot data has now been synced.'
                        }
                    case 'ResendConfirmation': 
                        return {
                            main: 'Success',
                            desc: 'A confirmation email has been sent to the registered email.'
                        }
                    case 'copyEmail': 
                        return {
                            main: '',
                            desc: 'Email address successfully copied to your clipboard.'
                        }
                    case 'sendUserEmail': 
                        return {
                            main: 'Success',
                            desc: 'Your email have been successfully sent.'
                        }
                    default: 
                        return {
                            main: 'Default Notification',
                            desc: 'No specific case has been added to handle this notification type.'
                        }
                }
            }
        },
        mounted() {
        },
        methods: {
           closeNotification() {
                this.notificationType = '';
                this.notificationActive = false;
           }
        },
        watch: {
            'notificationActive': function (val, oldVal) {
                setTimeout(() => {
                    this.closeNotification();
                }, 3600);
            },
            '$props': {
                handler: function (val, oldVal) { 
                    this.notificationType = val.notificationType;
                    this.notificationActive = true;
                },
                deep: true
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>