<script setup>
    import { getAuth, tenantDatabase } from "@/tenant";
    import { toRef } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import { getDatabase, ref, update, push, child } from "firebase/database";
    import { getStorage, ref as storageRef } from "firebase/storage";
    import store from '../store';
    
    import deleteUser from '../helpers/DeleteUser';
    import Loading from '../components/containers/Loading.vue';
    import ChevronDown from '~icons/my-icons/chevron-down';

</script>

<template>
    <Loading :isLoading="submitting" :type="'overlay'" :loadText="'Processing...'">
        <div v-if="handleModalActive" class="fixed left-0 w-screen h-screen z-100 top-0 flex justify-center items-center">
            <div class="bg-[#111A1C] w-full absolute h-full opacity-40 z-1" @click="closeModal()"></div>
            <div class="bg-white z-10 max-w-[578px] w-full relative text-center rounded-lg">
                <div class="p-4 leading-[28px] flex items-center border-b border-bd border-solid">
                    <img :src="handleServiceData.icon" class="mr-5" alt="">
                    <span class="text-xl font-bold">{{handleServiceData.name}}</span>
                </div>
                <div class="p-4 inline-block text-left">
                    <div class="mt-2 flex flex-col justify-center">
                        <div v-if="handleServiceData.oneOffEnabled">
                            <label class="inline-flex items-center text-sm mb-2">
                                <span class="inline-block mr-4 font-bold text-sm w-[120px]">Occurance:</span>
                                <div class="relative">
                                    <select class="min-w-[296px] border border-bd border-solid rounded h-12 px-4 appearance-none" @change="handleOccuranceUpdate($event)" v-model="occuranceType">
                                        <option value="one-off">One off</option>
                                        <option value="period">Period</option>
                                    </select>
                                    <ChevronDown class="absolute right-3 top-1/2 -translate-y-1/2 text-lg pointer-events-none" />
                                </div>
                            </label>
                        </div>
                        <label class="inline-flex items-center text-sm mb-2" v-if="!handleServiceData.oneOffEnabled || occuranceType == 'period'">
                            <span class="inline-block mr-4 font-bold text-sm w-[120px]">Monthly hours:</span>
                            <input :value="hours" @input="e => hours = e.target.value" type="text" class="min-w-[296px] border border-bd border-solid rounded h-12 px-4" />
                        </label>
                        <div class="inline-flex items-center text-sm mb-6">
                            <span class="inline-block mr-4 font-bold text-sm w-[120px]">Set start date:</span>
                            <div class="relative js-dropdown-calendar">
                                <button @click="dateFilterOpen = !dateFilterOpen" :class="[!dateFilterOpen ? 'rounded' : 'rounded-tl rounded-tr border-b-transparent']" class="min-w-[296px] min-h-[46px] block px-4 py-2.5 relative text-left border border-bd">
                                    {{dateLabel}}
                                    <ChevronDown :class="{'-rotate-180': dateFilterOpen}" class="transition transition-500 absolute right-2 top-1/2 -translate-y-1/2 text-lg" />
                                </button>
                                <div v-if="dateFilterOpen" class="w-full absolute bg-white p-4 top-[calc(100%-1px)] right-0 border border-bd border-t-0 z-[2] rounded-bl rounded-br">
                                    <Datepicker
                                        :autoApply="true"
                                        :modelValue="date"
                                        @update:modelValue='handleDateUpdate'
                                        :enableTimePicker="false"
                                        :monthPicker="true"
                                        :format="'MMMM yyyy'" 
                                        :minDate="new Date()"
                                        :maxDate="new Date(new Date().setFullYear(new Date().getFullYear() + 1))"
                                        :clearable="false"
                                        :inline="true"
                                        :menuClassName="'c-datepicker--dropdown'"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="inline-flex items-center text-sm">
                            <span class="inline-block mr-4 font-bold w-[120px]">Monthly cost</span>
                            <span>$A{{calculateCost}}.00</span>
                        </div>
                        <!-- {{handleServiceData}} -->
                    </div>
                </div>
                <div class="mt-11 flex justify-between w-full p-4">
                    <button class="mr-3 bg-grey-50 px-6 py-2.5 rounded-md" @click="closeModal()">Cancel</button>
                    <button class="bg-orange-500 px-6 py-2.5 rounded-md text-white" @click="submit()">
                        <span v-if="handleServiceData.activeService">Update</span>
                        <span v-else>Add service</span>
                    </button>
                </div>
            </div>
        </div>
    </Loading>
</template>

<script>
  export default {
    props: ['isActive', 'data'],
    data() {
      return {
            // handleServiceData: this.data,
            submitting: false,
            hours: this.data?.hours,
            cost: this.data?.cost,
            serviceName: this.data?.name,
            date: {
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            },
            dateFilterOpen: false,
            retainerId: store.getters.getActiveRetainerState,
            occuranceType: this.data?.occurance
      }
    },
    computed: {
        handleServiceData() {
            this.serviceId = this.data.serviceId;
            this.serviceName = this.data.name;
            this.hours = this.data.hours;
            this.cost = this.data.cost;
            this.occuranceType = this.data.occurance;
            if(this.data.occurance === 'one-off') {
                this.hours = 1;
            }
            return this.data;
        },
        handleModalActive() {
            return this.isActive;
        },
        calculateCost() {
            const { cost, hours } = this;
            return cost * Number(hours) ? cost * Number(hours) : 0
        },
        dateLabel() {
            const { date } = this;
            if(!date) {
                return ''
            }
            var options = { month: 'long', year: 'numeric' };
            var thisDate = new Date(date.year, date.month, 1);
            return thisDate.toLocaleString('en-GB', options);
        }
    },  
    mounted() {
        if(this.occuranceType === 'one-off') {
            this.hours = 1;
        }
    },
    methods: {
        closeModal(success) {
            this.occuranceType = '';
            this.modalActive = false;
            this.$emit('handleModalClose', success);
            document.body.classList.remove('overflow-hidden');
        },
        handleOccuranceUpdate(e) {
            if(e.target.value === 'one-off') {
                this.hours = 1;
            }
        },
        handleDateUpdate(e) {
            this.date = e;
            this.dateFilterOpen = false;
        },
        submit() {
            const db = tenantDatabase();
            const activeRetainerId = store.getters.getActiveRetainerState;
            this.isSubmitting = true;
            const updates = {};
            updates[`/retainers/${activeRetainerId}/services/${this.serviceId}/hours`] = this.hours;
            if(this.occuranceType === 'one-off') {
                updates[`/retainers/${activeRetainerId}/services/${this.serviceId}/occurance`] = 'one-off';
                updates[`/retainers/${activeRetainerId}/services/${this.serviceId}/date`] = new Date(this.date.year, this.date.month, 1);
            } else {
                if(this.hours > 0) {
                    updates[`/retainers/${activeRetainerId}/services/${this.serviceId}/occurance`] = 'period';
                } else {
                    updates[`/retainers/${activeRetainerId}/services/${this.serviceId}/occurance`] = null;
                }
            }

            update(ref(db), updates).then(res => {
                const changeLogUpdate = {};
                const userName = store.getters.getUserState.firstName + ' ' + store.getters.getUserState.lastName;
                const year = new Date().getFullYear();
                const month = new Date().getMonth();
                const newKey = push(child(ref(db), `retainerHistory/${activeRetainerId}/${year}-${month + 1}/`)).key;
                let updateDetail = '';
                if(this.occuranceType === 'one-off') {
                    updateDetail = `One off ${this.serviceName} service added.`
                } else if(this.hours > 0) {
                    updateDetail = `${this.serviceName} service changed to ${this.hours} hours.`
                } else {
                    updateDetail = `${this.serviceName} service removed.`
                }
                changeLogUpdate[`/retainerHistory/${activeRetainerId}/${year}-${month + 1}/${newKey}`] = {
                    detail: updateDetail,
                    changedBy: userName,
                    changedDate: new Date()
                }
                update(ref(db), changeLogUpdate).then(res => {
                    this.closeModal(true);
                    this.isSubmitting = false;
                });
            }).catch(err => {
                console.log(err);
                this.isSubmitting = false;
            });
        }
    }
  }
</script>

<style lang="scss">

</style>
