<script setup>
    
</script>

<template>
    <div v-if="taskData.length > 0" class="">
        <div  class="bg-grey-50 grid grid-cols-2 px-8 py-3 border-bd border-y text-xs font-medium">
            <span>Task</span>
            <span>Hours</span>
        </div>
        <div class="px-6 sm:px-8 py-2 text-sm">
            <div v-for="(task, index) in taskData.slice(0, viewLimit)" :key="task.name+index" class="grid grid-cols-1 sm:grid-cols-2 py-2 border-b border-grey-100 last:border-0">
                <div class="mb-3 mt-1 sm:mt-0 sm:mb-0 font-semibold sm:font-normal pr-4">{{displayType ? task[displayType] : task.name}}</div>
                <div class="flex ">
                    <span class="w-16 mr-2 flex-shrink-0">{{task.formattedTime}} hrs</span>
                    <div class="w-full">
                        <span 
                            class="h-1 min-w-[7px] rounded-full inline-block"
                            :class="[task.billable == true ? 'bg-green-600' : 'bg-purple-500', index == 0 ? 'w-full': '']"
                            :style="task.progressBarLengthStyle"
                        >
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="taskData.length > viewLimit && (taskData.length + 10 > viewLimit)" class="mt-8 mb-6 text-center">
                <button class="py-3 px-6 bg-orange-500 rounded text-white" @click="viewMore">View more</button>
            </div>
        </div>
    </div>
    <div v-else class="border-bd border-t h-48 flex items-center justify-center bg-grey-50 font-medium">
        No Data Available
    </div>
</template>

<script>
    export default {
        props: ['data', 'dataType'],
        data: function() {
            return {
                viewLimit: 10,
                displayType: this.dataType ? this.dataType : null
            }
        },
        computed: {
            taskData() {
                return this.data?.map(item => {
                    const mostHours = Math.max(...this.data.map(o => o.time));
                    const relativeHours = (item.time / mostHours) * 100;
                    const progressBarLengthStyle = `width: ${relativeHours}%;`;
                    const formattedTime = parseFloat(item.time.toPrecision(3));

                    return {
                        ...item,
                        progressBarLengthStyle,
                        formattedTime
                    }
                })
                .sort((a, b) => b.time - a.time);
            }
        },
        methods: {
            viewMore() {
                this.viewLimit = this.viewLimit + 10;
            }
        },
        mounted() {
        }
    };

</script>


<style lang="scss" scoped>
    
</style>