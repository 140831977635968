<script setup>
</script>

<template>
  <div class="flex">
    <router-view />
  </div>
  <notifications class="fixed bottom-4 left-1/2 -translate-x-1/2 flex !top-[unset] !w-fit justify-center items-center">
      <template #body="props">
        <div class="my-notification !px-3 py-2 flex items-center justify-center whitespace-nowrap !w-full">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="12" fill="#92D95A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1384 8.19526C17.3987 8.45561 17.3987 8.87772 17.1384 9.13807L10.4717 15.8047C10.2114 16.0651 9.78927 16.0651 9.52892 15.8047L6.86225 13.1381C6.6019 12.8777 6.6019 12.4556 6.86225 12.1953C7.1226 11.9349 7.54471 11.9349 7.80506 12.1953L10.0003 14.3905L16.1956 8.19526C16.4559 7.93491 16.878 7.93491 17.1384 8.19526Z" fill="white"/>
          </svg>
          <p class="title !mx-2 font-bold !text-sm">
            {{ props.item.title }}
          </p>
          <p class="!text-sm" v-html="props.item.text"/>
        </div>
      </template>
    </notifications>
  <div class="fixed bottom-4 right-4 text-xs text-right pointer-events-none" v-if="env === 'development'">
    <div>Current Environment <span class="font-medium block capitalize">{{env}}</span></div>
  </div>
</template>

<script>
  import "vue-skeletor/dist/vue-skeletor.css";
  import 'swiper/css';
  import 'swiper/css/bundle';
  export default {
    data() {
      return {
        apiBaseUrl: import.meta.env.VITE_BASE_API,
        apiTimezone: import.meta.env.VITE_API_TIMEZONE,
        env: import.meta.env.VITE_APP_ENV
      }
    },
    mounted() {
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: theme('colors.grey-900');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .my-notification {
    background: #EBF8CD;
    display: flex;
    border-radius: 4px;
  }
  [v-cloak] {
    display: none;
  }
</style>
