import ServiceRetainerImage from '../assets/img/services-retainer.png';
import ServiceUatImage from '../assets/img/services-uat.png';
import ServiceSecurityImage from '../assets/img/services-security.png';
import ServiceSiteAuditImage from '../assets/img/services-site-audit.png';
import ServiceStagingImage from '../assets/img/services-staging.png';
import ServiceHostingImage from '../assets/img/services-hosting.png';

const allServices = [
    {
        serviceId: 'retainer',
        name: 'Retainer',
        icon: ServiceRetainerImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: 0,
        cost: 0,
        occurance: 'Monthly',
        oneOffEnabled: false
    },
    {
        serviceId: 'uat',
        name: 'UAT',
        icon: ServiceUatImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: 0,
        cost: 0,
        occurance: 'Monthly',
        oneOffEnabled: false
    },
    {
        serviceId: 'hosting',
        name: 'Hosting',
        icon: ServiceHostingImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: null,
        cost: 0,
        occurance: '',
        oneOffEnabled: false
    },
    {
        serviceId: 'securityScan',
        name: 'Security Scan',
        icon: ServiceSecurityImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: null,
        cost: 0,
        occurance: '',
        oneOffEnabled: true
    },
    {
        serviceId: 'siteAudit',
        name: 'Site Audit',
        icon: ServiceSiteAuditImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: null,
        cost: 0,
        occurance: '',
        oneOffEnabled: true
    },
    {
        serviceId: 'staging',
        name: 'Staging',
        icon: ServiceStagingImage,
        details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus vestibulum venenatis, arcu tempor purus duis. Egestas enim.',
        hours: null,
        cost: 0,
        occurance: '',
        oneOffEnabled: true
    }
]

export default allServices;