<script setup>

    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    

    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import store from "@/store";
    import SectionPanel from "@/components/containers/SectionPanel.vue";
    import DataBar from "@/components/DataBar.vue";
    import UserCard from "@/components/UserCard.vue";
    import Loading from '@/components/containers/Loading.vue';

    import { getAuth } from "@/tenant";
    import axios from "axios";

    import ChevronDown from '~icons/my-icons/chevron-down';

</script>

<template>
    <div class="pb-20 flex-1 ml-[196px] relative">
        <div class="py-9 bg-white border-b border-solid border-bd">
            <Container class="w-full flex flex-col gap-4 sm:flex-row justify-between items-center">
                <h1 class="text-3xl font-bold">Team</h1>
                <div class="relative js-dropdown-calendar" ref="dropdownCalendar">
                    <button @click="dateFilter.isOpened = !dateFilter.isOpened" :class="[!dateFilter.isOpened ? 'rounded' : 'rounded-tl rounded-tr border-b-transparent']" class="min-h-[46px] block px-4 py-2.5 w-[296px] relative text-left border border-grey-900">
                        <span v-if="dateFilter.filterActiveType === 'select'">{{dateFilter.selectedValue > 0 ? `Last ${parseInt(dateFilter.selectedValue) + 1} days`: ''}}</span>
                        <span v-if="dateFilter.filterActiveType === 'dateRange'">{{`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`}}</span>
                        <ChevronDown :class="{'-rotate-180': dateFilter.isOpened}" class="transition transition-500 absolute right-2 top-1/2 -translate-y-1/2 text-lg" />
                    </button>
                    <div v-if="dateFilter.isOpened" class="w-full absolute bg-white p-4 top-[calc(100%-1px)] right-0 border border-grey-900 border-t-0 z-[2] rounded-bl rounded-br">
                        <div class="flex rounded-full border-2 border-grey-900 bg-grey-900 mb-5">
                            <div class="flex-1">
                                <label class="block">
                                    <input v-model="dateFilter.displayType" type="radio" value="select" name="dateFilterType" class="sr-only peer" />
                                    <span class="block text-center py-2 px-4 text-sm cursor-pointer text-white peer-checked:bg-white peer-checked:text-grey-900 rounded-full font-medium">Select</span>
                                </label>
                            </div>
                            <div class="flex-1">
                                <label class="block">
                                    <input v-model="dateFilter.displayType" type="radio" value="dateRange" name="dateFilterType" class="sr-only peer"/>
                                    <span class="block text-center py-2 px-4 text-sm cursor-pointer text-white peer-checked:bg-white peer-checked:text-grey-900 rounded-full font-medium">Date range</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="dateFilter.displayType == 'select'">
                            <div>
                                <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="6" name="dateFilter" class="sr-only peer" :checked="dateFilter.selectedValue" />
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 7 days</span>
                                </label>
                            </div>
                            <div>
                                <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="13" name="dateFilter" class="sr-only peer"/>
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 14 days</span>
                                </label>
                            </div>
                            <div>
                            <label class="block mb-2">
                                    <input v-model="dateFilter.selectedValue" @change="handleSelectDateFilter($event)" type="radio" value="29" name="dateFilter" class="sr-only peer"/>
                                    <span class="peer-checked:bg-grey-50 peer-checked:text-grey-600 peer-checked:cursor-auto peer-checked:border peer-checked:border-bd border-2 rounded-[6px] block text-center py-2 px-4 text-sm border-grey-900 cursor-pointer">Last 30 days</span>
                                </label>
                            </div>
                        </div>
                        <div v-if="dateFilter.displayType == 'dateRange'">
                            <Datepicker
                                :menuClassName="'c-datepicker--dropdown'"
                                :inline="true"
                                :modelValue="dateFilter.filterDate"
                                @update:modelValue='handleDateUpdate'
                                :enableTimePicker="false"
                                :range="true"
                                :format="'dd MMM yyyy'" 
                                ref="dateRangeFilter"
                                selectText="Apply"
                                hideOffsetDates="true"
                                weekStart="0"
                            >
                                <template #calendar-header="{ index }">
                                    <div class="text-xs font-normal text-grey-900 text-center mt-1">
                                        {{ calenderHead[index] }}
                                    </div>
                                </template>
                            </Datepicker>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
        <Container class="w-full">
            <div class="mt-15">
                <SectionPanel title="Team Hours" :date="`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`">
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <DataBar 
                            :chartData="totalTeamStats"
                            :useData="'retainer'"
                        />
                    </Loading>
                </SectionPanel>
            </div>
            <div class="mt-12">
                <SectionPanel title="Top Performers" :date="`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`">
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <div class="p-8">
                            <div class="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                <UserCard
                                    class="mb-4 last:mb-0 md:mb-0"
                                    v-for="(user, index) in topThreeUsers" 
                                    :userData="user" 
                                    :key="index" 
                                    :position="`${index + 1}`" 
                                />
                            </div>
                        </div>
                    </Loading>
                </SectionPanel>
            </div>
            <div class="mt-12">
                <SectionPanel title="Leaderboard" :date="`${convertDateFormat(dateFilter.filterDate[0])} - ${convertDateFormat(dateFilter.filterDate[1])}`">
                    <Loading :isLoading="isLoading" :loadText="'Loading...'">
                        <div 
                            v-for="(user, index) in allUserDetails" 
                            class="bg-white py-4 flex items-center border-b border-bd last:border-0 w-full"
                            :key="user.email"
                        >
                            <div class="flex w-full items-center">
                                <DataBar 
                                    :userData="user"
                                    :positionNumber="index + 1"
                                    :chartData="user?.hours"
                                    :type="'user'"
                                    :styling="'small'"
                                />
                            </div>
                        </div>
                    </Loading>
                </SectionPanel>
            </div>
        </Container>
    </div>
</template>

<script>
    
    export default {
        data() {
            return {
                billableTarget: 450,
                isLoading: true,
                allUserDetails: [],
                dateFilter: {
                    isOpened: false,
                    displayType: 'select',
                    selectedValue: 6,
                    filterDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
                    filterActiveType: 'select'
                },
                appSettings: {
                    teamHoursTarget: 60,
                    userHoursTarget: 30
                }
            }
        },
        mounted() {
            if(store.getters.getAppSettings) {
                const appSettings = {...store.getters.getAppSettings};
                this.appSettings.teamHoursTarget = appSettings.teamHoursTarget;
                this.appSettings.userHoursTarget = appSettings.userHoursTarget;
                this.billableTarget = parseFloat(this.appSettings.teamHoursTarget) * this.daysExcludingWeekends;
            }
            this.getStats();
            document.addEventListener('click', event => {
                if(event.target.closest('.js-dropdown-calendar') === null) {
                    this.dateFilter.isOpened = false;
                }
            });
        },
        computed: {
            calenderHead() {
                return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            },
            topThreeUsers() {
                const { allUserDetails } = this;
                if(allUserDetails.length > 3) {
                    return allUserDetails.slice(0, 3);
                }
                return allUserDetails
            },
            totalTeamStats() {

                const { allUserDetails, billableTarget } = this;
                const arr = JSON.parse(JSON.stringify(allUserDetails));
                const flatArray = arr.map(user => {
                    user.hours.taskList.forEach(task => {
                        !task.retainer ? task.retainer = `[No Client Assigned] - ${task.name}` : ''
                    });
                    return [...user.hours.taskList]
                });

                let uniqueTaskArray = flatArray.flat().reduce((a, b) => {
                    let retainer = b.retainer;
                    let found = a.find(item => {
                        return item.retainer == retainer
                    });
                    found ? found.time += b.time : a.push(b)
                    return a;
                }, []);

                return {
                    billable: parseFloat(allUserDetails.reduce((a, b) => a + b.hours.billable, 0).toPrecision(2)),
                    nonBillable: parseFloat(allUserDetails.reduce((a, b) => a + b.hours.nonBillable, 0).toPrecision(2)),
                    billableTarget: billableTarget,
                    taskList: uniqueTaskArray
                }
            },
            daysExcludingWeekends() {
                const { filterDate } = this.dateFilter;
                const fromDate = new Date(filterDate[0]);
                const toDate = new Date(filterDate[1]);
                let count = 0;
                let curDate = new Date(fromDate.getTime());
                while (curDate <= toDate) {
                    const dayOfWeek = curDate.getDay();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
                    curDate.setDate(curDate.getDate() + 1);
                }
                return count;
            }
        },
        methods: {
            convertDateFormat(date) {
                var options = { day: 'numeric', month: 'short', year: 'numeric' };		
                var date = new Date(date);
                return date.toLocaleString('en-GB', options);
            },
            handleDateUpdate(e) {
                this.dateFilter.filterDate = e;
                this.dateFilter.selectedValue= 0,
                this.allUserDetails = [];
                this.dateFilter.filterActiveType = 'dateRange';
                this.getStats();
                this.dateFilter.isOpened = false;
            },
            handleSelectDateFilter(e) {
                const dateValue = e.target.value;
                const newDate = [new Date().setDate(new Date().getDate() - dateValue), new Date()];
                this.dateFilter.selectedValue = dateValue;
                this.dateFilter.filterDate = newDate;
                this.allUserDetails = [];
                this.dateFilter.filterActiveType = 'select';
                this.getStats();
                this.dateFilter.isOpened = false;
            },
            convertToHours(seconds) {
                if(!seconds) {
                    return 0
                }
                var timeInt = parseInt(seconds)
                var hours = (timeInt / 3600)
                return Math.round((hours + Number.EPSILON) * 100) / 100;
            },
            async getStats() {
                this.isLoading = true;
                const auth = await getAuth();
                const once = auth.onAuthStateChanged(currentUser => {
                    if(currentUser) {

                        const currentToken = auth.currentUser.accessToken;
                        const { filterDate } = this.dateFilter;
                        
                        var from = new Date(filterDate[0]);
                        var to = new Date(filterDate[1]);
   
                        axios.get(`${this.$root.apiBaseUrl}/team/stats/${from.toISOString().substring(0,10)}/${to.toISOString().substring(0,10)}`, {
                            headers: {
                                'authorization': currentToken,
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => {

                            this.billableTarget = parseFloat(this.appSettings.teamHoursTarget) * this.daysExcludingWeekends;

                            this.allUserDetails = res.data.map(x => {
                                return {
                                    name: x.name,
                                    email: x.email,
                                    role: "",
                                    profileImageUrl: x.profileImage ?? "https://via.placeholder.com/100x100",
                                    hours: {
                                        billable: this.convertToHours(x.billable),
                                        billableTarget: parseFloat(this.appSettings.userHoursTarget) * this.daysExcludingWeekends, //TODO:?
                                        nonBillable: this.convertToHours(x.nonbillab),
                                        taskList: x.taskList.map(t => {
                                            return {
                                                name: t.name,
                                                time: this.convertToHours(t.time),
                                                billable: t.billable,
                                                retainer: t.client
                                            };
                                        })
                                    },
                                }
                            });

                            this.isLoading = false;

                        }).catch(err => {
                            console.log(err)
                            this.isLoading = false;
                        });

                    }
                });
                once();
            }
        }
    }
</script>

<style lang="scss">
    .c-datepicker--dropdown {
        border: 0;
        font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        &:focus {
            border: 0;
        }
        .dp__calendar_item {
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
            font-size: theme('fontSize.xs');
        }
        .dp__action.dp__select {
            border: 2px solid #010101;
            border-radius: 6px;
            font-size: theme('fontSize.sm');
            display: inline-block;
            padding: 6px 16px;
            color: #010101;
            font-weight: 500;
        }
        .dp__calendar_header_item {
            font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif;
        }
        .dp__calendar_header_separator {
            display: none;
        }
        .dp__range_end, .dp__range_start {
            background: theme('colors.grey-900');
            font-weight: bold;
        }
        .dp__range_between {
            font-weight: 500;
            background: theme('colors.grey-50');
        }
        .dp__inner_nav {
            .dp__icon {
                color: theme('colors.grey-900')
            }
        }
    }
</style>
