<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
import { getDatabase, ref, onValue, query, equalTo, orderByChild, limitToFirst } from "firebase/database";
import axios from 'axios';

import store from '@/store';
import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
import Loading from '@/components/containers/Loading.vue';
import UserDropdown from '@/components/objects/UserDropdown.vue'
import UserAvatar from '@/components/objects/UserAvatar.vue';
import AccountSettingsValueToLabel from '@/helpers/AccountSettingsValueToLabel';

import PermissionHelpers from '@/helpers/PermissionHelpers';

import Container from '@/components/containers/Container.vue';

import UserPlusIcon from '~icons/my-icons/user-plus';
import SearchIcon from '~icons/my-icons/search';
import PlusClose from '~icons/my-icons/cross';
import IconArrowDown from '~icons/my-icons/sort-down';
import CopyIcon from '~icons/my-icons/copy';
import PersonIcon from '~icons/my-icons/person-icon';
import TickIcon from '~icons/my-icons/tick';

import Fuse from 'fuse.js';

</script>

<template>
	<Loading :isLoading="snapshotLoading">
		<div class="pb-20 flex-1 ml-[196px] relative">
			<Container>
				<div class="py-12">
					<div class="w-full flex justify-between items-center">
					<h1 class="text-3xl font-bold">Contacts</h1>
						<div class="flex">
							<div class="relative">
								<SearchIcon class="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
								<input type="text" :value="searchQuery" @input="searchQuery = $event.target.value"
									class="block rounded border border-bd w-[300px] text-ellipsis px-12 py-2.5 h-12"
									placeholder="Search contacts" />
								<button v-if="searchQuery.length > 0" @click="() => searchQuery = ''"
									class="absolute top-1/2 right-4 -translate-y-1/2">
									<PlusClose class="w-3" />
									<span class="sr-only">Clear search</span>
								</button>
							</div>
							<router-link v-if="PermissionHelpers.CanAddUsers()"
								:to="{ name: 'Client - Add new contact', params: { clientId: $route.params.clientId } }"
								class="ml-5 relative flex items-center bg-orange-500 rounded text-white py-2.5 px-5">
								<UserPlusIcon class="pointer-events-none w-5 h-5 mr-2 text-[0.6rem]" />
								<span>Add new contact</span>
							</router-link>
						</div>
					</div>
				</div>
				<div class="border border-grey-200 rounded-lg">
					<table class="w-full">
						<thead class="border-none">
							<tr class="bg-gray-50 border-b border-gray-200">
								<th class="rounded-tl-lg w-[288px]">
									<button class="block px-8 py-4 w-full text-left text-purple-900 text-xs font-semibold"
										@click="handleSortBy('name')">
										Name
										<span v-if="sort.sortBy === 'name'" class="text-[10px] inline-block ml-2"
											:class="{ 'rotate-180': sort.direction === 'desc' }">
											<IconArrowDown />
										</span>
									</button>
								</th>
								<th class="">
									<button @click="handleSortBy('role')"
										class="w-[148px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold">
										Job title
										<span v-if="sort.sortBy === 'retainer'" class="text-[10px] inline-block ml-2"
											:class="{ 'rotate-180': sort.direction === 'desc' }">
											<IconArrowDown />
										</span>
									</button>
								</th>
								<!--
								<th class=" w-[288px]">
									<button class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
										@click="handleSortBy('retainer')">Retainer
										<span v-if="sort.sortBy === 'retainer'" class="text-[10px] inline-block ml-2"
											:class="{ 'rotate-180': sort.direction === 'desc' }">
											<IconArrowDown />
										</span></button>
								</th>
								-->
								<th class="">
									<button class="w-[168px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
										@click="handleSortBy('accountSettings')">Account
										<span v-if="sort.sortBy === 'accountSettings'" class="text-[10px] inline-block ml-2"
											:class="{ 'rotate-180': sort.direction === 'desc' }">
											<IconArrowDown />
										</span></button>
								</th>
								<th class="">
									<button class="w-[108px] block px-2 py-4 text-left text-purple-900 text-xs font-semibold"
										@click="handleSortBy('lastActive')">Last active
										<span v-if="sort.sortBy === 'lastActive'" class="text-[10px] inline-block ml-2"
											:class="{ 'rotate-180': sort.direction === 'desc' }">
											<IconArrowDown />
										</span></button>
								</th>
								<th class="w-[96px]  rounded-tr-lg">
									<!-- Options -->
								</th>
							</tr>
						</thead>
						<tbody v-if="filteredList.length > 0" class="">
							<tr v-for="(user) in filteredList" :key="user.item.email"
								class="border-b border-solid last:border-none group">
								<td class="bg-white pl-8 pr-2 py-3 text-xs group-last:rounded-bl-lg">
									<div class="inline-flex items-center">
										<router-link class="inline-flex items-center"
											:to="{ name: 'Client - User profile', params: { userid: user.item.uid, clientId: $route.params.clientId } }">
											<span
												class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[64px] min-h-[64px] inline-flex items-center justify-center bg-center bg-cover bg-black"
												:style="{ backgroundImage: `url(${user.item.profileImage})` }">
												<UserAvatar v-if="!user.item.profileImage"
													:name="`${user.item.firstName} ${user.item.lastName}`" />
											</span>
										</router-link>
										<div>
											<router-link class="inline-flex items-center"
												:to="{ name: 'Client - User profile', params: { userid: user.item.uid, clientId: $route.params.clientId } }">
												<span class="mb-1 text-grey-900 text-xs block">{{ user.item.firstName }}
													{{ user.item.lastName }}</span>
											</router-link>
											<span class="flex items-center text-grey-400 text-xs group cursor-pointer"
												@click="handleCopyEmail(user.item.email)">{{ user.item.email }}
												<CopyIcon class="ml-2 opacity-0 hover:text-blue-500 text-grey-900 group-hover:opacity-100">
												</CopyIcon>
											</span>
										</div>
									</div>
								</td>
								<td class="bg-white px-2 py-3 text-xs">
									{{ user.item.roleTitle ? user.item.roleTitle : '' }}
								</td>
								<!--
								<td class="bg-white px-2 py-3 text-xs">

									<router-link v-if="user.retainer && user.retainer.length > 0"
										class="inline-flex items-center"
										:to="{ name: 'Beta Dashboard Reports', params: { retainerId: user.retainer[0] } }">
										<span v-for="(projects, index) in user.retainer" :key="projects">
											{{ ProjectValueToLabel(projects) }}<span class="inline-block"
												v-if="user.retainer.length !== 1 && index != Object.keys(user?.retainer).length - 1">,&nbsp;</span>
										</span>
									</router-link>
									<span v-if="!user.retainer">N/A</span>
								</td>
								-->
								<td class="bg-white px-2 py-3 text-xs">
									<div v-for="setting in user.item.accountSettings" :key="setting">
										<div class="flex items-center mb-1 last:mb-0"
											v-if="setting !== 'receive-monthly-report' && setting !== 'can-add-users'">
											<template v-if="setting === 'primary-contact'">
												<PersonIcon class="mr-2.5 w-3" />
											</template>
											<template v-else>
												<TickIcon class="mr-2.5 w-3" />
											</template>
											<div>
												{{ AccountSettingsValueToLabel(setting) }}
											</div>
										</div>
									</div>
								</td>
								<td :class="!user.item?.lastLoginTime ? 'text-gray-400' : ''" class="bg-white px-2 py-3 text-xs">
									{{ user.item?.lastLoginTime ? (user.item?.lastLoginTime) : 'Registration pending' }}
								</td>
								<td class="bg-white px-2 py-3 text-xs text-right group-last:rounded-br-lg">
									<div class="inline-flex items-center px-4">
										<UserDropdown type="client" :clientId="$route.params.clientId" value="view" :userData="user.item"
											:userId="user.item.uid" @handleCopyEmail="handleCopyEmail(user.item?.email)"
											@handleDeleteModal="handleDeleteUserModal"
											@handleResendConfirmation="sendConfirmationMail(user.item)" :disabled="isLoading" />
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="filteredList.length === 0">
							<tr class="border-b border-solid last:border-none">
									<td class="bg-white py-8  gap-3 text-xs text-center" colspan="6">
											<p class="pb-3">No contacts found</p>
											<button @click="handleFullReset" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">
													Reset filters
											</button>
											
									</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Container>
		</div>
	</Loading>
	<router-view></router-view>
</template>

<script>
export default {
	data() {
		return {
			searchQuery: '',
			deleteModalActive: false,
			deleteSelectedUser: {},
			notifications: {},
			tileData: [],
			retainerData: null,
			snapshotLoading: true,
			retainerUsers: [],
			internalUsers: [],
			currentUserRole: store.getters.getUserState.permission,
			isLoading: false,
			paginationPage: 1,
			itemsPerPage: 10,
			sort: [],
			clientUsers: []
		}
	},
	watch: {
		'$route'(to, from) {
		}
	},
	computed: {
		filterUsers() {
			const clientUsersArray = Object.entries(this.clientUsers).map(([uid, value]) => ({
				uid,
				...value,
				fullName: `${value.firstName} ${value.lastName}`
			}));

			const fuse = new Fuse(clientUsersArray, {
				keys: ['fullName', 'firstName', 'lastName', 'email', 'team', 'retainer'],
				threshold: 0.1,
				distance: 100,
				includeScore: true,
				minMatchCharLength: 1,
			});

			if (this.searchQuery && this.searchQuery.length > 0) {
				return fuse.search(this.searchQuery || "");
			}

			return clientUsersArray.map(val => ({
				item: Object.assign(val, {}),
				matches: [],
				score: 0.4,
			}));
		},
		paginationPageCount() {
			const { itemsPerPage, } = this;
			return Math.ceil(this.filterUsers.length / itemsPerPage)
		},
		filteredList() {
			const { paginationPage, itemsPerPage } = this;
			const { sortBy, direction } = this.sort;

			// Pagination
			const from = (paginationPage * itemsPerPage) - itemsPerPage;
			const to = paginationPage * itemsPerPage;

			let tempArr = this.filterUsers


			if (sortBy?.length > 0) {
				if (sortBy === 'lastActive') {
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return new Date(a.item.lastLoginTime || 0) - new Date(b.item.lastLoginTime || 0)
						} else {
							return new Date(b.item.lastLoginTime || 0) - new Date(a.item.lastLoginTime || 0)
						}
					});
				} else if (sortBy === 'accountSettings') {
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return (a.item?.accountSettings || []).length - (b.item?.accountSettings || []).length
						} else {
							return (b.item?.accountSettings || []).length - (a.item?.accountSettings || []).length
						}
					});
				} else if (sortBy === 'name') {
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return `${a?.item?.firstName} ${a?.item?.lastName}`.localeCompare(`${b?.item?.firstName} ${b?.item?.lastName}`)
						} else {
							return `${b?.item?.firstName} ${b?.item?.lastName}`.localeCompare(`${a?.item?.firstName} ${a?.item?.lastName}`)
						}
					});
				} else if (sortBy === 'role') {
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return (a?.item?.roleTitle || []).length - (b?.item?.roleTitle || []).length
						} else {
							return (b?.item?.roleTitle || []).length - (a?.item?.roleTitle || []).length
						}
					});
				}
			}

			console.log(tempArr.slice(from, to))

			return tempArr.slice(from, to);
		},
		internalWebcodaUserRole() {
			return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
		},
		isWebcodaAdmin() {
			const currentUser = store.getters.getUserState;
			const viewAsClient = store.getters.getViewAsClient;
			if (viewAsClient) {
				return false
			}
			return currentUser.permission === 'admin'
		},
	},
	async mounted() {

		await initCurrentUserSession(this);
		this.getContacts();
		this.sort.sortBy = 'name';
		this.sort.direction = 'asc';
	},
	methods: {
		handleFullReset() {
			this.searchQuery = '';
		},
		handleSortBy(field) {
			const { direction, sortBy } = this.sort;
			this.sort.sortBy = field;
			if (sortBy === field) {
				this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
			} else {
				this.sort.direction = 'asc'
			}
		},
		getContacts() {

			const db = tenantDatabase();
			const clientUsersRef = query(ref(db, 'users'), orderByChild("client"), equalTo(this.$route.params.clientId));


			onValue(clientUsersRef, (snapshot) => {
				const data = snapshot.val();
				if (data) {
					this.clientUsers = data;
					this.snapshotLoading = false;
				}
			});
			this.snapshotLoading = false;
		},
		handleCopyEmail(email) {
			if (email) {
				navigator.clipboard.writeText(email).then(() => {
					this.$notify({
						title: "",
						text: "Email address successfully copied to your clipboard",
						duration: 5000,
					});
				}, function (err) {
					console.error('Async: Could not copy text: ', err);
				});
			}
		},
		handleDeleteUserModal(uid, user) {
			this.deleteModalActive = true;
			this.deleteSelectedUser.userId = uid;
			this.deleteSelectedUser.user = user;
			document.body.classList.add('overflow-hidden');
		},
		handleDeleteModalClose(success) {
			this.deleteModalActive = false;
			this.deleteSelectedUser = {};
			if (success) {
				this.notifications = 'deleteUser';
			}
		},
		async sendConfirmationMail(user) {
			const auth = await getAuth();
			auth.onAuthStateChanged(currentUser => {
				if (currentUser) {

					const currentToken = auth.currentUser.accessToken;
					const currentUrlBase = window.location.origin;

					let data = {
						Uid: user.uid || null,
						SiteUrl: currentUrlBase
					}

					this.isLoading = true;

					axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						// alert('sent');
						this.isLoading = false;
						this.notifications = 'ResendConfirmation';
						setTimeout(() => {
							this.notifications = '';
						}, 3600)
					}).catch(err => {
						console.log(err)
						this.isLoading = false;
					});
				}
			});
		},
	}
}
</script>