export default {
    dateFrequencies: [
        {
            label: "Weekly",
            value: "weekly",
            calculateDayValue: 7
        },
        {
            label: "Fortnightly",
            value: "fortnightly",
            calculateDayValue: 14
        },
        {
            label: "Monthly",
            value: "monthly",
            calculateDayValue: 30
        },
        {
            label: "Quarterly",
            value: "quarterly",
            calculateDayValue: 90
        },
        {
            label: "6 months",
            value: "half-yearly",
            calculateDayValue: 180
        },
        {
            label: "Yearly",
            value: "yearly",
            calculateDayValue: 365
        }
    ]
}