import axios from "axios";

const baseApi = import.meta.env.VITE_BASE_API;

export default async function RetainersNeedAttention(token) {
    const data = await axios({
        method: 'get',
        url: `${baseApi}/retainer/watch?month=${new Date().getMonth() + 1}&year=${new Date().getFullYear()}&ThresholdPercent=10`,
        headers: {
            'authorization': token,
        },
    }).then(res => {
        return res.data
    }).catch(err => {
        return err
    });

    return data
}
