import { getDatabase, ref, update, push, child } from "firebase/database";
import { getAuth, tenantDatabase } from "../tenant";

export default async function LogAuditLog(input) {
    const { type, object, data, date, user, action } = input;

    // if (window.location.href.includes('localhost')) return;

    const db = tenantDatabase();
    const auth = await getAuth();

    return await push(child(ref(db), 'logs'), {
        type,
        object,
        action,
        data: data || {},
        date: date || new Date().getTime(),
        user: user || auth?.currentUser?.uid || 'anonymous',
        url: window.location.href || "undefined"
    });

}