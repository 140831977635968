<script setup>

    import store from "../store";
    import axios from "axios";
    import * as am4core from "@amcharts/amcharts4/core";
    import * as am4charts from "@amcharts/amcharts4/charts";
    
    import Container from '../components/containers/Container.vue';
    import Header from '../components/objects/Header.vue';
    import SectionPanel from "./containers/SectionPanel.vue";

</script>

<template>
    <div>
        <div class="flex items-center justify-center" v-if="chartData.length > 0">
            <div class="w-full" style="max-width: 100%;">
                <div id="chartdiv" style="height: 360px;"></div>
            </div>
        </div>
        <div class="p-8 text-center" v-else>
            No data available.
        </div>
    </div>
</template>

<script>
    
    export default {
        props: ['tileData'],
        data() {
            return {
                chartData: this.tileData ? this.tileData : []
            }
        },
        computed: {
            
        },
        watch: {
            tileData: function(newVal, oldVal) { 
                this.chartData = newVal;
                setTimeout(()=> {
                    this.initChart(this.chartData);
                }, 100);
                
            }
        },
        mounted() {
            this.initChart(this.chartData);
        },
        methods: {

            initChart(data) {
                
                am4core.disposeAllCharts();

                let rawData = data;
                let arr = [];
                rawData.forEach(item => {
                    // Assuming the month will always be MMMM YYYY
                    const monthSplit = item.title.split(' ');
                    const newMonthStr = monthSplit[0].slice(0, 3) + ' ' + monthSplit[1].slice(2,4);
                    let obj = {
                        date: newMonthStr
                    };
                    let s = JSON.stringify(item.listData)
                    JSON.parse(s).forEach(data => {
                        let name = data.itemName.toLowerCase();
                        let val = parseFloat(data.itemValue)
                        obj[name] = val;
                    });
                    arr.push(obj);
                });

                arr.forEach(item => {
                    const monthHoursAllowed = item.allowance;
                    item.initAllowance = item.allowance;
                    item.initBillable = item.billable;
                    if(item.billable >= monthHoursAllowed) {
                        const overTime = Math.abs(monthHoursAllowed - item.billable);
                        item.billable = monthHoursAllowed;
                        item.rolloverValue = overTime;
                    }
                    else {
                        item.rolloverValue = 0;
                    }
                });

                if(arr.length > 0) {

                    // Create chart instance
                    var chart = am4core.create("chartdiv", am4charts.XYChart);
                    chart.numberFormatter.numberFormat = "#.##"

                    // Add data
                    chart.data = arr;

                    // Create axes
                    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "date";
                    categoryAxis.renderer.grid.template.location = 0;
                    categoryAxis.cursorTooltipEnabled = false;
                    categoryAxis.startLocation = 0;
                    categoryAxis.endLocation = 1;
                    categoryAxis.renderer.minGridDistance = 20;
                    categoryAxis.renderer.labels.template.fontSize = 14;
                    categoryAxis.renderer.grid.template.strokeWidth = 0;

                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.cursorTooltipEnabled = false;
                    
                    createColumnSeries('date', 'allowance', 'Allowance', 'allowance');
                    createColumnSeries('date', 'billable', 'Billable', 'billable');
                    createColumnSeries('date', 'rolloverValue', 'Negative Rollover', 'rollover');

                    function createColumnSeries(category, value, name, type) {

                        // Create series
                        var series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueY = value;
                        series.dataFields.categoryX = category;
                        series.name = name;
                        series.clustered = false;

                        series.columns.template.strokeWidth = 0;
                        series.columns.template.width = 12;
                        
                        // Hover tooltip
                        series.tooltipText = "{categoryX}: [b]{valueY}[/]";
                        series.adapter.add("tooltipText", function(ev, target) {
                            var text = "";
                            chart.series.each(function(item) {
                                if(!item.isHidden) {
                                    if(item.dataFields.valueY == 'allowance' && series.tooltipDataItem.dataContext.initAllowance < 0) {
                                        text += item.name + ": {" + item.dataFields.valueY + "} hrs\n";
                                    } else if(item.dataFields.valueY == 'billable') {
                                        text += item.name + ": " + series.tooltipDataItem.dataContext.initBillable + " hrs\n";
                                    } else {
                                        text += item.name + ": {" + item.dataFields.valueY + "} hrs\n";
                                    }
                                } 
                            });
                            return text;
                        });

                        series.tooltip.getFillFromObject = false;
                        series.tooltip.background.fill = am4core.color("#fff");
                        series.tooltip.label.fill = am4core.color("#00");
                        
                        // Prevent cross-fading of tooltips
                        series.tooltip.defaultState.transitionDuration = 0;
                        series.tooltip.hiddenState.transitionDuration = 0;

                        series.columns.template.column.adapter.add("cornerRadiusTopLeft", function(radius, target){
                            return 10
                        });
                        series.columns.template.column.adapter.add("cornerRadiusTopRight", function(radius, target){
                            return 10
                        });

                        if(type !== undefined && type !== null) {
                            if(type === 'allowance') {
                                series.stacked = true;
                                series.columns.template.adapter.add("fill", function(fill, target) {
                                    return am4core.color("#F0F0F0");
                                });
                            }
                            if(type === 'billable') {
                                series.columns.template.column.adapter.add("cornerRadiusTopLeft", function(radius, target){
                                    if (target.dataItem.dataContext && (target.dataItem.dataContext.remaining < 0)) {
                                        return 0;
                                    }
                                    return radius
                                });
                                series.columns.template.column.adapter.add("cornerRadiusTopRight", function(radius, target){
                                    if (target.dataItem.dataContext && (target.dataItem.dataContext.remaining < 0)) {
                                        return 0;
                                    }
                                    return radius
                                });
                                series.columns.template.adapter.add("fill", function(fill, target) {
                                    return am4core.color("#70BC41");
                                });
                            }
                            if(type === 'rollover') {
                                series.stacked = true;
                                series.columns.template.adapter.add("fill", function(fill, target) {
                                    return am4core.color("#EF4E41")
                                });
                            }
                        }
                    }

                    chart.legend = new am4charts.Legend();
                    chart.cursor = new am4charts.XYCursor();
                    chart.cursor.maxTooltipDistance = -1;

                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
