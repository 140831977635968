<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
import { getDatabase, onValue, ref, update, query, orderByChild, } from "firebase/database";
import axios from 'axios';
import store from '@/store';

import Container from '@/components/containers/Container.vue';
import Header from '@/components/objects/Header.vue';
import Notification from '@/components/objects/Notification.vue';
import Loading from '@/components/containers/Loading.vue';
import ArrowLeft from '~icons/my-icons/arrow-left';
import PermissionsValidation from '@/components/containers/PermissionsValidation.vue';

import EmailIcon from '~icons/my-icons/orange-email';
import LeaderboardIcon from '~icons/my-icons/orange-leaderboard';
import ServicesIcon from '~icons/my-icons/orange-services';

import CheveronDown from '~icons/my-icons/chevron-down'
import NoResultFound from "@/components/objects/NoResultFound.vue";

import moment from 'moment'

</script>

<template>
    <PermissionsValidation :requiredRoles="['admin']">
        <Container class="w-full">
            <div class="pt-10 flex justify-between items-center">
                <h1 class="text-3xl font-bold">Audit Logs</h1>
                <div class="flex relative gap-3">
                    <div class="relative" v-click-outside-element="closeClientSearch">
                        <div @click="openClientSearch" class="flex cursor-pointer items-center px-4  rounded border border-bd w-[300px] text-ellipsis py-2.5 h-12">
                            <p class="w-[248px] truncate">{{ selectedClient ? selectedClient.name : "All clients" }}</p>
                            <CheveronDown :class="showClient && 'rotate-180', selectedClient && 'hidden'" class="h-[20px] w-[20px] ml-3 cursor-pointer duration-150" />
                        </div>
                        <div v-if="showClient" class="min-w-[300px] max-w-[300px] absolute left-3 top-[50px] z-10">
                            <div class="bg-white flex row border border-gray-900 w-full">
                                <ul class="pl-2 max-w-[480px] max-h-[204px] overflow-x-hidden overflow-y-auto">
                                    <li @click="clearSelection" :class="!selectedClient ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''" class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                        All clients
                                    </li>
                                    <template v-if="clients">
                                        <li v-for="client in clients" @click="filterClient(client)" :key="client.clientId" :class="client == selectedClient ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''" class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                                            {{ client.name }}
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <div class="py-10 px-4">
            <div class="rounded-lg border border-solid mb-8">
                <Loading :isLoading="isLoading" type="overlay">
                    <table class="w-full max-w-full">
                        <thead class="border-none bg-grey-50 rounded-lg sticky top-0 z-5">
                            <tr class="bg-gray-50 border-b border-gray-200">
                                <th>
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >User</span>
                                </th>
                                <th>
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >Client</span>
                                </th>
                                <th>
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >Action</span>
                                </th>
                                <th>
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >Data</span>
                                </th>
                                <th class="min-w-[140px]">
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >Date</span>
                                </th>
                                <th>
                                    <span
                                        class="block px-2 py-4 w-full text-left text-purple-900 text-xs font-semibold"
                                    >URL</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr v-for="(log, logId) in paginatedLogs" :key="`${logId}-${log.date}`"
                                :class="ifNewDate(log.date) ? 'animate-injection' : 'bg-white'"
                                class="border-b border-solid last:border-none group">
                                <td class="px-2 py-3 text-xs">
                                    <p>{{ `${getUserDetails(log.user)?.firstName || "Anonymous"} ${getUserDetails(log.user)?.lastName || ""} (${getClientDetails(getUserDetails(log.user)?.client)?.name || getUserDetails(log.user)?.permission || "anonymous"})` }}</p>
                                </td>
                                <td class="px-2 py-3 text-xs">
                                    <p>{{ getClientDetails(log?.data?.clientId)?.name }}</p>
                                </td>
                                <td class="px-2 py-3 text-xs">
                                    <p>{{ log.object }} - {{ log.action }}</p>
                                    <p class="text-grey-300" v-if="log.data?.id">({{ log.data?.id || "" }})</p>
                                </td>
                                <td class="px-2 py-3 text-xs max-w-[700px]">
                                    <p style="word-wrap: break-word">{{ JSON.stringify(log.data) }}</p>
                                </td>
                                <td class="px-2 py-3 text-xs w-[360px]">
                                    <p>{{ moment(log.date).format('MMMM Do YYYY, h:mm:ss a') }}</p>
                                </td>
                                <td class="px-2 py-3 text-xs w-[360px]">
                                    <p style="word-wrap: break-word">{{ log.url }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <NoResultFound :counts="filteredLogs.length" :searchTerm="selectedClient" :handleFullReset="clearSelection" pageName="audit logs" />
                </Loading>
            </div>
            <div v-if="paginationPageCount > 1" class="flex items-center justify-between">
                <button class="mr-3 disabled:text-grey-300 text-sm bg-gray-50" :disabled="paginationPage == 1"
                    @click="handlePaginationPrevNext(true)">
                    <span class="sr-only">Previous page</span>
                    <div class="flex items-center justify-center rounded border border-gray-200 py-2 px-5">
                        <ChevronLeft />
                        <p class="ml-2">Prev</p>
                    </div>
                </button>
                <div>
                    <button class="mx-2"
                        :class="{ 'font-bold w-10 h-10 bg-black rounded-full text-white': index === paginationPage }"
                        v-for="index in paginationPageCount" :key="index" @click="handlePaginationSelect(index)">
                        {{ index }}
                    </button>
                </div>
                <button class="ml-3 disabled:text-grey-300 text-sm bg-gray-50"
                    :disabled="paginationPage == paginationPageCount" @click="handlePaginationPrevNext(false)">
                    <span class="sr-only">Next page</span>
                    <div class="flex items-center justify-center rounded border border-gray-200 px-5 py-2">
                        <p class="pr-2">Next</p>
                        <ChevronRight />
                    </div>
                </button>
            </div>
        </div>
    </PermissionsValidation>
</template>

<script>

export default {
    props: [],
    data() {
        return {
            logs: [],
            users: [],
            clients: [],
            selectedClient: null,
            isLoading: true,
            showClient: false,
            paginationPage: 1,
            paginationPerPage: 100
        }
    },
    mounted() {
        const db = tenantDatabase();

        onValue(query(ref(db, 'logs')), (snapshot) => {
           const data = snapshot.val();
                if (data) {
                    this.logs = Object.values(data).reverse();
                    console.log(this.logs)
                    this.isLoading = false;
                }
                else {
                    this.isLoading = false;
            }
        });

        onValue(ref(db, 'users'), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                this.users = data;
            }
        });

        onValue(ref(db, 'clients'), (snapshot) => {
            const data = snapshot.val();
            if (data) {
                this.clients = data;
                this.clients =  Object.values(this.clients).filter(c => !c.archived)
            }
        });
    },
    computed: {
        paginationPageCount() {
            const { paginationPerPage, } = this;
            return Math.ceil(this.filteredLogs.length / paginationPerPage)
        },
        filteredLogs() {
            return this.selectedClient === null ?  this.logs
                : this.selectedClient !== null && this.selectedClient?.clientId === undefined ? [] :
                this.logs.filter(log => log?.data?.clientId == this.selectedClient?.clientId || "");
        },
        paginatedLogs() {
            const { paginationPage, paginationPerPage } = this;
            // Pagination
            const from = (paginationPage * paginationPerPage) - paginationPerPage;
            const to = paginationPage * paginationPerPage;

            return this.filteredLogs.slice(from, to);
        }
    },
    methods: {
        async handlePaginationSelect(number) {
            this.paginationPage = number;
        },
        handlePaginationPrevNext(prev) {
            const { paginationPage } = this;
            this.paginationPage = prev ? paginationPage - 1 : paginationPage + 1;
        },
        ifNewDate(date) {
            return moment().diff(date) < 3000
        },
        filterClient(team) {
            this.selectedClient = team;
            this.showClient = false;
        },
        clearSelection() {
            this.selectedClient = null;
            this.showClient = false;
        },
        getUserDetails(usersId) {
            return Object.entries(this.users).find(user => user[0] === usersId)?.[1];
        },
        getClientDetails(clientId) {
            return Object.entries(this.clients).find(client => client[0] === clientId)?.[1];
        },
        openClientSearch() {
            this.showClient = true;
        },
        closeClientSearch() {
            this.showClient = false;
        }
    }
}
</script>

<style lang="scss"></style>
