<script setup>
    
</script>

<template>
    <div :class="this.noStyles ? '' : 'bg-white border border-bd rounded-lg'">
        <div v-if="title" class="border-b border-bd px-8 py-4 flex items-center justify-between">
            <h2 class="text-xl font-bold">{{this.title}}</h2>
            <span>{{this.date}}</span>
        </div>
        <slot></slot>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                
            }
        },
        props: ['title', 'date', 'noStyles']
    }

</script>


<style lang="scss" scoped>

</style>