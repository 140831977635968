<script setup>
    import IconChevronDown from '~icons/my-icons/chevron-down';
</script>

<template>
    <div class="text-sm">
        <div class="divide-y-[1px] divide-gray-200">
            <button @click="isActive = !isActive" class="flex items-center justify-between w-full py-2 font-bold">
                {{accordionData.title}}
                <IconChevronDown class="transition-transform duration-300" :class="{'rotate-180': isActive}"/>
            </button>
        </div>
        <div class="pb-2" v-if="isActive">
            <ul class="text-xs list-disc list-inside">
                <li class="mb-1" v-for="item in accordionData.changes" :key="item.detail">
                    {{item.detail}} - <span class="underline">{{item.changedBy}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['data'],
        data: function() {
            return {
                accordionData: this.data ? this.data : null,
                isActive: false
            }
        },
        mounted() {
        }
    }

</script>


<style lang="scss" scoped>

</style>