<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
import { getDatabase, ref, get, update, onValue } from "firebase/database";
import axios from "axios";

import store from '@/store';

	import Datepicker from '@vuepic/vue-datepicker';
	import Container from '@/components/containers/Container.vue';
	import Button from '@/components/objects/Button.vue';
	import Tooltip from '@/components/objects/Tooltip.vue';
	import ArrowLeft from '~icons/my-icons/arrow-left';
	import ArrowRight from '~icons/my-icons/arrow-right';
  import ChevronRight from '~icons/my-icons/chevron-right';
  import ChevronDown from '~icons/my-icons/chevron-down';
  import ChevronLeft from '~icons/my-icons/chevron-left';
	import IconEdit from '~icons/my-icons/edit';
	import IconClose from '~icons/my-icons/cross';
	import IconTick from '~icons/my-icons/tick';
	import IconInfo from '~icons/my-icons/info-dark';
	import IconSync from '~icons/my-icons/sync';
	import IconChevronDown from '~icons/my-icons/chevron-down';
	import IconMenuDot from '~icons/my-icons/menu-dot';
	import Download from '~icons/my-icons/download';
	import IconClock from '~icons/my-icons/clock';
	import TaskList from '@/components/objects/TaskList.vue';
	import Loader from '@/components/objects/Loader.vue';
	import Loading from '@/components/containers/Loading.vue'
	import Notification from '@/components/objects/Notification.vue';
	import Tile from '@/components/objects/Tile.vue'
	import ConfirmationDialog from "@/components/containers/ConfirmationDialog.vue";
	import NoResultFound from "@/components/objects/NoResultFound.vue";

import PermissionsHelpers from '@/helpers/PermissionHelpers';
import projectValueToLabel from '@/helpers/ProjectValueToLabel';
import TeamValueToLabel from '@/helpers/TeamValueToLabel';
import yearMonthFormat from '@/helpers/YearMonthFormat';
import ExportWorkbook from '@/helpers/ExportWorkbook';

import IconArrowDown from '~icons/my-icons/sort-down';
import Ellipsis from '~icons/my-icons/ellipsis';
import Warning from '~icons/my-icons/warning';
import Search from '~icons/my-icons/search';
import { Swiper, SwiperSlide } from 'swiper/vue';

	import { Skeletor } from "vue-skeletor";

	import LogAuditLog from "@/helpers/logAuditLog";

import Fuse from 'fuse.js';

</script>

<template>
	<div class="p-8 bg-white border-b border-solid border-bd" v-if="retainerDetails">
		<div class="w-full">
			<div class="flex flex-col text-3xl font-medium">
				<div v-if="PermissionsHelpers.isWebcodaAdmin()">
					<router-link :to="{ name: 'Retainers Overview', params: { retainerId: $route.params.retainerId } }"
						class="inline-flex items-center">
						<ArrowLeft class="mr-2 w-[16px] h-[16px] text-link" />
						<p class="text-link text-base leading-7">Retainers</p>
					</router-link>
				</div>
				<div v-else>
					<router-link else :to="{ name: 'Beta Dashboard Reports', params: { retainerId: $route.params.retainerId } }"
						class="flex items-center">
						<ArrowLeft class="mr-2 w-[16px] h-[16px] text-link" />
						<p class="text-link text-base leading-7">Reports</p>
					</router-link>
				</div>
					<div class="flex justify-between items-center">
						<span class="text-3xl font-bold leading-[48px] mt-1">Monthly Report</span>
						<div v-if="PermissionsHelpers.isWebcodaAdmin()">
							<div v-if="unPublishedItems" class="p-3 text-xs font-bold text-orange-500 bg-warning-light rounded-lg flex items-center space-x-1 justify-center">
								<Warning class="h-6 w-6" />
								<span>{{ unPublishedItems }} Unpublished time-entries</span>
							</div>
						</div>
					</div>
					<div class="flex w-full mt-8 lg:items-left items-center lg:justify-left" v-if="!loadingSelectedMonthTile">
						<span class="mr-3"><IconClock class="text-grey-200 text-2xl"/></span>
						<div class="text-xl">
							<span class="font-bold text-grey-900">{{ selectedMonthTileData.billableHours }}hrs</span>
							<span class="mx-2 text-purple-900">billable of</span>
							<span class="font-bold text-grey-900">{{selectedMonthTileData.allowanceHours }}hrs</span>
							<span class="mx-2 text-purple-900">allowance,</span>
							<span class="font-bold" :class="selectedMonthTileData.remainingHours < 0 ? 'text-error' : 'text-grey-900'">{{selectedMonthTileData.remainingHours }}hrs</span>
							<span class="mx-2 text-purple-900">remaining</span>
						</div>
						<div v-if="!isStatsLoading">
							<div class="relative flex items-center js-dropdown" v-if="getTileData">
								<button class="p-2 outline-none" @click="isTileOpened = !isTileOpened">
									<IconInfo class="fill-black w-5 h-5" />
								</button>
									<div class="absolute top-full mt-3 w-[360px] lg:w-[720px] 2xl:w-[1080px] py-6 px-6 rounded-md border-bd bg-white border right-0 xl:right-1/2 xl:translate-x-1/2 z-[10]" v-if="isTileOpened">
										<div class="swiper-action text-base cursor-pointer absolute z-50 left-2 top-1/2 -translate-y-1/2" :class="(swiper?.activeIndex || 0) <= 0 && 'opacity-50 cursor-not-allowed'" @click="swiper.slidePrev()">
											<ChevronLeft />
										</div>
										<swiper
											v-if="filterSnapshotMonths && filterSnapshotMonths.length > 0"
											:slides-per-view="1"
											:breakpoints="swiperBreakpoints"
											:space-between="16"
											@swiper="onSwiper"
										>
											<swiper-slide
												:key="tile.title"
												v-for="(tile) in filterSnapshotMonths"
											>
												<div>
													<Tile :data="tile" :retainerId="$route.params.retainerId || 0"/>
												</div>
											</swiper-slide>
										</swiper>
										<div v-else>
											<p class="text-center text-base">There is no tiles available.</p>
										</div>
										<div class="swiper-action text-base cursor-pointer absolute z-50 right-2 top-1/2 -translate-y-1/2" :class="((swiper?.activeIndex || 0) >= (filterSnapshotMonths?.length - 3 || 0) || filterSnapshotMonths.length <= 3) && 'opacity-50 cursor-not-allowed'" @click="swiper.slideNext()">
											<ChevronRight />
										</div>
									</div>
							</div>
						</div>
						<Skeletor v-else class="rounded" width="36" height="36" />
					</div>
					<Skeletor v-else class="rounded mt-8" width="600" height="36" />
				</div>

			<div class="flex w-full mt-12 lg:w-auto justify-start lg:justify-between items-center flex-col md:flex-row">
				<div class="relative w-[400px]">
					<Search class="w-6 h-6 absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
					<input type="text" :value="search" @input="search = $event.target.value"
						class="block rounded border border-bd mr-4 w-full pl-12 py-3 pr-12"
						placeholder="Search task & comments..." />
					<IconClose @click="search = ''" v-if="search.length > 0"
						class="w-3 h-3 absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer" />
				</div>
				<div class="flex items-center justify-center">
					<div v-if="PermissionsHelpers.isWebcodaAdmin()" class="flex">
						<Datepicker class="h-full [&>*]:rounded-r-none datePickRetainer" monthPicker :modelValue="filterMonth" @update:modelValue='updateAdminDataFilter'
							:required="true" :clearable="false" :autoApply="true" :enableTimePicker="false" :format="'MMMM yyyy'" />
							<button @click="goBackOneMonth" class="border-y border-grey-200 px-3"><ChevronLeft /></button>
  						<button @click="goForwardOneMonth" :disabled="isNextMonthLaterThanCurrent" class="border  border-grey-200 px-3 rounded-r"><ChevronRight :class="isNextMonthLaterThanCurrent ? 'text-grey-200' : ''" /></button>
					</div>
					<div v-else class="flex">
						<div v-if="filterableMonths && filterableMonths.length > 0" class="relative mb-2 lg:mb-0 w-full">
							<select class="w-full h-full sm:w-48 py-3 px-4 border rounded border-grey-300 appearance-none"
								:value="filterDateLabel" @change="updateDataFilter($event)">
								<option v-for="date in filterableMonths" :key="date.dateLabel" :value="date.dateLabel">
									{{ date.dateLabel }}
								</option>
							</select>
							<ChevronDown class="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none" />
						</div>
						<Skeletor v-else class="rounded" width="192" height="52" />
					</div>
					<div class="flex ml-4" v-if="PermissionsHelpers.isWebcodaAdmin()">
						<button v-if="PermissionsHelpers.isWebcodaAdmin()" @click="createItem()"
							class="mb-2 lg:mb-0 bg-brand-primary rounded-l-[6px] text-white whitespace-nowrap py-2.5 px-5 inline-flex items-center justify-center">
							Add entry
						</button>
						<div class="relative flex items-center js-dropdown">
							<button :class="PermissionsHelpers.isWebcodaAdmin() ? 'rounded-r-[6px]' : 'rounded-[6px]'"
								class="p-3.5 text-white bg-brand-primary border-l border-scarlet-700"
								@click="contextMenuOpened = !contextMenuOpened">
								<ChevronDown class="w-[24px] h-[24px]" />
								<span class="sr-only">Open menu</span>
							</button>
							<div v-if="contextMenuOpened"
								class="absolute right-0 top-full z-[10] min-w-[300px] bg-white border border-bd p-4 rounded flex flex-col mt-3">
								<button @click="exportData()"
									class="disabled:text-grey-300 disabled:bg-grey-100 mb-2 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center"
									:disabled="getUniqueTaskCount == 0">
									Export
								</button>
								<div v-if="PermissionsHelpers.isWebcodaAdmin()" class="mb-2 block">
									<button @click="syncMonth()"
										class="w-full bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
										Sync
										<IconSync class="ml-3 text-sm" />
									</button>
								</div>
								<template v-if="$route.params.clientId !== 'no-client' && PermissionsHelpers.isWebcodaAdmin()">
									<router-link 
									class="mb-2 bg-orange-500 rounded text-white py-3  px-6 inline-flex items-center justify-center "
										:to="{ name: 'Admin - Reports Overview - Reports', params: { clientId: $route.params.clientId || null } }">
										<button
											>
											Edit client/retainer
										</button>
									</router-link>
									<router-link
									class="mb-2 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center "
									:to="{name: 'Admin - Beta clients', query: { viewing: $route.params.clientId || null } }">
										<button
											>
											View client
										</button>
									</router-link>
								</template>
								<template v-else-if="$route.params.clientId == 'no-client' && PermissionsHelpers.isWebcodaAdmin()">
									<Tooltip
										class="flex"
										content="There is no client attached to this retainer, you can link a retainer on the Clients Overview Page."
										position="top"
									>
										<button class="cursor-default bg-grey-50 text-purple-900/50 w-full text-center border-b last:border-0 mb-2 py-3 px-6">Edit client/retainer</button>
									</Tooltip>
									<Tooltip
										class="flex"
										content="There is no client attached to this retainer, you can link a retainer on the Clients Overview Page."
										position="top"
									>
										<button class="cursor-default bg-grey-50 mb-2 text-purple-900/50 w-full text-center border-b last:border-0 py-3 px-6">View client</button>
									</Tooltip>
								</template>
								<router-link v-if="PermissionsHelpers.isWebcodaAdmin()"
									:to="{ name: 'Admin - Add invoice', params: { retainerId: $route.params.retainerId } }"
									class="mb-2 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
									Log overspend invoice
								</router-link>
								<router-link v-if="PermissionsHelpers.isWebcodaAdmin()"
									:to="{ name: 'Send Report', params: { retainerData: retainerReportData } }"
									class="mb-2 lg:mb-0 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center justify-center">
									Send Report
								</router-link>
							</div>
						</div>
					</div>
					<div class="flex items-center justify-center" v-else>
						<button @click="exportData()"
							class="disabled:text-grey-300 ml-4 disabled:bg-grey-100 bg-orange-500 rounded text-white py-3 px-4 inline-flex items-center justify-center"
							:disabled="getUniqueTaskCount == 0">
							<Download class="text-white mr-1.5" />
								Export to Excel
						</button>
					</div>
				</div>

			</div>
		</div>
	</div>
	<div class="px-6 md:px-8 pb-20 md:pt-4">
		<Notification :notificationType="notifications" />
		<Loading :isLoading="isLoading" type="overlay" :loadText="'Loading...'">

			<div v-if="retainerReportHistory && PermissionsHelpers.isWebcodaAdmin()" class="mt-4">
				<div class="border p-4 bg-blue-50 border-blue-100 rounded-lg flex">
					<span
						class="w-6 h-6 inline-flex items-center justify-center bg-blue-400 text-white rounded-full mr-3">i</span>
					<div class="w-full">
						<div class="flex justify-between items-center w-full">
							<div class="text-xs mt-1">
								<span class="font-bold inline-block border-r pr-2 border-blue-100 mr-2">Report sent</span>
								<span class="uppercase">{{ new Date(retainerReportHistory.sentDate).toLocaleString('en-AU') }}</span>
								by <span class="underline">{{ retainerReportHistory.sentBy }}</span>.
								Recipients:
								<span v-for="(user, i) in retainerReportHistory.recipients" :key="user.name">
									<span class="underline">{{ user.label }}</span><span
										v-if="i !== retainerReportHistory.recipients.length - 1">, </span>
								</span>
							</div>
							<button @click="handleRetainerHistoryAccordion()" v-if="retainerReportHistory.openedBy?.length > 0">
								<span class="sr-only">Expand</span>
								<span class="inline-block transition" ref="reportHistoryBtn">
									<ChevronDown />
								</span>
							</button>
						</div>
						<div class="w-full hidden" ref="reportHistoryContent">
							<ul class="text-xs" v-if="retainerReportHistory.openedBy?.length > 0">
								<li class="flex items-center mt-3" v-for="user in retainerReportHistory.openedBy" :key="user.name">
									<span class="font-bold inline-block border-r pr-2 border-blue-100 mr-2">Report opened</span>
									<div>
										<span class="uppercase">{{ new Date(user.dateTime).toLocaleString('en-AU') }}</span>
										by
										<span class="underline">{{ user.name }}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div v-if="(overspendInvoices && currentOverspendInvoices && Object.keys(currentOverspendInvoices).length > 0)"
				class="py-2 px-3 mt-12 text-white font-bold rounded-t-lg bg-blue-900">
				Overspend invoices
			</div>
			<div v-if="(overspendInvoices && currentOverspendInvoices && Object.keys(currentOverspendInvoices).length > 0)"
				class="border bg-white border-blue-900 rounded-b-lg overflow-hidden">
				<div class="overflow-auto bg-white touch-auto">
					<table class="text-sm border-collapse w-full table-fixed min-w-[1400px]">
						<tr class="bg-blue-50 border-b border-blue-100 border-solid sticky top-0 z-[5] items-center">
							<th class="px-3 py-4 text-left">
								<span class="font-bold flex items-center w-full">
									Invoice #
								</span>
							</th>
							<th class="px-3 py-4 text-left">
								<span class="font-bold flex items-center w-full">
									Hours billed
								</span>
							</th>
							<th class="px-3 py-4 text-left">
								<span class="font-bold flex items-center w-full">
									Value
								</span>
							</th>
							<th class="px-3 py-4 text-left w-1/2 shrink-0">
								<span class="font-bold flex items-center w-full">
									Description
								</span>
							</th>
							<th class="px-3 py-4 text-left">
							</th>
						</tr>
						<tr v-for="(invoice, invoiceId) in currentOverspendInvoices" :key="invoiceId"
							class="text-grey-900 bg-white">
							<td class="px-3 py-6">
								<p>{{ invoice.invoiceNumber }}</p>
							</td>
							<td class="px-3 py-6">
								<p>{{ invoice.hoursBilled }}hrs</p>
							</td>
							<td class="px-3 py-6">
								<p>${{ invoice.value }}</p>
							</td>
							<td class="px-3 py-6">
								<p>{{ invoice.description }}</p>
							</td>
							<td class="px-3 py-6 text-right">
								<div class="text-right" v-if="PermissionsHelpers.isWebcodaAdmin()">
									<router-link
										:to="{ name: 'Admin - Edit invoice', params: { retainerId: $route.params.retainerId, invoiceId: invoiceId } }"
										class="text-xs text-grey-800 border border-grey-200 border-solid rounded px-3 py-2 bg-white">
										Edit
									</router-link>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>




			<div class="border bg-white border-grey-200 rounded-lg mt-12 overflow-hidden">
				<div class="overflow-auto bg-white touch-auto min-h-[160px]">
					<div class="text-sm border-collapse w-full table-fixed min-w-[1400px]">
						<div
							class="bg-grey-50 border-b border-grey-200 border-solid sticky top-0 z-[5] flex items-center row-col-widths"
							:class="{ 'row-col-widths--client-view': !PermissionsHelpers.isWebcodaAdmin() }">
							<div class="px-3 py-4 text-left">
								<!-- Accordion toggle head -->
							</div>
							<div class="px-3 py-3 text-left flex items-center" v-if="PermissionsHelpers.isWebcodaAdmin()">
								<label class="relative inline-flex items-center justify-center cursor-pointer">
									<input class="peer sr-only" type="checkbox" v-model="selectAllModel" @change="selectAll($event)">
									<span
										class="inline-block w-5 h-5 border bg-white border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
									<span
										class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
										<IconTick class="text-white" />
									</span>
								</label>
							</div>
							<div class="px-3 py-3 text-left" v-if="PermissionsHelpers.isWebcodaAdmin()">
								<div class="relative bg-white js-dropdown">
									<button class="font-bold p-2 border w-full text-left rounded inline-flex justify-between items-center"
										@click="statusDropdown = !statusDropdown">
										Status
										<IconChevronDown :class="{ 'rotate-180': statusDropdown }" class="transition" />
									</button>
									<ul class="absolute left-0 top-full z-[10] bg-white p-2 border flex flex-col rounded"
										v-if="statusDropdown">
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('published')" value="published" class="peer sr-only"
														type="checkbox" @change="handleStatusFilter($event.target.value)">
													<span
														class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
													<span
														class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white" />
													</span>
												</span>
												<span class="ml-2 font-normal">Published</span>
											</label>
										</li>
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('unpublished')" value="unpublished"
														class="peer sr-only" type="checkbox" @change="handleStatusFilter($event.target.value)">
													<span
														class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
													<span
														class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white" />
													</span>
												</span>
												<span class="ml-2 font-normal">Unpublished</span>
											</label>
										</li>
										<li class="inline-flex items-center mb-2 last:mb-0">
											<label class="inline-flex items-center justify-center cursor-pointer">
												<span class="relative inline-flex">
													<input :checked="statusFilter.includes('deleted')" value="deleted" class="peer sr-only"
														type="checkbox" @change="handleStatusFilter($event.target.value)">
													<span
														class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
													<span
														class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
														<IconTick class="text-white" />
													</span>
												</span>
												<span class="ml-2 font-normal">Deleted</span>
											</label>
										</li>
									</ul>
								</div>
							</div>
							<div class="px-3 py-3 text-left">
								<button class="font-bold flex items-center w-full" @click="handleSortBy('date')">
									Date
									<span v-if="sort.sortBy === 'date'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3 text-left">
								<button class="font-bold flex items-center w-full" @click="handleSortBy('taskName')">
									Task
									<span v-if="sort.sortBy === 'taskName'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3 text-left">
								<span class="font-bold flex items-center w-full">
									Comment
								</span>
							</div>
							<div class="px-3 py-3 text-center">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full"
									@click="handleSortBy('userName')">
									Submitted
									<span v-if="sort.sortBy === 'userName'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full"
									@click="handleSortBy('unbillable')">
									<span>
										Non billable
										<span class="font-normal block">({{ filterRetainerData.unbillableTime.toFixed(2) || 0 }}hrs)</span>
									</span>
									<span v-if="sort.sortBy === 'unbillable'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full"
									@click="handleSortBy('billable')">
									<span>
										Billable
										<span class="font-normal block">({{ filterRetainerData.billableTime.toFixed(2) || 0 }}hrs)</span>
									</span>
									<span v-if="sort.sortBy === 'billable'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div class="px-3 py-3">
								<button class="font-bold flex items-center text-center justify-center mx-auto w-full"
									@click="handleSortBy('taskTotalTime')">
									TTL
									<span v-if="sort.sortBy === 'taskTotalTime'" class="text-[10px] inline-block ml-2"
										:class="{ 'rotate-180': sort.direction === 'desc' }">
										<IconArrowDown />
									</span>
								</button>
							</div>
							<div v-if="PermissionsHelpers.isWebcodaAdmin()" class="px-3 py-4 text-center"><span
									class="sr-only">Edit</span></div>
						</div>
						<div class="text-grey-900 bg-white">							
							<NoResultFound :counts="newFilteredData.length" :searchTerm="search" :handleFullReset="handleFullReset" pageName="tasks" />
							<TaskList v-for="task in newFilteredData" :forceExpanded="search.length > 0" :key="task.item.id"
								:data="task.item" :filterDate="filterDate" :selectItemsHandler="handleSelectedItems"
								:selectedItems="selectedItems" />
						</div>
					</div>
				</div>
				<div v-if="PermissionsHelpers.isWebcodaAdmin()">
					<div v-if="anySelected > 0"
						class="fixed bg-purple-900 p-6 bottom-16 text-white rounded-lg left-1/2 -translate-x-1/2 flex items-center justify-center">
						<span>
							{{ anySelected ? anySelected : 0 }} item{{ anySelected > 1 ? 's' : '' }} selected
						</span>
						<div class="ml-6 flex items-center">
							<button v-if="isApproved"
								class="py-2 px-4 bg-red-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="approveSelection(false)">
								Unpublish
							</button>
							<button class="py-2 px-4 bg-red-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="handleDeleteUserModal">
								Delete
							</button>
							<button class="py-2 px-4 bg-green-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="handleDeleteUserModalConfirm(false)">
								Restore
							</button>
							<button v-if="!isApproved"
								class="py-2 px-4 bg-green-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="approveSelection(true)">
								Publish
							</button>
							<button v-if="isBillable"
								class="py-2 px-4 bg-green-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="approveBillableSelection(true)">
								Billable
							</button>
							<button v-if="!isBillable"
								class="py-2 px-4 bg-red-600 inline-block rounded-lg text-white text-sm mr-2 last:mr-0"
								@click="approveBillableSelection(false)">
								Non Billable
							</button>
						</div>
					</div>
				</div>
			</div>
		</Loading>
	</div>
	<ConfirmationDialog :isActive="deleteModalActive"
		:headerText="selectedItems.length > 1 ? 'Are you sure you want to delete this tasks?' : 'Are you sure you want to delete these task?'"
		:descriptionText="deleteUserModalDescription"
		:confirmButtonText="selectedItems.length > 1 ? 'Delete tasks' : 'Delete task'"
		@handleModalClose="handleDeleteModalClose" @handleModalConfirm="handleDeleteUserModalConfirm(true)" />
	<div class="z-[1000] relative">
		<router-view v-slot="{ Component }">
			<component :is="Component" :retainerInfo="retainerInfo" />
		</router-view>
	</div>

</template>

<script>

export default {
	props: ['date', 'notificationType'],
    data() {
      return {
        search: "",
        swiper: null,
        projectName: "",
        retainerId: "",
				filterDate: "",
				filterDateLabel: "",
				filterableMonths: [],
				overspendSubscription: {},
				showDeleted: false,
				resData: [],
				displayData: [],
				displayRetainerData: {},
				editRetainerDataMode: false,
				editRetainerData: {},
				teamUserList: [],
				monthRetainerData: {},
				selectedMonthTileData: {},
				loadingSelectedMonthTile: true,
				retainerDetails: {},
				isLoading: true,
				isStatsLoading: true,
				retainerReportHistory: null,
				statusFilter: ['published', 'unpublished'],
				statusDropdown: false,
				notifications: this.notificationType ? this.notificationType : '',
				sort: {
					sortBy: '',
					direction: 'asc'
				},
				swiperBreakpoints: {
					1536: {
						slidesPerView: 3,
						spaceBetween: 16
					},
					1024: {
						slidesPerView: 2,
						spaceBetween: 16
					}
				},
				selectedItems: [],
				contextMenuOpened: false,
				tileData: [],
				isTileOpened: false,
				forcedRetainer: false,
				deleteModalActive: false,
      };
    },
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId || to?.params?.date) {
					if(PermissionsHelpers.isWebcodaAdmin()) {
						const url = new URL(window.location.href);

					this.forcedRetainer = true;
					const db = tenantDatabase();
					const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
					onValue(retainersRef, snapshot => {
						if (snapshot.val()) {
							this.retainerDetails = snapshot.val();
							store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
						}
					}, { onlyOnce: true });
				} else {

					if (store.getters.getRetainerDetails) {
						this.retainerDetails = store.getters.getRetainerDetails;
					} else {
						const db = tenantDatabase();
						const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
						onValue(retainersRef, snapshot => {
							if (snapshot.val()) {
								this.retainerDetails = snapshot.val();
								store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
							}
						}, { onlyOnce: true });
					}
				}

				const db = tenantDatabase();

				// Unsubscribe to previous retainer

				const overspendInvoicesRef = ref(db, `/overspendInvoices/${to?.params?.retainerId}`);
				this.overspendSubscription = onValue(overspendInvoicesRef, snapshot => {
					if (snapshot.val()) {
						this.overspendInvoices = snapshot.val();

					} else {
						this.overspendInvoices = null
					}
				});

				this.filterableMonths = [];

					this.getTasks(false);
					this.getMonthTileData()
					let selectedMonthDateObj = new Date(`${to?.params?.date}-1`);

					this.filterDate = selectedMonthDateObj;
					this.filterDateLabel = `${selectedMonthDateObj.toLocaleString('default', { month: 'long' })} ${selectedMonthDateObj.getFullYear()}`;

					this.displayData = this.resData.filter(item => {
						let itemDate = new Date(item.date);
						return (
							itemDate.getMonth() === selectedMonthDateObj.getMonth() &&
							itemDate.getFullYear() === selectedMonthDateObj.getFullYear()
						);
					});

					this.getMonthStatistics();
				}
			}
		},
    mounted() {

		this.currentFilterDate = this.$route.params.date

		const db = tenantDatabase();
		const overspendInvoicesRef = ref(db, `/overspendInvoices/${this.$route.params.retainerId}`);
			this.overspendSubscription = onValue(overspendInvoicesRef, snapshot => {
				if (snapshot.val()) {
					this.overspendInvoices = snapshot.val();
				} else {
					this.overspendInvoices = null
				}
			});

		if (PermissionsHelpers.isWebcodaAdmin()) {
			const url = new URL(window.location.href);

			this.forcedRetainer = true;
			const db = tenantDatabase();
			const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
			onValue(retainersRef, snapshot => {
				if (snapshot.val()) {
					this.retainerDetails = snapshot.val();
					store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
				}
			}, { onlyOnce: true });

			
		} else {

			if (store.getters.getRetainerDetails) {
				this.retainerDetails = store.getters.getRetainerDetails;
			} else {
				const db = tenantDatabase();
				const retainersRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
				onValue(retainersRef, snapshot => {
					if (snapshot.val()) {
						this.retainerDetails = snapshot.val();
						store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
					}
				}, { onlyOnce: true });
			}
		}


		this.filterableMonths = [];

		this.getTasks(false, true);

		this.getMonthTileData();

			// Bind click event to close status filter
			document.addEventListener('click', event => {
				if(event.target.closest('.js-dropdown') === null) {
					this.statusDropdown = false;
					this.contextMenuOpened = false;
					this.isTileOpened = false;
				}
			});
    },
		computed: {
			currentMonthIndex() {
      return this.filterableMonths.findIndex(date => date.dateLabel === this.filterDateLabel);
			},
			isFirstMonth() {
				return this.currentMonthIndex === 0;
			},
			isLastMonth() {
				return this.currentMonthIndex === this.filterableMonths.length - 1;
    	},
			isNextMonthLaterThanCurrent() {
				const nextMonth = this.getNextMonth();
				const currentMonth = new Date();
				return nextMonth.year > currentMonth.getFullYear() || 
					(nextMonth.year === currentMonth.getFullYear() && nextMonth.month > currentMonth.getMonth());
			},
			filterSnapshotMonths() {
				let { sortedTileData } = this;

				if (PermissionsHelpers.isWebcodaAdmin()) {
					return sortedTileData;
				}
				// Filter out by tasks count
				return sortedTileData.filter(item => item.listData.filter(l => l.itemName === 'Tasks')[0].itemValue > 0);
			},
      sortedTileData() {
        return (this.tileData || []).sort((a,b) => {
          return new Date('1 ' + b.title) - new Date('1 ' + a.title)
        });
      },
			deleteUserModalDescription() {
				const user = Object.assign({}, this.user);
				const matchingItems = this.displayData.filter(item => this.selectedItems.includes(item.id));

			const taskNames = matchingItems.map(item => item.taskName).join(', ');
			const taskLabel = this.selectedItems.length > 1 ? 'Tasks: ' : 'Task: ';

			return `<div class="flex gap-2"><div class="block mb-1">${taskLabel}</div><div class="font-medium">${taskNames}</div></div>`
		},
		filterMonth() {
			return {
				month: new Date(this.$route.params.date + '-1').getMonth(),
				year: new Date(this.$route.params.date + '-1').getFullYear()
			}
		},
		currentOverspendInvoices() {
			return this.overspendInvoices ? Object.entries(this.overspendInvoices).filter(([invoiceKey, invoiceData]) => {
				return (new Date(invoiceData.effectiveDate).getMonth() == new Date(`${this.$route.params.date}-1`).getMonth()) && (new Date(invoiceData.effectiveDate).getFullYear() == new Date(`${this.$route.params.date}-1`).getFullYear())
			}).reduce((curr, invoice) => {
				return {
					...curr,
					[invoice[0]]: invoice[1]
				}
			}, {}) : {}
		},
		retainerInfo() {
			return {
				dateLabel: this.filterDateLabel,
				retainerId: this.$route.params.retainerId,
				tileData: this.formattedGetTileData,
				unPublishedItems: this.unPublishedItems,
			}
		},
		unPublishedItems() {
			return this.newData?.reduce((count, curr) => count + (curr?.taskList?.filter(item => !item.approved)?.length || 0), 0)
		},
		getTileData() {
			const { tileData, filterDateLabel } = this;
			const selectedTile = tileData?.filter(item => item.title === filterDateLabel)[0];

			return selectedTile;
		},
		formattedGetTileData() {
			const { getTileData } = this;
			if (getTileData) {
				return getTileData.listData.reduce((obj, item) => {
					return {
						...obj,
						[item.itemName]: typeof item.itemValue == "number" ? item.itemValue : item.itemValue ? item.itemValue.replace('hrs', '') : 0
					}
				}, {})
			}
			return null;
		},
		// getPrevMonth() {
		// 	const { filterDateLabel } = this;
		// 	const date = new Date(`1 ${filterDateLabel}`);
		// 	const options = { month: 'long' };
		// 	const prevMonth = new Date(date.setMonth(date.getMonth() - 1));
		// 	return prevMonth.toLocaleString('en-US', options);
		// },
		// getNextMonth() {
		// 	const { filterDateLabel } = this;
		// 	const date = new Date(`1 ${filterDateLabel}`);
		// 	const options = { month: 'long' };
		// 	const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
		// 	return nextMonth.toLocaleString('en-US', options);
		// },
		// getPrevMonthLabel() {
		// 	const { filterDateLabel, filterableMonths } = this;
		// 	let currentIndex = 0;
		// 	filterableMonths.forEach((item, index) => {
		// 		item.dateLabel === filterDateLabel ? currentIndex = index : ''
		// 	});
		// 	var options = { month: 'short', year: '2-digit' };
		// 	var date = new Date(`1 ${filterableMonths[currentIndex + 1]?.dateLabel}`);
		// 	return filterableMonths[currentIndex + 1] ? date.toLocaleString('en-US', options) : null;
		// },
		// getNextMonthLabel() {
		// 	const { filterDateLabel, filterableMonths } = this;
		// 	let currentIndex = 0;
		// 	filterableMonths.forEach((item, index) => {
		// 		item.dateLabel === filterDateLabel ? currentIndex = index : ''
		// 	});
		// 	var options = { month: 'short', year: '2-digit' };
		// 	var date = new Date(`1 ${filterableMonths[currentIndex - 1]?.dateLabel}`);
		// 	return filterableMonths[currentIndex - 1] ? date.toLocaleString('en-US', options) : null;
		// },
		// getMonthYear() {
		// 	var filterDate = new Date(`1 ${this.filterDate}`);
		// 	var monthVar = filterDate.getMonth() + 1;
		// 	monthVar = monthVar < 10 ? '0' + monthVar : '' + monthVar;
		// 	var yearVar = filterDate.getFullYear();
		// 	return `${monthVar}-${yearVar}`;
		// },
		getUniqueTaskCount() {
			return this.newData.length;
		},
		createWorkbookExportSettings() {
			return {
				datePeriod: this.filterDateLabel,
				name: this.projectName
			}
		},
		anySelected() {
			return this.selectedItems.length
		},
		approvedTasksHours() {
			const arr = this.displayData.filter(item => {
				return item.approved === true
			});
			let sum = 0;
			arr.forEach(task => {
				sum += task.time
			});
			return this.convertToHours(sum)
		},
		newFilteredData() {
			const fuse = new Fuse(this.newData, {
				keys: ['taskList.comment', 'taskList.taskName'],
				threshold: 0.1,
				ignoreLocation: true,
                minMatchCharLength: 1
			})

			if (this.search) {
				return fuse.search(this.search || "")
			}
			const data = this.newData.map(val => ({
				item: Object.assign({...val, approved: !val?.taskList.find(d => !d.approved)}, {}),
				matches: [],
				score: 1
			}));
			
			return data;
		},
		newData() {
			const { statusFilter, displayData } = this;
			const { sortBy, direction } = this.sort;
			// group by unique task id
			const groupedData = displayData.reduce((r, a) => {
				r[a.externalTaskId] = r[a.externalTaskId] || {};
				r[a.externalTaskId].taskList = r[a.externalTaskId].taskList || [];
				r[a.externalTaskId].taskName = r[a.externalTaskId].taskName || a.taskName;
				r[a.externalTaskId].taskUrl = r[a.externalTaskId].taskUrl || a.taskUrl;
				r[a.externalTaskId].freshdeskTicketId = r[a.externalTaskId].freshdeskTicketId || a.freshdeskTicketId;
				r[a.externalTaskId].time = r[a.externalTaskId].time + a.time || a.time;
				r[a.externalTaskId].billableTime = r[a.externalTaskId].billableTime || 0;
				r[a.externalTaskId].unBillableTime = r[a.externalTaskId].unBillableTime || 0;
				r[a.externalTaskId].taskTotalTime = r[a.externalTaskId].taskTotalTime || a.taskTotalTime;
				r[a.externalTaskId].userName = r[a.externalTaskId].taskList.length > 0 ? '' : a.userName;
				r[a.externalTaskId].taskUnbillable = r[a.externalTaskId].taskUnbillable ? true : a.taskUnbillable;
				r[a.externalTaskId].approved = r[a.externalTaskId].approved ? true : a.approved;
				r[a.externalTaskId].status = r[a.externalTaskId].status === 'Modified' ? r[a.externalTaskId].status : a.status;
				r[a.externalTaskId].taskList.push(a);
				r[a.externalTaskId].dateFrom ? new Date(r[a.externalTaskId].dateFrom).getTime() > new Date(a.date).getTime() ? r[a.externalTaskId].dateFrom = a.date : '' : r[a.externalTaskId].dateFrom = a.date;
				r[a.externalTaskId].dateTo ? new Date(r[a.externalTaskId].dateTo).getTime() < new Date(a.date).getTime() ? r[a.externalTaskId].dateTo = a.date : '' : r[a.externalTaskId].dateTo = a.date;
				if (a.taskUnbillable) {
					r[a.externalTaskId].unBillableTime = r[a.externalTaskId].unBillableTime ? r[a.externalTaskId].unBillableTime + a.time : a.time;
				} else {
					r[a.externalTaskId].billableTime = r[a.externalTaskId].billableTime ? r[a.externalTaskId].billableTime + a.time : a.time;
				}
				return r;
			}, Object.create(null));

			const arr = [];
			for (const property in groupedData) {
				arr.push(groupedData[property])
			}

			let tempArr = [];

			if (statusFilter.length > 0) {
				const a = arr.map(taskGroup => {
					let b = [];
					if (PermissionsHelpers.isWebcodaAdmin()) {
						if (statusFilter.includes('published')) {
							b = b.concat(taskGroup.taskList.filter(item => item.approved));
						}
						if (statusFilter.includes('unpublished')) {
							b = b.concat(taskGroup.taskList.filter(item => !item.approved));
						}
						if (statusFilter.includes('deleted') && !statusFilter.includes('unpublished')) {
							b = b.concat(taskGroup.taskList.filter(item => item.status === 'Deleted'));
						}
					} else {
						b = b.concat(taskGroup.taskList.filter(item => item.approved));
					}

					return {
						...taskGroup,
						taskList: b
					};
				});
				tempArr = tempArr.concat(a);
			}

			if (sortBy.length > 0) {
				if (sortBy === 'date') {	//  number sorting
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return new Date(a.dateFrom) - new Date(b.dateFrom)
						} else {
							return new Date(b.dateFrom) - new Date(a.dateFrom)
						}
					});
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => {
							if (direction === 'asc') {
								return new Date(a.date) - new Date(b.date)
							} else {
								return new Date(b.date) - new Date(a.date)
							}
						});
					});
				} else if (sortBy === 'unbillable') {
					tempArr.sort((a, b) => direction === 'asc' ? a.unBillableTime - b.unBillableTime : b.unBillableTime - a.unBillableTime);
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => direction === 'asc' ? new Date(a.time) - new Date(b.time) : new Date(b.time) - new Date(a.time));
					});
				} else if (sortBy === 'billable') {
					tempArr.sort((a, b) => direction === 'asc' ? a.billableTime - b.billableTime : b.billableTime - a.billableTime);
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => direction === 'asc' ? new Date(b.time) - new Date(a.time) : new Date(a.time) - new Date(b.time));
					});
				} else if (sortBy === 'taskTotalTime') {
					tempArr.sort((a, b) => direction === 'asc' ? a.taskTotalTime - b.taskTotalTime : b.taskTotalTime - a.taskTotalTime);
				} else {
					tempArr.sort((a, b) => {
						if (direction === 'asc') {
							return (a[sortBy] || '').localeCompare((b[sortBy] || ''))
						} else {
							return (b[sortBy] || '').localeCompare((a[sortBy] || ''))
						}
					});
					tempArr.forEach(item => {
						item.taskList.sort((a, b) => {
							if (direction === 'asc') {
								return (a[sortBy] || '').localeCompare((b[sortBy] || ''))
							} else {
								return (b[sortBy] || '').localeCompare((a[sortBy] || ''))
							}
						});
					});
				}
			}


			return tempArr.length > 0 ? tempArr : []
		},

		isApproved() {
			// Get the 'approved' values for each selected item
			const selectedItemsApproved = this.selectedItems.map(selectedId => {
				// Find the corresponding item in 'displayData'
				const item = this.displayData.find(data => data.id === selectedId);
				// Return the 'approved' value if the item is found, otherwise default to false
				return item ? item.approved : false;
			});
			// Check if all selected items have 'approved' set to true
			return selectedItemsApproved.every(approved => approved);
		},
		isBillable() {
			
			const selectedItemsApproved = this.selectedItems.map(selectedId => {
				
				const item = this.displayData.find(data => data.id === selectedId);
				// console.log('item', item.taskUnbillable);
				// console.log('item test', item ? item.taskUnbillable : false);
				return item ? item.taskUnbillable : false;
			});
			return selectedItemsApproved.every(approved => approved);
		},
		filterRetainerData() {
			const { convertToHours, newData } = this;
			const filteredData = {
				billableTime: newData.reduce((a, b) => a + b.billableTime, 0) / 60 / 60,
				unbillableTime: newData.reduce((a, b) => a + b.unBillableTime, 0) / 60 / 60,
			}
			return filteredData;
		},
		retainerReportData() {
			let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);
			let month = currentFilterDate.getMonth() + 1;
			let year = currentFilterDate.getFullYear();

			const data = {
				retainerName: this.projectName,
				reportMonth: this.filterDateLabel,
				year: year,
				month: month,
				reportNextMonth: this.getNextMonth,
				retainerId: this.$route.params.retainerId,
				retainerStats: this.displayRetainerData,
				taskCount: this.getUniqueTaskCount
			}
			return JSON.stringify(data)
	},
		totalHoursObj() {
			return {
				billable: this.filterRetainerData.billableTime,
				unbillable: this.filterRetainerData.unbillableTime
			}
		}
	},
    methods: {
		goBackOneMonth() {
			const prevMonth = this.getPrevMonth();
			this.updateAdminDataFilter(prevMonth);
		},
		goForwardOneMonth() {
			const nextMonth = this.getNextMonth();
			this.updateAdminDataFilter(nextMonth);
		},
		getPrevMonth() {
			const date = new Date(`1 ${this.filterDateLabel}`);
			date.setMonth(date.getMonth() - 1);
			return { year: date.getFullYear(), month: date.getMonth() };
		},
		getNextMonth() {
			const date = new Date(`1 ${this.filterDateLabel}`);
			date.setMonth(date.getMonth() + 1);
			return { year: date.getFullYear(), month: date.getMonth() };
		},
    getPrevMonthLabel() {
      const { filterDateLabel, filterableMonths } = this;
      let currentIndex = 0;
      filterableMonths.forEach((item, index) => {
        if (item.dateLabel === filterDateLabel) {
          currentIndex = index;
        }
      });
      const options = { month: 'short', year: '2-digit' };
      const date = new Date(`1 ${filterableMonths[currentIndex + 1]?.dateLabel}`);
      return filterableMonths[currentIndex + 1] ? date.toLocaleString('en-US', options) : null;
    },
    getNextMonthLabel() {
      const { filterDateLabel, filterableMonths } = this;
      let currentIndex = 0;
      filterableMonths.forEach((item, index) => {
        if (item.dateLabel === filterDateLabel) {
          currentIndex = index;
        }
      });
      const options = { month: 'short', year: '2-digit' };
      const date = new Date(`1 ${filterableMonths[currentIndex - 1]?.dateLabel}`);
      return filterableMonths[currentIndex - 1] ? date.toLocaleString('en-US', options) : null;
    },
    getMonthYear() {
      const filterDate = new Date(`1 ${this.filterDate}`);
      let monthVar = filterDate.getMonth() + 1;
      monthVar = monthVar < 10 ? '0' + monthVar : '' + monthVar;
      const yearVar = filterDate.getFullYear();
      return `${monthVar}-${yearVar}`;
    },
			exportData() {
				ExportWorkbook(this.displayData, this.totalHoursObj, this.createWorkbookExportSettings);
				LogAuditLog({
						type: "Retainer",
						object: "ExportRetainer",
						action: "run",
						data: {
								totalHours: this.totalHoursObj,
								clientId: this.$route.params.clientId,
								retainerId: this.$route.params.retainerId,
						}
				})
			},
      onSwiper(swiper) {
        this.swiper = swiper
      },
			monthDiff(d1, d2) {
				var months;
				months = (d2.getFullYear() - d1.getFullYear()) * 12;
				months -= d1.getMonth();
				months += d2.getMonth();
				return months <= 0 ? 0 : months;
			},
		handleSelectedItems(idArray) {
			if (this.selectedItems.some(r => idArray.includes(r))) {
				this.selectedItems = this.selectedItems.filter(item => {
					return !idArray.includes(item)
				});
			} else {
				idArray.forEach(id => this.selectedItems.push(id));
			}
		},
		async getRetainerReportHistory(retainerId, yearMonth) {
			this.retainerReportHistory = null;
			const db = tenantDatabase();
			const retainerReportHistoryRef = ref(db, `/retainerReportHistory/${retainerId}/${yearMonth}`);
			onValue(retainerReportHistoryRef, async snapshot => {
				const data = snapshot.val();
				this.retainerReportHistory = data;
				const isARecipient = data?.recipients?.filter(user => {
					return user.email === store.getters.getUserState.email
				}).length > 0;
				let firstTimeOpening = !data?.openedBy?.filter(user => {
					return user.email === store.getters.getUserState.email
				}).length > 0;
				if (isARecipient && firstTimeOpening) {
					const auth = await getAuth();
					const once = auth.onAuthStateChanged(user => {
						if (user) {
							const { retainerId, filterDate } = this;
							const yearMonthString = `${new Date(filterDate).getFullYear()}-${new Date(filterDate).getMonth() + 1}`;
							const updates = {};
							const retainerHistoryData = {
								name: user.displayName,
								dateTime: new Date(),
								email: user.email,
								uid: user.uid
							};
							let openedByArr = [];
							if (data.openedBy) {
								openedByArr = data.openedBy;
							}
							openedByArr.push(retainerHistoryData);

							updates[`/retainerReportHistory/${retainerId}/${yearMonthString}/openedBy`] = openedByArr;
							update(ref(db), updates);
						}
					});
					once();
				}
			}, { onlyOnce: true });
		},
		handleSortBy(field) {
			const { direction, sortBy } = this.sort;
			this.sort.sortBy = field;
			if (sortBy === field) {
				this.sort.direction = direction === 'asc' ? 'desc' : 'asc';
			} else {
				this.sort.direction = 'asc'
			}
		},
		updateDateUrl(date) {
			if (PermissionsHelpers.isWebcodaAdmin()) {
				this.$router.replace({ params: { retainerId: this.$route.params.retainerId, date: yearMonthFormat(date) } });
			} else {
				this.$router.replace({ params: { date: yearMonthFormat(date) } });
			}

		},
		handleStatusFilter(val) {
			const { statusFilter } = this;
			if (val === 'deleted') {
				statusFilter.includes(val) ? this.getTasks(false) : this.getTasks(true);
			}
			statusFilter.includes(val) ? this.statusFilter = statusFilter.filter(item => item !== val) : this.statusFilter.push(val);
		},
		createItem() {
			//TODO: move if this is not the correct way of setting current retainer
			store.commit('updateActiveRetainerState', this.$route.params.retainerId);

			this.$router.push({
				name: 'Edit',
				params: {
					dataid: "new"
				}
			});
		},
		async syncMonth() {
			const auth = await getAuth();
			auth.onAuthStateChanged(user => {
				if (user) {
					const currentToken = auth.currentUser.accessToken;
					let currentFilterDate = new Date(`1 ${this.filterDateLabel}`);

					let from = `${currentFilterDate.getFullYear()}-${currentFilterDate.getMonth() + 1}-1`;
					let nextMonth = new Date(currentFilterDate.getFullYear(), currentFilterDate.getMonth() + 1, 1);
					let to = `${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-1`;
					this.isLoading = true;
					axios.get(`${this.$root.apiBaseUrl}/import/projectTimeRecords/${this.$route.params.retainerId}/${from}/${to}`, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						},
						params: {
							onlyApproved: this.syncOnlyApproved
						}
					}).then(res => {
						this.dataObj = res.data;
						this.getTasks();
						this.isLoading = false;
					}).catch(err => {
						console.log(err)
						this.isLoading = false;
					});
				}
			});
		},
		getProgress(task) {
			let res = task.taskTotalTime ? this.convertToHours(task.taskTotalTime, true) : "0"
			if (task.taskEstimateTime && PermissionsHelpers.isWebcodaAdmin()) {
				res += " / " + this.convertToHours(task.taskEstimateTime, true)
			}
			return res;
		},
		convertDateString(ds, monthYear) {
			var options = monthYear ? { month: 'long', year: 'numeric' } : { month: 'short', day: 'numeric' };
			var date = new Date(ds);
			return date.toLocaleString('en-US', options)
		},
		convertToHours(seconds, decimals) {
			if (!seconds) {
				return 0 + 'hrs'
			}
			if (decimals) {
				var timeInt = parseInt(seconds)
				var hours = (timeInt / 3600)
				return Math.round((hours + Number.EPSILON) * 100) / 100 + `${h <= 1 ? 'hr' : 'hrs'}`;
			} else {
				var d = Number(seconds);
				var h = Math.floor(d / 3600);
				var m = Math.floor(d % 3600 / 60);
				var s = Math.floor(d % 3600 % 60);
				return m > 0 ? `${h}hr${h <= 1 ? '' : 's'} ${m}mins` : `${h}hr${h <= 1 ? '' : 's'}`
			}
		},
		handleDeleteModalClose() {
			this.deleteModalActive = false;
			this.selectedUser = {}
		},
		async handleDeleteUserModalConfirm(deleteItems) {
			const auth = await getAuth();
			var req = this.selectedItems;
			auth.onAuthStateChanged(user => {
				if (user) {
					this.isLoading = true;
					const currentToken = auth.currentUser.accessToken;
					axios.post(`${this.$root.apiBaseUrl}/timerecord/deleteEntries/${this.$route.params.retainerId}/${deleteItems}`, req, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						this.selectedItems?.map(item => {
							let task = this.displayData.find(i => item == i.id);
							LogAuditLog({
									type: "Retainer",
									object: `Task`,
									action: deleteItems ? "delete" : "restore",
									data: {
										id: item,
										taskName: task.taskName,
										clientId: this.$route.params.clientId,
										retainerId: this.$route.params.retainerId,
									}
							})
						})
						this.getTasks(this.statusFilter.includes('deleted') ? true : false);
						this.selectAllModel = false;
						this.deleteModalActive = false;
					}).catch(err => {
						console.log(err);
					})
				}
			});
		},
		handleDeleteUserModal() {
			this.deleteModalActive = true;
		},
		async approveSelection(approve) {
			const auth = await getAuth();
			let ids = this.selectedItems;
			let req = {
				timeRecordIds: ids,
				comment: ''
			};
			auth.onAuthStateChanged(user => {

				if (user) {
					this.isLoading = true;
					const currentToken = auth.currentUser.accessToken;
					axios.post(`${this.$root.apiBaseUrl}/timerecord/approveEntries/${this.$route.params.retainerId}/${approve}`, req, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						console.log(this.displayData)
						this.selectedItems?.map(item => {
							let task = this.displayData.find(i => item == i.id);
							LogAuditLog({
									type: "Retainer",
									object: `Task`,
									action: approve ? "publish" : "unpublish",
									data: {
										id: item,
										taskName: task.taskName,
										clientId: this.$route.params.clientId,
										retainerId: this.$route.params.retainerId
									}
							})
						})
						this.getTasks(this.showDeleted);
						this.selectAllModel = false;
					}).catch(err => {
						console.log(err);
					})
				}
			});
		},
		approveBillableSelection(approve) {
      const auth = getAuth();
      let ids = this.selectedItems;
      auth.onAuthStateChanged(user => {
        if (user) {
          this.isLoading = true;
          const currentToken = auth.currentUser.accessToken;
          ids.forEach(id => {
            const item = this.displayData.find(data => data.id === id);
            if (item) {
							// console.log('this.convertToHours', this.convertToHours(item.time, true))
							let convertedHours = 0;
							let convertedMinutes = 0;

							const convertedTime = this.convertToHours(item.time, true);

							if (convertedTime) {
									const timeWithoutUnit = convertedTime
											.replace('hrs', '')
											.replace('hr', '')
											.trim();

									const totalHours = parseFloat(timeWithoutUnit);

									if (!isNaN(totalHours)) {
											convertedHours = Math.floor(totalHours);
											convertedMinutes = Math.round((totalHours - convertedHours) * 60);

											console.log('Converted Time:', { convertedHours, convertedMinutes });
									} else {
											console.log('Error: Unable to parse time string!');
									}
							} else {
									console.log('Invalid time provided!');
							}

              axios({
                method: 'put',
                url: `${this.$root.apiBaseUrl}/timerecord/${id}`,
                data: {
                  billable: approve,
                  comment: item.comment,
									time: {
										hours: convertedHours || 0,
										minutes: convertedMinutes
									},
                  everhourTaskId: item.everhourTaskId,
                  user: item.user,
                  date: item.date,
                  id: item.id,
                },
                headers: {
                  'authorization': currentToken
                }
              }).then(res => {
                LogAuditLog({
                  type: "Retainer",
                  object: "Task",
                  action: approve ? "make billable" : "make unbillable",
                  data: {
                    id: id,
                    clientId: this.$route.params.clientId,
                    retainerId: this.$route.params.retainerId
                  }
                });
                this.getTasks(this.showDeleted);
                this.selectAllModel = false;
              }).catch(err => {
                console.log(err);
              }).finally(() => {
                this.isLoading = false;
              });
            }
          });
        }
      });
    },
		updateData() {
			var filterDate = new Date(this.filterDate);
			store.commit('updateMonthListingState', this.filterDate);
			this.displayData = this.resData.filter(item => {
				var itemDate = new Date(item.date);
				return itemDate.getMonth() == filterDate.getMonth() && itemDate.getFullYear() == filterDate.getFullYear();
			});

			const rawDate = new Date(this.filterDate);
			const yearMonthFormat = `${rawDate.getFullYear()}-${rawDate.getMonth() + 1}`;
			this.getRetainerReportHistory(this.$route.params.retainerId, yearMonthFormat)

			this.updateDateUrl(rawDate);
		},
		updateDataFilter(event) {
			let selectedMonthDateObj = new Date('1 ' + event.target.value);
			selectedMonthDateObj.setMonth(selectedMonthDateObj.getMonth()); // Add 1 to the month value
			this.filterDate = selectedMonthDateObj;
			this.filterDateLabel = event.target.value;

			this.displayData = this.resData.filter(item => {
				let itemDate = new Date(item.date);
				return (
					itemDate.getMonth() === selectedMonthDateObj.getMonth() &&
					itemDate.getFullYear() === selectedMonthDateObj.getFullYear()
				);
			});
			
			this.updateDateUrl(selectedMonthDateObj);
			this.getMonthStatistics();
		},
		async getMonthTileData() {
			this.loadingSelectedMonthTile = true
			const auth = await getAuth();

			if (auth.currentUser) {
				const currentToken = auth.currentUser.accessToken;
				let currentFilterDate = new Date(`${this.$route.params.date}-1`);
				let month = currentFilterDate.getMonth() + 1;
				let year = currentFilterDate.getFullYear();

				const result = await axios.get(`${this.$root.apiBaseUrl}/Retainer/${this.$route.params.retainerId}/${year}/${month}`, {
					headers: {
						'authorization': currentToken,
						'timezone': this.$root.apiTimezone
					}
				}).then(res => {
					const selectedTile = res.data;

					return selectedTile;
				}).catch(err => {
					console.log(err);
				})
				this.selectedMonthTileData = result
				this.loadingSelectedMonthTile = false
			}
		},
		updateAdminDataFilter(date) {
			let selectedMonthDateObj = new Date(`${date.year}-${date.month}-1`);
			selectedMonthDateObj.setMonth(selectedMonthDateObj.getMonth() + 1);
			this.filterDate = selectedMonthDateObj;
			this.filterDateLabel = `${selectedMonthDateObj.toLocaleString('default', { month: 'long' })} ${selectedMonthDateObj.getFullYear()}`;
			this.displayData = this.resData.filter(item => {
				let itemDate = new Date(item.date);
				return (
					itemDate.getMonth() === selectedMonthDateObj.getMonth() &&
					itemDate.getFullYear() === selectedMonthDateObj.getFullYear()
				);
			});

			this.updateDateUrl(selectedMonthDateObj);
			this.getMonthStatistics();
		},
		async getMonthStatistics() {
			this.isStatsLoading = true;
			const auth = await getAuth();
			const currentToken = auth.currentUser.accessToken;

			const filter = [];
			const today = new Date();
			for (let i = 11; i >= 0; i -= 1) {
				const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
				filter.push({
					month: d.getMonth() + 1,
					year: d.getFullYear()
				});
			}

			if (this.monthDiff(new Date(this.filterDate), new Date()) > 11) {
				filter.push({
					month: new Date(this.filterDate).getMonth() + 1,
					year: new Date(this.filterDate).getFullYear()
				});
			}

			axios({
				method: 'post',
				url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
				data: filter,
				headers: {
					'authorization': currentToken,
					'timezone': this.$root.apiTimezone
				},
				params: {
					clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
				}
			})
				.then(res => {
					this.tileData = res.data;
					this.isStatsLoading = false;

					if (PermissionsHelpers.isWebcodaAdmin()) {
						const monthData = res.data.map(item => ({
							dateLabel: item.title,
							dateObj: new Date(`${item.title}`)
						}))

						this.filterableMonths = monthData
					} else {
						const monthData = res.data
							.filter((item) => item.listData.find(lineItem => lineItem.itemName == "Billable").itemValue !== '0hrs' || item.listData.find(lineItem => lineItem.itemName == "UnBillable").itemValue !== '0hrs')
							.map(item => ({
								dateLabel: item.title,
								dateObj: new Date(`${item.title}`)
							}))

						this.filterableMonths = monthData
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		refreshSearchDeleted(e) {
			var includeDeleted = e.target.checked;
			this.getTasks(includeDeleted);
		},
		async getTasks(includeDeleted, initLoad = false) {
			this.isLoading = true;
			this.filterableMonths = [];
			this.selectedItems = [];
			this.tileDataRequest = [];

			const auth = await getAuth();

			auth.onAuthStateChanged(user => {
				if (user) {
					const currentToken = auth.currentUser.accessToken;
					let currentFilterDate = new Date(`${this.$route.params.date}-1`);

					var monthVar = currentFilterDate.getMonth() + 1;
					var yearVar = currentFilterDate.getFullYear();

					axios
						.get(`${this.$root.apiBaseUrl}/timerecord/search/${this.$route.params.retainerId}`, {
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},
							params: {
								includeDeleted: includeDeleted,
								Month: monthVar,
								Year: yearVar,
								clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
							}
						})
						.then(res => {
							const data = res.data;
							// Sort dates so it's clearer to filter
							data.sort((a, b) => new Date(a.date) - new Date(b.date));


							this.resData = data;

							if (initLoad) {
								let filterDateLabel = '';
								let filterDate = null;

								if (this.$route.params.date) {
									let date
									if (this.$route.params.date == "latest") {
										date = new Date()
									} else {
										date = new Date(new Date(this.$route.params.date + '-1'));
									}
									filterDateLabel = this.convertDateString(date, true);
									filterDate = date;

									this.updateDateUrl(date);
								} else if (this.$route.params.date) {
									const match = this.filterableMonths.find(item => item.dateLabel === this.convertDateString(new Date('1 ' + this.$route.params.date), true));
									if (match) {
										filterDateLabel = this.convertDateString(new Date(new Date(this.$route.params.date + '-1')), true);
										filterDate = new Date(new Date(this.$route.params.date + '-1'));
									}
								}

								if (!filterDate) {
									filterDateLabel = this.filterableMonths[0].dateLabel;
									filterDate = this.filterableMonths[0].dateObj;
								}

								this.filterDateLabel = filterDateLabel;
								this.filterDate = filterDate;
							}

							this.displayData = data.filter(item => {
								const itemDate = new Date(item.date);
								const filterDate = new Date(this.filterDate);
								return (
									itemDate.getMonth() === filterDate.getMonth() &&
									itemDate.getFullYear() === filterDate.getFullYear()
								);
							});

							store.commit('updateMonthListingState', this.filterDate);

							if (initLoad) {
								this.getMonthStatistics()
							}

							this.isLoading = false;
						})
						.catch(err => {
							console.log(err);
							this.isLoading = false;
						});

					this.projectName = projectValueToLabel(this.$route.params.retainerId);
				}
			});
		},
		selectAll(e) {
			if (e.target.checked) {
				this.selectedItems = this.newData.map(item => {
					return item.taskList.map(task => task.id)
				}).flat();
			} else {
				this.selectedItems = [];
			}
		},
		toggleEditRetainerDataMode() {
			if (!this.editRetainerDataMode) {
				if (this.displayRetainerData && Object.keys(this.displayRetainerData).length > 0) {
					this.editRetainerData = { ...this.displayRetainerData };
				} else {
					this.editRetainerData = {
						retainer: 0,
						rollover: 0,
						allowance: 0,
						billable: 0,
						remaining: 0
					}
				}
			}
			this.editRetainerDataMode = !this.editRetainerDataMode;
		},
		async submitRetainerData() {

			const { retainer, rollover } = this.editRetainerData;

			const auth = await getAuth();
			auth.onAuthStateChanged(user => {
				if (user) {

					const currentToken = auth.currentUser.accessToken;
					var filterDate = new Date(this.filterDate);
					var monthVar = filterDate.getMonth() + 1;
					var yearVar = filterDate.getFullYear();

					let data = {
						RetainerHours: retainer,
						RolloverHours: rollover,
						Year: yearVar,
						Month: monthVar,
						ProjectID: this.$route.params.retainerId
					}

					axios.post(`${this.$root.apiBaseUrl}/retainer`, data, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {
						this.displayRetainerData = {
							retainer: res.data.retainerHours,
							rollover: res.data.rolloverHours,
							allowance: res.data.allowanceHours,
							billable: res.data.billableHours,
							remaining: res.data.remainingHours,
						};
						this.toggleEditRetainerDataMode();

						//TODO: save info??
					}).catch(err => {
						console.log(err)
					});

				}
			});

		},
		handleRetainerHistoryAccordion() {
			this.$refs.reportHistoryContent.classList.toggle('hidden');
			this.$refs.reportHistoryBtn.classList.toggle('rotate-180');
		},
		handleFullReset() {
			this.selectedItems = [];
			this.search = '';
			this.statusFilter = ['published', 'unpublished'];
			this.filterableMonths = [];
			this.sort = {
				sortBy: '',
				direction: 'asc'
			};		
			this.getTasks(false, true);	
		}
	}
};
</script>

<style lang="scss">
.dp__input {
	min-height: 52px !important;
}

.c-grid-table {
	border: 4px solid #1D2B2E;
	display: block;

	&__summary {
		background-color: #1D2B2E;
		color: white;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}

.row-col-widths {
	>div {
		flex-shrink: 0;

		&:nth-of-type(1) {
			width: 64px;
			flex-shrink: 0;
		}

		&:nth-of-type(2) {
			width: 48px;
		}

		&:nth-of-type(3) {
			width: 128px;
		}

		&:nth-of-type(4) {
			width: 160px;
		}

		&:nth-of-type(5) {
			min-width: 20%;
			flex: 1;
		}

		&:nth-of-type(6) {
			min-width: 18%;
			flex: 1;
		}

		&:nth-of-type(7) {
			min-width: 14%;
			flex: 1;
		}

		&:nth-of-type(8) {
			width: 150px;
		}

		&:nth-of-type(9) {
			width: 150px;
		}

		&:nth-of-type(10) {
			width: 128px;
		}

		&:nth-of-type(11) {
			width: 96px;
		}
	}

	&--client-view {
		width: 100%;

		>div {
			flex-shrink: 0;

			&:nth-of-type(1) {
				width: 64px;
				flex-shrink: 0;
			}

			&:nth-of-type(2) {
				width: 168px;
			}

			&:nth-of-type(3) {
				min-width: 22%;
				flex: 1;
			}

			&:nth-of-type(4) {
				min-width: 20%;
				flex: 1;
			}

			&:nth-of-type(5) {
				min-width: 15%;
				flex: 1;
			}

			&:nth-of-type(6) {
				min-width: 110px;
				width: 110px;
			}

			&:nth-of-type(7) {
				min-width: 110px;
				width: 110px;
			}

			&:nth-of-type(8) {
				width: 108px;
			}
		}
	}
}
</style>

<style>

  .swiper-action {
    background-color: rgba(249, 249, 249, 1);
    border: 1px solid rgba(223, 223, 223, 1);
    border-radius: 1000px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>