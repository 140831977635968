import { getDatabase, ref, update, push, child } from "firebase/database";
import LogAuditLog from "@/helpers/logAuditLog";
import { getAuth, tenantDatabase } from "../tenant";

export default async function updateClientDetails(clientId, data) {

    const db = tenantDatabase();
    const updates = {};
    let newKey = null;
    if(clientId) {
        updates[`clients/${clientId}`] = data;
    } else {
        newKey = push(child(ref(db), 'clients')).key;
        updates[`clients/${newKey}`] = data;
    }
    LogAuditLog({
        type: "Client",
        object: `Client`,
        action: clientId ? "edit" : "create",
        data: {
            ...data,
            id: clientId || newKey
        }
    })
    return await update(ref(db), updates);
    
}