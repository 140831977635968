<script setup>
   import Loading from './Loading.vue';
</script>

<template>
    <Loading :isLoading="submitting" :type="'overlay'" :loadText="'Processing...'">
    <div v-if="modalActive" class="fixed w-screen h-screen top-0 left-0 z-[100] flex justify-start items-center ">
        
            <div class="bg-[#111A1C] w-full absolute h-full opacity-40 z-1" @click="closeModal()"></div>
            <div class="bg-white px-4 py-8 z-100 max-w-[578px] mx-auto m-10 w-full relative text-center rounded-lg">     
                <p class="pb-4 leading-[28px]">
                    {{headerText}}
                </p>
                <div class="mb-8 mt-3 inline-block text-left" v-html="descriptionText"></div>
                <div>
                    <button class="w-[176px] mr-3 bg-grey-50 px-6 py-3 rounded-md" @click="closeModal()">Cancel</button>
                    <button class="bg-orange-500 px-6 py-3 rounded-md text-white" @click="handleConfirm()">{{confirmButtonText}}</button>
                </div>
            </div>
    </div>
    </Loading>
</template>

<script>
    export default {
        props: ['isActive', 'headerText', 'descriptionText', 'confirmButtonText'],
        emits: ['handleModalClose', 'handleModalConfirm'],
        watch: {
            isActive: function(newVal, oldVal) {
                this.modalActive = newVal;
            },
        },
        created() {
        },
        data() {
            return {
                modalActive: this.isActive,
                submitting: false
            }
        },
        mounted() {
        },
        methods: {
            handleConfirm() {
                this.$emit('handleModalConfirm');
                document.body.classList.remove('overflow-hidden');
            },
            closeModal(success) {
                this.modalActive = false;
                this.$emit('handleModalClose', success);
                document.body.classList.remove('overflow-hidden');
            }
        }
    }
</script>

<style lang="scss">

</style>