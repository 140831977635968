<script setup>
    import axios from "axios";
    import moment from "moment";
	import { getAuth, tenantDatabase } from "@/tenant";

    import Expander from '@/components/objects/Expander.vue';
    import DashboardEditItem from '@/components/DashboardEditItem.vue';
    import { Skeletor } from "vue-skeletor";
    import store from "@/store";

    import { getDatabase, remove, get, push, child, ref, onValue, update } from "firebase/database";

    import LogAuditLog from "@/helpers/logAuditLog";

	import Warning from '~icons/my-icons/warning';
</script>

<template>
    <div class="fixed top-0 left-0 w-screen min-h-screen h-full overflow-x-hidden overflow-y-auto bg-grey-900/40 animate-fade-in z-[100]">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <form @submit.prevent="saveChanges" class="w-[800px] bg-grey-50 ml-auto min-h-screen flex flex-col z-[2] relative animate-slide-in">
            <div class="px-6 py-5 border-b border-grey-200 bg-white sticky top-0 z-50">
                <h3 
                class="text-lg font-bold"
                >{{ isEdit ? "Edit" : "Log" }} overspend invoice</h3>
            </div>
            <div class="p-6">
                <Expander
                    :title="'Invoice details'"
                    :opened="true"
                >
                    <div v-if="unPublishedItems" class="p-3 text-xs font-bold text-orange-500 bg-warning-light rounded-lg flex items-center space-x-2">
                        <Warning class="h-6 w-6" />
                        <span>{{ unPublishedItems }} Unpublished time-entries</span>
                        <span class="font-normal text-grey-900">Publish or delete unpublished time-entries before logging invoice.</span>
                    </div>
                    <div :class="unPublishedItems && 'mt-10'">
                        <div class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Retainer report month
                            </div>
                            <div class="w-full">
                                <span>{{ convertLongDateString($route.params.date) }}</span>
                            </div>
                        </div>
                        <div class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Billable
                            </div>
                            <div class="w-full">
                                <span v-if="tileData.Billable">{{ tileData.Billable }}hrs</span>
                                <Skeletor v-else class="" width="64" height="24" />
                            </div>
                        </div>
                        <div class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Allowance
                            </div>
                            <div class="w-full">
                                <span v-if="tileData.Allowance">{{ tileData.Allowance }}hrs</span>
                                <Skeletor v-else class="" width="64" height="24" />
                            </div>
                        </div>
                        <div class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Remaining
                            </div>
                            <div class="w-full font-bold" :class="tileData.Remaining < 0 && 'text-error font-bold'">
                                <span v-if="tileData.Remaining">{{ tileData.Remaining }}hrs</span>
                                <Skeletor v-else class="" width="64" height="24" />
                            </div>
                        </div>
                        <div class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Hourly rate
                            </div>
                            <div class="w-full">
                                <span v-if="hourlyRate > -1">${{hourlyRate}}</span>
                                <Skeletor v-else class="" width="96" height="24" />
                            </div>
                        </div>
                        <hr class="border-t border-grey-100 my-4">
                    </div>
                    <div class="text-sm">
                        <DashboardEditItem
                            v-model:val="details.hoursBilled"
                            :init-value="details.hoursBilled"
                            theme="dark"
                            name="hoursBilled"
                            required="true"
                            label="Hours billed *"
                            input-type="number"
                        />
                        <DashboardEditItem
                            v-model:val="details.value"
                            :init-value="details.value"
                            :optional="true"
                            theme="dark"
                            name="value"
                            label="Value ($) <span class='text-tertiary'>- optional</span>"
                            input-type="money"
                        />
                        <DashboardEditItem
                            v-model:val="details.invoiceNumber"
                            :init-value="details.invoiceNumber"
                            theme="dark"
                            name="invoiceNumber"
                            label="Invoice # *"
                            required="true"
                            placeholder="e.g. INV1234"
                            input-type="text"
                        />
                        <DashboardEditItem
                            v-model:val="details.description"
                            :init-value="details.description"
                            :optional="true"
                            theme="dark"
                            name="description"
                            label="Description <span class='text-tertiary'>- optional</span>"
                            input-type="textarea"
                        />
                        <hr class="border-t border-grey-100 my-4" />
                        <div v-if="details.createdAt" class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Created
                            </div>
                            <div class="w-full">
                                <span v-if="details.createdAt">{{ moment(details.createdAt).format('DD/MM/yyyy hh:mm') }}</span>
                                <Skeletor v-else class="" width="96" height="24" />
                            </div>
                        </div>
                        <div v-if="details.editedAt" class="mb-3 items-center last:mb-0 flex">
                            <div class="min-w-[125px] grow-0 shrink basis-0 text-xs mr-3 font-bold inline-block">
                                Last edited
                            </div>
                            <div class="w-full">
                                <span v-if="details.editedAt">{{ moment(details.editedAt).format('DD/MM/yyyy hh:mm') }}</span>
                                <Skeletor v-else class="" width="96" height="24" />
                            </div>
                        </div>
                    </div>
                </Expander>
            </div>
            <div class="px-6 py-3 border-t border-grey-200 bg-white text-right sticky bottom-0 z-[2] mt-auto flex justify-between">
                <div>
                    <button v-if="isEdit" @click="deleteInvoice" type="button" class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-error rounded text-white py-3 px-6">
                        <span>Delete</span>
                    </button>
                </div>
                <div>
                    <button @click="closeEditInvoice" type="button" class="mr-6 text-blue-500 font-medium">Cancel</button>
                    <button :disabled="disabledLogInvoice" type="submit" class="transition disabled:text-grey-300 disabled:bg-grey-100 inline-flex items-center relative bg-orange-500 rounded text-white py-3 px-6">
                        <span v-if="!isEdit">Log invoice</span>
                        <span v-else>Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    props: ['retainerInfo'],
    data: function () {
        return {
            tileData: {},
            clients: {},
            unPublishedItems: 0,
            submitting: false,
            details: {
                effectiveDate: new Date(),
                hoursBilled: 0,
                value: 0,
                invoiceNumber: "",
                description: "",
                lastEditDate: ""
            },
        }
    },
    watch: {
        retainerInfo(to,from) {
            if (to.tileData) {
                this.tileData = to.tileData
                this.unPublishedItems = to.unPublishedItems
            }
        }
    },
    async mounted() {
        this.resetForm();

        if (this.retainerInfo?.tileData) {
            this.tileData = this.retainerInfo.tileData
        } else {
            this.fetchTileData(new Date(this.$route.params.date))
        }

        this.unPublishedItems = this.retainerInfo?.unPublishedItems || 0

        const db = tenantDatabase();

        const clientsRef = ref(db, `/clients`);
        const retainerRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
        const overspendInvoicesRef = ref(db, `/overspendInvoices/${this.$route.params.retainerId}/${this.$route.params.invoiceId}`);

        onValue(overspendInvoicesRef, snapshot => {
            if(snapshot.val()) {
                const data = snapshot.val();

                this.details = {
                    ...data
                }
            }
        });

        onValue(clientsRef, snapshot => {
            if(snapshot.val()) {
                this.clients = snapshot.val();
            } else {
                this.clients = null
            }
        });

        onValue(retainerRef, snapshot => {
            if(snapshot.val()) {
                this.activeRetainer = snapshot.val();
            } else {
                this.activeRetainer = null
            }
        });

        $('body').addClass('overflow-hidden');
    },
    computed: {
        isEdit() {
            return this.$route.params.invoiceId ? true : false;
        },
        activeClient() {
            const { clients } = this
            return clients && Object.values(clients).find(client => {
                return client.retainers ? Object.keys(client.retainers).some(clientRetainer => {
                    return clientRetainer == this.$route.params.retainerId
                }) : false
            }) || {}
        },
        defaultPricing() {
            return store.getters.getAppSettings.services;
        },
        disabledLogInvoice() {
            return this.details.hoursBilled <= 0 || this.details.invoiceNumber === "";
        },
        hourlyRate() {
            return (this.activeRetainer?.retainerRate?.toString().trim() || this.activeClient?.rate?.toString().trim()) || 0;
        }
    },
    methods: {
        async fetchTileData(date) {
            const auth = await getAuth();
            const currentToken = auth.currentUser.accessToken;
			axios({
				method: 'post',
				url: `${this.$root.apiBaseUrl}/retainer/stats/${this.$route.params.retainerId}`,
				data: [
                    {
                        month: date.getMonth() + 1,
                        year: date.getFullYear()
                    }
                ],
				headers: {
					'authorization': currentToken,
					'timezone': this.$root.apiTimezone
				}
			})
			.then(res => {
				const data = res.data[0].listData.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item.itemName]: typeof item.itemValue == "number" ? item.itemValue : item.itemValue ? item.itemValue.replace('hrs', '') : 0
                    }
                }, {})

				this.tileData = data;
			})
			.catch(err => {
				console.log(err);
			});
        },
		convertLongDateString(date) {
			return new Date(date).toLocaleString('en-US', { month: 'long', year: 'numeric' })
		},
        async saveChanges() {
            if(this.disabledLogInvoice) return;

            this.submitting = true;
            try {
                const db = tenantDatabase();

                let uuid

                const invoiceData = {
                    ...this.details,
                    effectiveDate: moment(this.$route.params.date).format('yyyy-MM-DD'),
                    editedAt: this.$route.params.invoiceId ? new Date().toISOString() : null
                }

                if (!this.$route.params.invoiceId) {
                    uuid = push(child(ref(db), 'overspendInvoices')).key;
                    LogAuditLog({
                        type: "Retainer",
                        object: "OverspendInvoice",
                        action: "create",
                        data: {...invoiceData, id: uuid, clientId: this.activeClient?.clientId || null, retainerId: this.$route.params.retainerId, lastEditDate: null}
                    })
                } else {
                    uuid = this.$route.params.invoiceId
                    LogAuditLog({
                        type: "Retainer",
                        object: "OverspendInvoice",
                        action: "edit",
                        data: {...invoiceData, id: uuid, clientId: this.activeClient?.clientId || null, retainerId: this.$route.params.retainerId, lastEditDate: null}
                    })
                }
                const firebaseDirectory = `/overspendInvoices/${this.$route.params.retainerId}/${uuid || "null"}`

                const updates = {
                    [firebaseDirectory]: invoiceData,
                };

                if (!this.$route.params.invoiceId) {
                    updates[firebaseDirectory].createdAt = new Date().toISOString()
                }
                $('body').removeClass('overflow-hidden');

                await update(ref(db), updates);

                this.$notify({
                    title: "",
                    text: `Overspend invoice ${this.isEdit ?  'updated':'created'} successfully`,
                    duration: 5000,
                });

                this.closeEditInvoice()
                this.submitting = false;
                this.resetForm();

                return
            } catch (error) {
                console.error('An error occurred while saving changes:', error);
            }
        },
        closeEditInvoice() {
            $('body').removeClass('overflow-hidden')
            if ((window.history.length) > 0) {
                this.$router.go(-1)
            } else {
                this.$router.push({ name: 'Beta Dashboard Reports Listing', params: { retainerId: this.$route.params.retainerId } });
            }
        },
        deleteInvoice() {
            const db = tenantDatabase();
        
            const invoiceRef = ref(db, `overspendInvoices/${this.$route.params.retainerId}/${this.$route.params.invoiceId}`);
            remove(invoiceRef)
            LogAuditLog({
                type: "Retainer",
                object: "OverspendInvoice",
                action: "delete",
                data: {...this.details, id: this.$route.params.invoiceId, clientId: this.activeClient?.clientId || null, retainerId: this.$route.params.retainerId}
            })
            this.closeEditInvoice()
        },
        resetForm() {
            this.details = {
                effectiveDate: new Date(),
                hoursBilled: 0,
                value: 0,
                invoiceNumber: "",
                description: "",
                lastEditDate: ""
            }
        }
    }
};

</script>


<style lang="scss">

    .tox.tox-tinymce {
        border-color: theme('colors.grey-200');
        border-width: 1px;
        border-radius: 4px;
    }

</style>