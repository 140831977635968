const allRoles = [
    {
        label: 'Global Administrator',
        value: 'admin'
    },
    {
        label: 'Webcoda User',
        value: 'webcoda-user'
    },
    {
        label: 'Client Admin',
        value: 'client-admin'
    },
    {
        label: 'Client User',
        value: 'client-user'
    }
]

export default allRoles;