<template>
    <span class="flex">
        <span :class="[this.color ? 'lds-ring--' + this.color : '', 'lds-ring']"><span></span><span></span><span></span><span></span></span>
    </span>
</template>

<script>
    export default {
        data() {
            return {}
        },
        props: ['color']
    }
</script>

<style lang="scss" scoped>
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 24px;
        height: 24px;
    }

    .lds-ring span {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 0;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }

    .lds-ring span:nth-child(1) {
        animation-delay: -0.45s;
    }

    .lds-ring span:nth-child(2) {
        animation-delay: -0.3s;
    }

    .lds-ring span:nth-child(3) {
        animation-delay: -0.15s;
    }

    .lds-ring--orange {
        span {
            border-color: theme('colors.orange-500') transparent transparent transparent;
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>