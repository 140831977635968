<script setup>
    import Doc from '~icons/my-icons/doc';
</script>

<template>
    <button
        type="button"
        ref="tooltip"
        :class="customClass"
        :data-content="content"
    >
        <slot></slot>
    </button>
    <div class="inline" ref="tooltipHtml">
        <slot name="htmlContent"></slot>
    </div>
</template>

<script>

    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';

    export default {
        props: ['content', 'htmlContent', 'class', 'position'],
        data: function() {
            return {
                customClass: this.class ? this.class : '',
                pos: this.position ? this.position : 'bottom'
            }
        }, 
        mounted() {
            tippy(this.$refs.tooltip, {
                content: this.$slots.htmlContent ? this.$refs.tooltipHtml : this.content,
                placement: this.pos
            });
        }
    };

</script>


<style lang="scss" scoped>
    .tippy-box {
        font-family: Montserrat,Arial,"Helvetica Neue",Helvetica,sans-serif !important;
        background-color: theme('colors.purple-900') !important;
        font-size: 0.875rem;
        font-weight: 500;
        line-height:  1.25rem;
        max-width: 468px;
        padding: 0;
        max-width: 160px;
    }
    .tippy-arrow {
        background-color: theme('colors.purple-900') !important;
    }
    .tippy-content {
        padding: 1rem;
        margin: 0;
    }
</style>