const convertTimeHelper = (dateTimeString, returnNumberOfDays) => {
    if(!dateTimeString) {
        return '—';
    }
    const dateNow = new Date();
    const date = new Date(dateTimeString);
    const hoursDiff = Math.floor(Math.abs(dateNow - date) / 36e5);
    if(isNaN(hoursDiff)) {
    }
    if(hoursDiff < 24) {
        return returnNumberOfDays ? 0 : 'Today'
    }
    if(hoursDiff >= 24 && hoursDiff < 48) {
        return returnNumberOfDays ? 1 : 'Yesterday'
    }
    else {
        return returnNumberOfDays ? Math.floor(hoursDiff / 24) : `${Math.floor(hoursDiff / 24)} days ago`
    }
}


export default convertTimeHelper;