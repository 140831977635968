<script setup>

import Search from '~icons/my-icons/search';
import IconChevronDown from '~icons/my-icons/chevron-down';

</script>

<template>
    <div class="relative min-h-[48px] flex items-center cursor-pointer z-50">
        <div @click="onClick" class="flex items-center">
            <img v-if="src" :src="src" alt=""
                class="inline-block max-w-[160px] max-h-[32px] mix-blend-multiply mr-5" 
            />
            <p class="font-bold cursor-pointer max-w-[384px] truncate">{{ activeLabel || "Please select a retainer" }}</p>
            <IconChevronDown :class="active && 'rotate-180'" class="h-[20px] w-[20px] ml-3"/>
        </div>
        <div :class="!active && 'hidden'" class="min-w-[480px] max-w-[480px] absolute left-0 top-[50px]">
            <div class="bg-white flex row border border-gray-900 w-full">
                <div class="flex items-center border border-b-[#DFDFDF]">
                    <search class="ml-4 mr-2 my-3"></search>
                    <input type="text" placeholder="Search" class="focus:border-none outline-none w-full " ref="searchbox"
                        @input="query = $event.target.value" />
                </div>
                <ul @click="onClick" class="pl-2 max-w-[480px] max-h-[204px] overflow-x-hidden overflow-y-auto">
                    <li v-for="(item, index) in filterUsers" @click="handleSelectedRetainer(item.value)"
                        :key="index" :class="item.value == actRetainer ? 'bg-[#FF4B40] text-white hover:bg-[#FF4B40] hover:text-grey-800' : ''" class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate">
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: ['items', 'activeLabel', 'onClick', 'active', 'actRetainer', 'handleActiveRetainer', 'src'],
    data() {
        return {
            query: this.propsQuery ? this.propsQuery : '',
            length: 0,
        }
    },
    watch: {
        active(newActive, oldActive) {
            if (newActive) {
                setTimeout(() => {
                    this.$refs.searchbox.focus()
                }, 100)
            }
        }
    },
    methods: {
        handleSelectedRetainer(value) {
            this.$emit('handleActiveRetainer', value)
            this.query = '';
            this.$refs.searchbox.value = '';
        }
    },
    computed: {
        filterUsers() {
            const { items, query } = this
            return items?.filter(item => {
                return !query ||
                    item?.label?.toLowerCase()?.includes(query?.toLowerCase())
                    
            })
        },
    },
}

</script>


<style lang="scss" scoped>

</style>