<script setup>

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { sendSignInLinkToEmail } from "firebase/auth";
import { getAuth, tenantDatabase, getCurrentTeanantID } from "../../tenant";
import { getApp } from "firebase/app";
import { getDatabase, ref, onValue, update } from "firebase/database";
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import store from '../../store';
import initAllProjects from '@/helpers/InitAllProjects';

import Container from '../containers/Container.vue';
import Header from '../objects/Header.vue';
import Button from '../objects/Button.vue';
import Loading from '../containers/Loading.vue';
import allRoles from "../../data/alluserRoles";
import allAccountSettings from "../../data/accountSettingsData";
import getFirebaseConfig from "../../helpers/GetFirebaseConfig";
import vSelect from 'vue-select'

import ArrowLeft from '~icons/my-icons/arrow-left';
import IconTick from '~icons/my-icons/tick';
import Close from '~icons/my-icons/cross';

import LogAuditLog from "@/helpers/logAuditLog";
</script>

<template>
    <div class="fixed top-0 left-0 w-full min-h-screen h-full overflow-auto bg-grey-900/40 z-[100]">
        <!-- <div class="absolute top-0 left-0 w-full h-full"></div> -->
        <div class="max-w-[800px] w-full bg-grey-50 ml-auto h-screen flex flex-col z-[2] relative">
            <div class="px-6 py-5 border-b border-grey-200 bg-white flex justify-between ">
                <h3 class="font-bold text-lg col">{{ $route.name.replace("Admin -", '').replace("Client -", '') }}</h3>
                <Close @click="$router.go(-1)" class="cursor-pointer" />
            </div>
            <div class="w-full flex flex-col overflow-auto">
                <div v-if="notifications.message"
                    class="max-w-[840px] w-full mx-auto bg-orange-100 rounded-lg px-4 py-4 mt-8 flex items-center">
                    <div
                        class="flex-shrink-0 mt-1 text-white mr-3 rounded-full bg-orange-400 w-7 h-7 inline-flex items-center justify-center text-sm">
                        !
                    </div>
                    <div class="pl-3 text-xs">
                        <p>{{ notifications.message }}</p>
                    </div>
                    <button class="ml-auto mr-0 font-medium text-[9px] text-orange-400"
                        @click="notifications.message = ''">
                        <Close />
                    </button>
                </div>
                <form class="p-6 flex flex-col justify-between relative" ref="addContact">
                    <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                        <div class="max-w-[840px] w-full mx-auto bg-white rounded-lg border-solid border px-12 py-10">
                            <div class="flex items-center mb-3 last:mb-0">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="firstName">
                                    First name *
                                </label>
                                <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                                    :value="user.firstName" @input="user.firstName = $event.target.value"
                                    name="firstName" required>
                            </div>
                            <div class="flex items-center mb-3 last:mb-0">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="lastName">
                                    Last name *
                                </label>
                                <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                                    :value="user.lastName" @input="user.lastName = $event.target.value" name="lastName"
                                    required>
                            </div>
                            <div class="flex items-center mb-3 last:mb-0">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="email">
                                    Email *
                                </label>
                                <input class="flex-1 border border-bd border-solid h-12 rounded px-4" type="email"
                                    :value="user.email" @input="user.email = $event.target.value" name="email" required>
                            </div>
                            <div class="flex items-center mb-3 last:mb-0">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="phone">
                                    Contact number
                                </label>
                                <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                                    :value="user.phone" @input="user.phone = $event.target.value" name="phone">
                            </div>
                            <div class="flex items-center mb-3 last:mb-0">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="roleTitle">
                                    Job title
                                </label>
                                <input class="flex-1 border border-bd border-solid h-12 rounded px-4"
                                    :value="user.roleTitle" @input="user.roleTitle = $event.target.value"
                                    name="roleTitle">
                            </div>
                            <div class="flex items-center mb-3 last:mb-0" v-if="currentUserRole == 'admin' && $route.name.includes('Admin -')">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="team">
                                    Client
                                </label>
                                <div class="flex flex-col w-full">
                                    <v-select class="w-full"
                                        v-model="user.client" 
                                        :options="sortedFirebaseClients 
                                        ? sortedFirebaseClients.map(client => client ? {label: client.name, id: client.clientId} : {}) 
                                        : []" 
                                        :reduce="(option) => option.id"
                                        label="label"
                                        :clearable="false"
                                        required
                                    >
                                    </v-select>
                                </div>
                            </div>
                            <!--
                            <div class="flex items-center mb-3 last:mb-0" v-if="currentUserRole == 'admin'">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="retainer">
                                    Retainer
                                </label>
                                <Multiselect v-model="user.retainer" mode="multiple" :hideSelected="false"
                                    :close-on-select="false" :object="true" :options="allRetainers"
                                    :disabled="currentUserRole === 'client-admin'">
                                    <template v-slot:multiplelabel="{ values }">
                                        <div class="multiselect-multiple-label">
                                            <span class="flex-shrink-0" v-for="(val, index) in values" :key="val.value">
                                                {{ val.label }}<span class="inline-block"
                                                    v-if="values.length !== 1 && index != Object.keys(values).length - 1">,&nbsp;</span>
                                            </span>
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>
                            
                            <div class="flex items-center mb-3 last:mb-0" v-if="currentUserRole == 'admin'">
                                <label class="min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="permission">
                                    Permissions *
                                </label>
                                <select :disabled="currentUserRole === 'client-admin'"
                                    class="flex-1 border border-bd border-solid h-12 rounded px-4 appearance-none"
                                    :value="user.permission" @change="user.permission = $event.target.value"
                                    id="permission" required>
                                    <option value="">Please select</option>
                                    <option :value="role.value" v-for="role in allRoles" :key="role.value">
                                        {{ role.label }}</option>
                                </select>
                            </div>
                            -->
                            <div class="flex mb-3 last:mb-0">
                                <div class="pt-3 min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="permission">
                                    Contact type
                                </div>
                                <div class="my-2 flex flex-col">
                                    <label v-for="setting in allAccountSettings" :key="setting.value"
                                        class="relative inline-flex items-center cursor-pointer mb-2 last:mb-0">
                                        <span class="relative flex">
                                            <input class="peer sr-only" :value="setting.value" type="checkbox"
                                                @change="handleCheckboxUpdate(setting.value)">
                                            <span
                                                class="inline-block w-5 h-5 border border-grey-300 rounded peer-checked:bg-grey-800 p-1"></span>
                                            <span
                                                class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                                <IconTick class="text-white" />
                                            </span>
                                        </span>
                                        <span class="ml-3">{{ setting.label }}</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                            <!-- <div class="flex mb-3 last:mb-0">
                                <div class=" min-w-[125px] font-bold text-xs text-grey-300 mr-3" for="account-settings">
                                Account settings
                                </div>
                                <div class="flex flex-col">
                                    <label
                                        class="relative inline-flex items-center  mb-2 last:mb-0"
                                        :class="[isBothAdmin ? 'cursor-not-allowed' : 'cursor-pointer']">
                                        <span class="relative flex">
                                            <input class="peer sr-only" :disabled="isBothAdmin" v-model="user.userCanLogin"
                                            type="checkbox">
                                        <span
                                            :class="[isBothAdmin ? 'peer-checked:bg-grey-300' : 'peer-checked:bg-grey-800']"
                                            class="inline-block w-5 h-5 border border-grey-300 rounded p-1"></span>
                                        <span
                                            class="text-[11px] pointer-events-none invisible peer-checked:visible absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                            <IconTick class="text-white" />
                                        </span>
                                        </span>
                                        <span class="ml-3" :class="[isBothAdmin ? 'text-grey-300' : '']">
                                    User can login to portal</span>
                                    </label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </Loading>
                </form>
            </div>
            <div
                class="text-right py-3 px-6 flex items-center justify-end mt-auto bg-white border-t border-grey-200 w-full">
                <v-btn @click="$router.go(-1)" class="text-blue-500 mr-6 cursor-pointer">
                    Cancel
                </v-btn>
                <button :disabled="isSubmitting" class="px-6 py-3 text-white bg-orange-500 rounded font-medium"
                    @click="submitClickHandler" type="button">
                    Add contact
                </button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            currentUserRole: store.getters.getUserState.permission,
            allTeams: [],
            allRetainers: [],
            user: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                phone: "",
                roleTitle: "",
                client: this.$route.params.clientId,
                retainer: [],
                permission: 'client-admin',
                accountSettings: [],
                userCanLogin: false,
            },
            error: {
                password: false,
                confirmPassword: false
            },
            notifications: {
                message: ""
            },
            confirmPassword: "",
            showPasswordField: false,
            isSubmitting: false,
            firebaseClients: [],
        }
    },
    computed: {
        sortedFirebaseClients() {
            return this.firebaseClients.slice().sort((a, b) => a?.name && a?.name?.localeCompare(b.name));
        },
        validatePassword() {
            // Password validation
            if (this.user.password.length > 0) {
                const regexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;
                if (!regexString.test(this.user.password)) {
                    this.error.password = true;
                    return false;
                }
            } else {
                this.error.password = true;
                return false;
            }
            this.error.password = false;
            return true;
        },
        validateConfirmPassword() {
            if (this.user.password === this.confirmPassword) {
                this.error.confirmPassword = false;
                return true;
            }
            this.error.confirmPassword = true;
            return false;
        }
    },
    created() {
        const user = store.getters.getUserState;
        if (user.permission == 'client-user') {
            if (!user.accountSettings || !user.accountSettings.includes('can-add-users')) {
                this.$router.push({ name: "Users" })
            }
        }
    },
    async mounted() {
        const auth = await getAuth();
        this.getUserData()
        axios({
            method: 'get',
            url: `${this.$root.apiBaseUrl}/client/all`,
            headers: {
                'authorization': auth.currentUser.accessToken,
                'timezone': this.$root.apiTimezone
            },
        }).then(res => {
            this.allTeams = res.data;
            this.getRetainerProjects();
        }).catch(err => {
            console.log(err);
        });
    },
    methods: {
        async getUserData() {
            const auth = await getAuth();
            const once = auth.onAuthStateChanged(user => {
                if (user) {
                    this.authUserUID = user.uid;
                }
            });

            once();

            await initAllProjects();

            const db = tenantDatabase();

            const clientsRef = ref(db, `/clients`);

            onValue(clientsRef, (snapshot) => {
                if (snapshot.exists()) {
                    const clientsObject = snapshot.val()
                    this.firebaseClients = Object.keys(clientsObject)
                        .filter(key => !clientsObject[key].archived)
                        .map(key => {
                            return {
                                ...clientsObject[key],
                                clientId: key
                            }
                        });

                    this.isLoading = false;
                } else {
                    console.log("No data available");
                    return {}
                }
            });
        },
        handleCheckboxUpdate(value) {
            let settings = this.user.accountSettings;
            if (settings.filter(a => a === value).length > 0) {
                this.user.accountSettings = settings.filter(a => a !== value);
            } else {
                let arr = settings;
                arr.push(value);
                this.user.accountSettings = arr;
            }
        },
        handleUpdate(event) {
            this.user.retainer = event
        },
        handleConfirmPassword(e) {
            this.confirmPassword = e.target.value;
            if (this.confirmPassword === this.user.password) {
                this.error.confirmPassword = false;
            } else {
                this.error.confirmPassword = true;
            }
        },
        formatRetainers(retainerList) {
            const retainersArr = retainerList.map(item => {
                return {
                    value: item.id,
                    label: item.projectName
                }
            });
            return retainersArr;
        },
        async getRetainerProjects($event) {

            // this.$refs.multiselect.clear()
            this.allRetainers = [];
            this.user.retainer = [];

            this.user.team = $event ? $event?.target.value : '';

            if (this.user.team == 'internal-user') {
                this.allRetainers = store.getters.getAllProjects.map(item => {
                    return {
                        label: item.projectName,
                        value: item.id
                    }
                });
            } else {
                if (this.user.team) {

                    const auth = await getAuth();
                    axios({
                        method: 'get',
                        url: `${this.$root.apiBaseUrl}/project/list/${this.user.team}`,
                        headers: {
                            'authorization': auth.currentUser.accessToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        const retainers = res.data;
                        if (retainers.length > 0) {
                            const allRetainersArr = this.formatRetainers(retainers);
                            this.allRetainers = allRetainersArr;
                            if (this.currentUserRole === 'client-admin') {
                                const arr = allRetainersArr.filter(item => {
                                    return store.getters.getUserState.retainer.includes(item.value);
                                });
                                this.user.retainer = arr;
                            } else {
                                this.user.retainer = [];
                            }
                        } else {
                            this.allRetainers = []
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                } else {
                    this.allRetainers = [];
                    this.user.team = "";
                    this.user.retainer = [];
                }
            }
        },
        async submitClickHandler() {

            const form = this.$refs.addContact;
            // let val = false;
            // if(!this.validatePassword || !this.validateConfirmPassword) {
            //     return;
            // }
            if (form.reportValidity()) {
                this.isSubmitting = true;
                const dataObj = {
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: this.user.email,
                    phone: this.user.phone,
                    roleTitle: this.user.roleTitle,
                    tenantId: await getCurrentTeanantID(),
                    // retainer: store.getters.getUserState.permission === 'client-admin' ? store.getters.getUserState.retainer : this.user.retainer.length > 0 ? this.user.retainer.map(item => item.value) : [],
                    permission: store.getters.getUserState.permission === 'client-admin' ? 'client-user' : this.user.permission,
                    accountSettings: this.user.accountSettings,
                    userCanLogin: true// this.user.userCanLogin,
                };
                dataObj.client = store.getters.getUserState.permission === 'client-admin' ? this.user.client : this.user.client?.length > 0 ? this.user.client : '';

                const app = getApp();
                const functions = getFunctions(app);

                const createUser = httpsCallable(functions, "createNewUser");
                createUser({ data: dataObj }).then(result => {
                    if (result.data?.message?.errorInfo) {
                        this.notifications.message = result.data.message.errorInfo.message;
                        this.isSubmitting = false;
                    } else if (result.data.errorInfo) {
                        this.notifications.message = result.data.errorInfo.message;
                        this.isSubmitting = false;
                    } else {
                        LogAuditLog({
                            type: "User",
                            object: "Contact",
                            action: "add",
                            data: {
                                id: result.data.uid,
                                ...dataObj,
                                clientId: dataObj.client
                            }

                        })
                        // Get a reference to the client in the database

                        this.isSubmitting = false;
                        this.$notify({
                            title: "Contact added",
                            text: "Registration email sent to " + dataObj.email,
                            duration: 5000,
                        });
                        if (store.getters.getUserState.permission == 'admin') {
                            this.isSubmitting = false;
                            this.$router.push({
                                name: 'Users',
                                params: {
                                    notification: 'createUser'
                                }
                            });
                        } else {
                            this.$router.push({
                                name: 'Beta Dashboard Contacts',
                                params: {
                                    notification: 'createUser'
                                }
                            });
                        }
                        
                        const clientRef = ref(tenantDatabase(), `clients/${this.user.client}`);

                        // Update the users field in the client's data
                        update(clientRef, {
                            [`users/${result.data.uid}`]: true
                        });
                        this.isSubmitting = false;
                        this.sendConfirmationMail(result.data);
                        this.$router.go(-1);
                    }
                }).catch(err => {
                    this.isSubmitting = false;
                    console.log(err)
                });

                return;
            }

        },
        async  sendConfirmationMail(user) {

            const auth = await getAuth();
            auth.onAuthStateChanged(currentUser => {
                if (currentUser) {

                    const currentToken = auth.currentUser.accessToken;
                    const currentUrlBase = window.location.origin;

                    let data = {
                        Uid: user.uid,
                        SiteUrl: currentUrlBase
                    }

                    axios.post(`${this.$root.apiBaseUrl}/account/confirmation`, data, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        // alert('sent');
                    }).catch(err => {
                        console.log(err)
                    });

                }
            });
        },
        handleCanLoginUpdate() {
            this.user.userCanLogin = !this.user.userCanLogin;
            console.log('User can login:', this.user.userCanLogin)
        }
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
    @import "vue-select/dist/vue-select.css";
</style>

<style scoped>
:deep() {
  --vs-actions-padding: 16px;
}
</style>

<style lang="scss">
.multiselect {
    height: 48px;

    .multiselect-multiple-label {
        overflow: hidden;
        display: inline;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 48px;
        width: 100%;
        vertical-align: middle;
    }
}
</style>



