<script setup>

	import { getDatabase, ref, onValue } from "firebase/database";
	import { getAuth, tenantDatabase } from "@/tenant";
	import store from '@/store';
	import initCurrentUserSession from '@/helpers/InitCurrentUserSession';
	import initAllProjects from '@/helpers/InitAllProjects';
	import Loading from '@/components/containers/Loading.vue';
	import RetainerServiceModal from '@/components/RetainerServiceModal.vue';
	import IconConfigure from '~icons/my-icons/configure';
	import IconTick from '~icons/my-icons/tick';
	
	import servicesData from '@/data/servicesData.js';

</script>

<template>
	<Loading :isLoading="snapshotLoading">
		<p class="text-3xl font-bold py-12">Services</p>
		<div class="border border-grey-200 rounded-lg">
			<table class="w-full">
				<thead class="border-none rounded-lg">
					<tr class="bg-grey-50 border-b border-gray-200 rounded-lg">
						<th class="rounded-tl-lg min-w-[280px]">
							<span class="block pl-8 py-3 w-full text-left text-xs font-semibold">
								Service
							</span>
						</th>
						<th class="">
							<span class="block px-3 py-3 w-full text-left text-xs font-semibold">
								Details
							</span>
						</th>
						<th class=" w-[84px]">
							<span class="block px-3 py-3 w-full text-center text-xs font-semibold">
								Hours
							</span>
						</th>
						<th class=" w-[148px]">
							<span class="block px-3 py-3 w-full text-center text-xs font-semibold">
								Cost
							</span>
						</th>
						<th class=" w-[128px]">
							<span class="block px-3 py-3 w-full text-center text-xs font-semibold">
								Occurance
							</span>
						</th>
						<th class="rounded-tr-lg w-[196px]"></th>
					</tr>
				</thead>
				<tbody class="">
					<tr v-for="service in formatServicesData" :key="service.serviceId" class="border-b border-solid last:border-none group">
						<td class="bg-white pl-8 pr-3 py-3 text-base group-last:rounded-bl-lg">
							<span class="flex items-center font-bold">
								<img v-if="service.icon" :src="service.icon" class="block mr-5" alt=""/>
								{{service.name}}
							</span>
						</td>
						<td class="bg-white px-3 py-8 text-xs">
							{{service.details}}
						</td>
						<td class="bg-white px-3 py-8 text-xs text-center">
							{{service.hours > 0 ? service.hours : '-' }}
						</td>
						<td class="bg-white px-3 py-8 text-xs text-center">
							$A{{service.activeService ? service.cost * service.hours : service.cost }}.00
						</td>
						<td class="bg-white px-3 py-8 text-xs text-center">
							{{service.occurance === 'one-off' ? `${dateLabel(service.date)}` : service.occurance === 'period' ? 'Monthly' : '-'}}
						</td>
						<td class="bg-white px-3 py-8 pr-6 text-sm text-right group-last:rounded-br-lg">
							<button v-if="!service.activeService" @click="() => activeServiceModalId = service.serviceId" class="text-sm bg-orange-500 inline-flex items-center py-2 px-5 text-white rounded">
								<span class="mr-1 font-bold">+</span>
								Add
							</button>
							<div v-else class="flex">
								<span class="text-sm rounded border-2 border-solid border-gray-900 flex items-center py-2 px-5 mr-2">
									<span class="mr-1 font-bold ">
										<IconTick class="w-[12px] mr-1"/>
									</span>
									Added
								</span>
								<button @click="() => activeServiceModalId = service.serviceId" class="text-sm flex items-center bg-orange-500 inline-block py-2 px-5 text-white rounded">
									<span class="mr-1 font-bold ">
										<IconConfigure class="w-[12px] mr-1"/>
									</span>
									Configure
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<RetainerServiceModal 
				:isActive="activeServiceModalId !== null" 
				:data="activeServiceModal ? activeServiceModal : null"
				@handleModalClose="handleServiceModalClose"
			/>
		</div>
	</Loading>
</template>

<script>
	export default {
		data() {
			return {
				retainerData: null,
				snapshotLoading: true,
				currentUserRole: store.getters.getUserState.permission,
				activeServiceModalId: null,
			}
		},
		watch: {
			'$route' (to, from) {
				if(to?.params?.retainerId) {
				 // Update retainer requests here
				 this.getRetainerData()
				} 
			}
		},
		computed: {
			internalWebcodaUserRole() {
				return this.currentUserRole === 'admin' || this.currentUserRole === 'webcoda-user'
			},
			activeServiceModal() {
				const serviceId = this.activeServiceModalId;
				const { formatServicesData } = this;
				return formatServicesData.filter(item => item.serviceId === serviceId).length > 0 ? formatServicesData.filter(item => item.serviceId === serviceId)[0] : null;
			},
			formatServicesData() {
				const serviceData = servicesData;
				const { retainerData } = this;
				const retainerServicesData = retainerData.services;
				const defaultServicesRates = store.getters.getAppSettings.services;

				const formattedData = serviceData?.map(item => {
					let isServiceActive = false;
					item.cost = defaultServicesRates[item.serviceId];
					item.hours = 0;
					for(const service in retainerServicesData) {
						if(item.serviceId === service) {
							isServiceActive = retainerServicesData[service].hours && Number(retainerServicesData[service].hours) > 0 ? true : false;
							item.cost = retainerServicesData[service] ? retainerServicesData[service].cost : defaultServicesRates[service];
							item.hours = retainerServicesData[service] && retainerServicesData[service].hours ? retainerServicesData[service].hours : 0;
							item.occurance = retainerServicesData[service].occurance ? retainerServicesData[service].occurance : '';
							retainerServicesData[service].date ? item.date = retainerServicesData[service].date : '';
						} 
					}
					return {
						...item,
						activeService: isServiceActive
					}
				});

				this.snapshotLoading = false

				return formattedData;
			}
		},
		async mounted() {

			await initCurrentUserSession(this);

			if((this.internalWebcodaUserRole || store.getters.getUserState.retainer?.length > 1) || store.getters.getViewAsClient) {
				await initAllProjects();
				const retainers = store.getters.getAllProjects.map(item => {
					return {
						label: item.projectName,
						value: item.id
					}
				});
			}

			this.getRetainerData()

		},
		methods: {
			getRetainerData() {
				
				const userRetainer = this.$route.params.retainerId;
				
				const db = tenantDatabase();
				const retainersRef = ref(db, `/retainers/${userRetainer}`);
				const retainerUsersRef = ref(db, `/retainerUsers/${userRetainer}/`);

				onValue(retainersRef, snapshot => {
					this.isInitLoading = true;
					this.retainerData = snapshot.val() ? snapshot.val() : {};
					if (this.retainerData.plans != null && this.retainerData.plans.length > 0) {
						var lastPlan = this.retainerData.plans[this.retainerData.plans.length - 1];
						this.retainerData.monthlyHours = lastPlan.monthlyHours;
                        this.retainerData.rolloverHours = lastPlan.rolloverHours;
                        this.retainerData.rolloverFrom = lastPlan.rolloverFrom;
                    }

					this.editRetainerData = snapshot.val() ? snapshot.val() : {};
					store.commit('updateRetainerDetails', snapshot.val() ? snapshot.val() : {});
					this.isInitLoading = false;
				});
				
				onValue(retainerUsersRef, snapshot => {
					if(snapshot.val()) {
						let formattedData = Object.keys(snapshot.val()).map((key) => {
							return {
								uid: key,
								...snapshot.val()[key]
							}
						});
						let clientUserArr = formattedData.filter(user => {
							return user.permission !== 'admin' && user.permission !== 'webcoda-user'
						});
						clientUserArr.forEach((item, index) => {
							if(item.permission === 'client-admin') {
								clientUserArr.splice(index, 1);
								clientUserArr.unshift(item);
							}
						});
						let webcodaUserArr = formattedData.filter(user => {
							return user.permission == 'admin' || user.permission == 'webcoda-user'
						});
						this.internalUsers = webcodaUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
						console.log(clientUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						}))
						this.retainerUsers = clientUserArr.map(user => {
							return {
								uid: user.uid,
								userSettings: {...user}
							}
						});
					} else {
						this.retainerUsers = [];
					}
					this.snapshotLoading = false
				});
			},
			handleServiceModalClose(success) {
				this.activeServiceModalId = null;
			},
			dateLabel(dateStr) {
				var options = { month: 'long', year: 'numeric' };		
				var thisDate = new Date(dateStr);
				return thisDate.toLocaleString('en-GB', options);
			},
		}
	}
</script>
