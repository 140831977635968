<script setup>

    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    import Container from '@/components/containers/Container.vue';
    import store from "@/store";
    import RetainerDetails from '@/components/pages/beta/admin/retainers/RetainerDetails.vue';

    import { getAuth, tenantDatabase } from "@/tenant";
    import axios from "axios";

    import { getDatabase, get, off, ref, onValue, update } from "firebase/database";

    import Tooltip from '@/components/objects/Tooltip.vue';

	import IconClose from '~icons/my-icons/cross';
    import FilterArrow from '~icons/my-icons/filter-arrow';
    import WhiteTick from '~icons/my-icons/whitetick';
    import Search from '~icons/my-icons/search';

    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Skeletor } from "vue-skeletor";
</script>

<template>
    <div class="pb-20 bg-white flex-1 ml-[196px] relative">
        <div>
            <Container>
                <div class="py-12 flex-1 relative">
                    <div class="grid grid-cols-2 gap-4">
                        <div :class="isLoading && 'bg-grey-50'" class="flex justify-between items-center border border-grey-200 p-6 rounded-lg">
                            <div>
                                <p v-if="!isLoading" class="text-2xl text-grey-900 font-medium">{{ needsBillingTasksNumber || 0 }}</p>
                                <Skeletor v-else class="mb-2" width="64" height="24" />
                                <p class="text-xs text-grey-900 font-bold">exceed allowance & need billing</p>
                            </div>
                            <img src="@/assets/img/timeclock.svg" alt="Time Clock" />
                        </div>
                        <div :class="isLoading && 'bg-grey-50'" class="flex justify-between items-center border border-grey-200 p-6 rounded-lg">
                            <div>
                                <p v-if="!isLoading" class="text-2xl text-grey-900 font-medium">{{ totalUnPublishedTasksNumber || 0 }}</p>
                                <Skeletor v-else class="mb-2" width="64" height="24" />
                                <p class="text-xs text-grey-900 font-bold">include unpublished tasks</p>
                            </div>
                            <img src="@/assets/img/unpublishedtasks.svg" alt="Time Clock" />
                        </div>
                    </div>
                    <div class="py-9">
                        <div class="w-full flex justify-between items-start">
                            <h1 class="text-3xl font-bold mr-5">Retainers</h1>
                            <div class="grid grid-cols-1 gap-2 justify-center items-start xl:flex">
                                <div class="flex gap-2">
                                    <div @click="toggleNeedsReview" :class="isLoading && 'bg-grey-50 cursor-default'" class="min-w-[160px] border border-gray-200 rounded flex cursor-pointer">
                                        <div class="flex items-center justify-center px-3 space-x-3">
                                            <div :class="needsReview ? 'bg-gray-900' : ''" class="w-5 h-5 border border-gray-200 rounded flex items-center justify-center">
                                                <WhiteTick class="w-3 h-2" />
                                            </div>
                                            <p class="text-sm font-medium">Needs Review</p>
                                        </div>
                                    </div>

                                    <div class="relative min-w-[400px]">
                                        <Search class="w-6 h-6 absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none" />
                                        <input type="text" :disabled="isLoading" :value="searchTerm" @input="searchTerm = $event.target.value" @keyup.enter="runSearch()" class="block rounded border border-bd mr-4 w-full pl-12 py-3 pr-12" placeholder="Search retainers" />
                                        <IconClose @click="clearSearch" v-if="searchTerm.length > 0" class="w-3 h-3 absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer" />
                                    </div>
                                </div>  
                                <div class="flex gap-2">
                                    <div class="relative mb-2 lg:mb-0 w-full sm:w-48">
                                        <Datepicker
                                            :disabled="isLoading"
                                            monthPicker
                                            :modelValue="filterMonth"
                                            @update:modelValue='handleMonthUpdate'
                                            :ref="inputName"
                                            :required="true"
                                            :clearable="false"
                                            :autoApply="true"
                                            :enableTimePicker="false"
                                            :format="'MMMM yyyy'"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border border-grey-200 rounded-lg">
                        <table ref="table" class="w-full border-collapse rounded-lg table-fixed">
                            <thead class="border-none rounded-lg text-purple-900 sticky top-0 z-50">
                                <tr class="bg-grey-50 border-b border-grey-200 rounded-lg">
                                    <th class="text-left text-xs font-normal align-middle py-4 px-3 pl-4 w-full rounded-lg">
                                        <button class="flex" @click="toggleSortOrder">
                                            <strong class="block mr-1">Client</strong> 
                                            <FilterArrow :class="ascending ? '' : 'rotate-180'" />
                                        </button>
                                    </th>
                                    <th class="text-center text-xs font-bold align-middle py-4 px-3 w-[112px]">
                                        <div class="pr-6 border-r border-grey-200">
                                            <Tooltip
                                                content="Contracted recurring monthly retainer hours"
                                                position="top"
                                            >
                                                <div class="font-bold">
                                                    Monthly <br />Retainer
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </th>
                                    <th class="text-center text-xs font-bold align-middle py-4 px-3 w-[120px]">
                                        <Tooltip
                                            content="Published non-billable hours"
                                            position="top"
                                        >
                                            <span class="font-bold">Non-billable</span>
                                        </Tooltip>
                                    </th>
                                    <th class="text-center text-xs font-bold align-middle py-4 px-3 w-[218px]">
                                        <Tooltip
                                            class="inline"
                                            content="Published Billable hours"
                                            position="top"
                                        >
                                            <span class="font-bold">Billable</span>
                                        </Tooltip>
                                        <span class="text-grey-300 mx-1">/</span>
                                        <Tooltip
                                            class="inline"
                                            content="Total hours client has to use for this month, including any rollover hours from prior month"
                                            position="top"
                                        >
                                            <span class="font-bold">Allowance</span>
                                        </Tooltip>
                                    </th>
                                    <th class="text-center text-xs font-bold align-middle py-4 px-3 w-[104px]">
                                        <Tooltip
                                            class="inline"
                                            content="Hours remaining for month, after deducting billable hours and overspend invoice from allowance"
                                            position="top"
                                        >
                                            <span class="font-bold">Remaining <p class="font-normal">({{ convertShortDateString(`${filterMonth.year}-${filterMonth.month + 1}`) }})</p></span>
                                        </Tooltip>
                                    </th>
                                    <th class="text-center text-xs font-bold align-middle py-4 px-3 w-[104px]">
                                        <Tooltip
                                            class="inline"
                                            content="Number of tasks with unpublished time-entries"
                                            position="top"
                                        >
                                            <span class="font-bold">Unpublished <br /> time-entries</span>
                                        </Tooltip>
                                    </th>
                                    <th class="w-[170px] rounded-lg"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="retainers && retainers?.length > 0 && clients && !isLoading">
                                    <RetainerDetails v-for="(retainer, retainerI) in computedRetainers" :key="retainerI" :date="filterMonth" :retainer="retainer" />
                                </template>
                                <template v-if="(isLoading && !clients?.length && !retainers?.length) || isPageLoading">
                                    <tr v-for="pI in 3" :key="pI">
                                        <td v-for="i in 7" :key="i" class="text-center p-4">
                                            <Skeletor height="32" />
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="retainers && !isLoading && retainers?.length == 0">
                                    <tr>
                                        <td colspan="7" class="text-center">
                                            <div class="px-12 pt-6 pb-4 text-center font-medium">
                                                No retainers found.
                                            </div>
                                            <div class="px-12 mb-6 text-center font-medium"  v-if="searchTerm">
                                                <button class="border rounded-md border-gray-200 bg-gray-50 p-1.5 px-4" @click="handleFullReset()"> Reset filters</button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="retainers && !isLoading && retainers?.length < totalApiCount">
                                    <tr>
                                        <td colspan="7" class="text-center pb-4">
                                            <button @click="addNextPage" class="py-1.5 px-4 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded text-xs">Load more</button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                </div>
            </Container>
        </div>
    </div>
    <router-view v-slot="{ Component }">
        <component
            :is="Component"
            :retainerInfo="retainerInfo"
        />
        </router-view>
</template>

<script>
    
    export default {
        data() {
            return {
                needsReview: false,
                isLoading: true,
                isPageLoading: false,
                initCall: true,
                retainers: {},
                clients: {},
                currentPage: 1,
                totalApiCount: 0,
                needsBillingTasksNumber: 0,
                totalUnPublishedTasksNumber: 0,
                overspendInvoices: {},
                computedRetainers: {},
                searchTerm: "",
                filterMonth: {},
                ascending: false,
                inputName: ""
            }
        },
        watch: {
            needsReview: function (val) {
                this.initRetainers();
            },
            ascending: function (val) {
                this.initRetainers();
            },
            filterMonth: function (val) {
                if (!this.initCall) {
                    this.initRetainers();
                }
            },
            retainers: function (val) {
                this.setComputedRetainers()
            },
            clients: function (val) {
                this.setComputedRetainers()
            },
            overspendInvoices: function (val) {
                this.setComputedRetainers()
            },
        },
        mounted() {
            if(store.getters.getAppSettings) {
                const appSettings = {...store.getters.getAppSettings};
            }

            const db = tenantDatabase();
            const clientsRef = ref(db, `/clients`);
            const retainerRef = ref(db, `/retainers/${this.$route.params.retainerId}`);
            const overspendInvoicesRef = ref(db, `/overspendInvoices`);

            onValue(clientsRef, snapshot => {
                if(snapshot.val()) {
                    this.clients = snapshot.val();
                } else {
                    this.clients = [];
                }
            });

            onValue(retainerRef, snapshot => {
                if(snapshot.val()) {
                    this.activeRetainer = snapshot.val();
                } else {
                    this.activeRetainer = null
                }
            });

            onValue(overspendInvoicesRef, snapshot => {
                if(snapshot.val()) {
                    this.overspendInvoices = snapshot.val();
                } else {
                    this.overspendInvoices = {}
                }
            });

            let date = new Date();

            if (this.$route.params.date != "latest") {
                date = new Date(this.$route.params.date + '-1');
            }

            const viewing = this.$route.query.viewing;
            const search = this.$route.query.search;


            this.filterMonth = {
                month: date.getMonth(),
                year: date.getFullYear()
            }

            if(date.getFullYear() && date.getMonth()) {
                this.$router.replace({
                    params: {
                        date: `${date.getFullYear()}-${date.getMonth() + 1}`,
                    },
                    query: {
                        search: search
                    }
                });
            }

            if (search) {
                this.searchTerm = search
            }

            this.initRetainers();
        },
        computed: {
            defaultPricing() {
                return store.getters.getAppSettings.services;
            },
            retainerInfo() {
                const activeRetainer = this.computedRetainers && Object.keys(this.computedRetainers).find(retainer => {
                    return this.computedRetainers[retainer].id == this.$route.params.retainerId
                }) || {}

                return {
                    unPublishedItems: this.computedRetainers[activeRetainer]?.unPublishedTasksNumber || 0,
                    hourlyRate: activeRetainer.retainerRate || this.defaultPricing.retainer,
                }
            },
            exceededAllowance() {
                const { retainers } = this

                return retainers && retainers.length && retainers.filter(retainer => {
                    return retainer.billable > retainer.allowance
                })?.length || null
            },
            unpublishedTasks() {
                const { retainers } = this

                return retainers && retainers.length && retainers.filter(retainer => {
                    return retainer.unPublishedTasks > 0
                })?.length || null
            },
        },
        methods: {
            handleFullReset() {
                this.searchTerm = ''
                this.$router.replace({'query': null});
                this.initRetainers();
            },
            runSearch() {
                this.$router.replace({
                    query: {
                        search: this.searchTerm
                    }
                })
                this.initRetainers();
            },
            async addNextPage() {
                const auth = await getAuth();
                const currentToken = auth.currentUser.accessToken;

                this.isPageLoading = true;
                this.currentPage++

                axios.get(`${this.$root.apiBaseUrl}/Retainer/getAll?year=${this.filterMonth.year || new Date().getFullYear()}&month=${this.filterMonth.month + 1 || new Date().getMonth() + 1}&page=${this.currentPage}&pagesize=20&isDescOrder=${this.ascending}&search=${this.searchTerm}&needReview=${this.needsReview}`, {
                    headers: {
                        'authorization': currentToken,
                        'timezone': this.$root.apiTimezone
                    }
                }).then(res => {
                    console.log([...this.retainers, ...res.data.projects])
                    this.retainers = [...this.retainers, ...res.data.projects] || []
                    this.isPageLoading = false;
                    this.totalApiCount = res.data.totalCount;
                    this.needsBillingTasksNumber = res.data.needsBillingTasksNumber;
                    this.totalUnPublishedTasksNumber = res.data.totalUnPublishedTasksNumber;
                    this.setComputedRetainers()
                }).catch((err) => {
                    this.isPageLoading = false;
                })
            },
            clearSearch() {
                this.searchTerm = ''
                this.$router.replace({'query': null});
                this.initRetainers();
            },
            convertShortDateString(date) {
                return new Date(date).toLocaleString('en-US', { month: 'short', year: 'numeric' })
            },
            setComputedRetainers() {
                const { retainers, clients } = this

                if (!retainers?.length > 0) return

                this.computedRetainers = retainers.map(retainer => {
                    const overspendInvoices = this.overspendInvoices[retainer.id] || {}
                    return {
                        ...retainer,
                        client: clients && Object.values(clients).find(client => {
                            return client.retainers ? Object.keys(client.retainers).some(clientRetainer => {
                                return clientRetainer == retainer.id
                            }) : false
                        }) || {},
                        overspendInvoices: overspendInvoices ? Object.entries(overspendInvoices).filter(([invoiceKey, invoiceData]) => {
                            return new Date(invoiceData.effectiveDate).getMonth() + 1 == this.filterMonth.month + 1 && new Date(invoiceData.effectiveDate).getFullYear() == this.filterMonth.year
                        }).reduce((curr, invoice) => {
                            return {
                                ...curr,
                                [invoice[0]]: invoice[1]
                            }
                        }, {}) : {},
                    }
                })
            },
            handleMonthUpdate(e) {
                this.filterMonth = e;

                this.$router.replace({
                    params: {
                        date: `${e.year}-${e.month + 1}`
                    }
                });
            },
            async initRetainers() {
                if (!this.isLoading || this.initCall) {
                    this.isLoading = true;
                    this.initCall = false;
                    this.currentPage = 1;
                    this.retainers = []

                    const auth = await getAuth();
                    const currentToken = auth.currentUser.accessToken;

                    axios.get(`${this.$root.apiBaseUrl}/Retainer/getAll?year=${this.filterMonth.year || new Date().getFullYear()}&month=${this.filterMonth.month + 1 || new Date().getMonth() + 1}&page=${this.currentPage}&pagesize=20&isDescOrder=${this.ascending}&search=${this.searchTerm}&needReview=${this.needsReview}`, {
                        headers: {
                            'authorization': currentToken,
                            'timezone': this.$root.apiTimezone
                        }
                    }).then(res => {
                        this.retainers = res.data.projects || []
                        this.isLoading = false;
                        this.totalApiCount = res.data.totalCount;
                        this.needsBillingTasksNumber = res.data.needsBillingTasksNumber;
                        this.totalUnPublishedTasksNumber = res.data.totalUnPublishedTasksNumber;
                        this.setComputedRetainers()
                    }).catch((err) => {
                        this.isLoading = false;
                    })
                }
            },
            toggleSortOrder() {
                this.ascending = !this.ascending;
            },
            toggleNeedsReview() {
                if (!this.isLoading) {
                    this.needsReview = !this.needsReview;
                    this.$router.replace({
                        query: {
                            needsReview: this.needsReview
                        }
                    })
                }
            }
        }
    }
</script>