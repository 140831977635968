<script setup>
    import DataBar from "./DataBar.vue";
    import UserAvatar from './objects/UserAvatar.vue';
</script>

<template>
    <div class="bg-purple-900 first:col-span-2 lg:first:col-span-1 text-white rounded-lg p-6 flex items-center">
        <div class="inline-block flex-shrink-0 relative mr-5 w-[100px] h-[100px]">
            <span class="-translate-x-2 absolute top-0 w-10 h-10 bg-orange-500 rounded-full text-white flex items-center justify-center text-lg font-bold">{{pos}}</span>
            <div class="rounded-full overflow-hidden">
                <span class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[100px] min-h-[100px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                    :style="{ backgroundImage: `url(${userData?.profileImageUrl || 'https://via.placeholder.com/100x100' ? '' : userData?.profileImageUrl})` }"
                >
                    <UserAvatar class="!text-xl" v-if="!userData?.profileImageUrl" :name="`${userData?.name} ${userData?.name}`" />
                </span>
            </div>
        </div>
        <div class="flex-1">
            <div class="text-xl font-bold">{{userData?.name}}</div>
            <div class="text-sm font-medium">{{userData?.role}}</div>
            <DataBar class="mt-3" :chartData="barData" :barOnly="true" :styling="'compact'" />
        </div>
    </div>
</template>

<script>


    export default {
        props: ['position', 'userData'],
        data: function() {
            return {
                pos: this.position ? this.position : '',
                barData: this.userData ? this.userData.hours : {},
                user: this.userData ? this.userData : {} 
            }
        }, 
        created() {
        },
        mounted() {
        }
    };

</script>


<style lang="scss" scoped>
    
</style>