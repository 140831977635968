import axios from "axios";

const baseApi = import.meta.env.VITE_BASE_API;

export default async function sendUserEmail(emailObj, token) {
    const data = { ...emailObj };
    return axios.post(`${baseApi}/email/user`, data, {
        headers: {
            'authorization': token
        }
    }).then(res => {
        return true
    }).catch(err => {
        return false
    });
}
