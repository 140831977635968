<script setup>
    import IconChevronDown from '~icons/my-icons/chevron-down';
    import Search from '~icons/my-icons/search';
    import CrossIcon from '~icons/my-icons/button-cross';

    import Fuse from 'fuse.js';
</script>

<template>
    <div 
        :class="showModal && 'border-x border-t border-gray-900'" 
        @click="onClick"
        class="relative min-h-[48px] flex items-center cursor-pointer w-full bg-white"
        >
        <div  class="flex items-center w-full">
            <div class="w-full flex flex-wrap gap-2 py-2">
                <div v-if="filteredAccounts && !filteredAccounts.length" class="font-medium cursor-pointer truncate flex items-center px-4 py-[6px] rounded ml-2">
                    <p class="text-gray-600 text-base">Select contact...</p>
                </div>
                <div v-else v-for="user in filteredAccounts" :key="user?.uid" class="font-medium cursor-pointer truncate flex items-center bg-gray-50 border border-gray-200 px-4 py-[6px] rounded ml-2">
                    <div class="flex items-center" @click.stop="$emit('handleRemoveUser', user?.uid)" >
                        <CrossIcon class="w-3 h-3 text-gray-700" />
                        <p class="text-gray-600 pl-2 text-xs">{{ user?.firstName + ' ' + user?.lastName }}</p>
                    </div>
                </div>
            </div>
            <IconChevronDown :class="showModal && 'rotate-180'" class="h-[20px] w-[20px] mr-3"/>
        </div>
        <div :class="!showModal && 'hidden'" class="w-full absolute left-[0px] top-[47px] z-40">
            <div class="bg-white flex row border-x border-b border-gray-900 w-full m-0">
                <div class="flex items-center border border-b-[#DFDFDF]">
                    <Search class="mr-2 my-3"></Search>
                    <input type="text" placeholder="Search" class="focus:border-none outline-none w-full " ref="searchbox"
                    @input="query = $event.target.value" />
                </div>
                <ul class="pl-2 w-full max-h-[204px] overflow-x-hidden overflow-y-auto z-40 bg-white">
                    <li 
                        v-for="user in filteredUsers"
                        :key="user?.uid"
                        :class="isActiveLabel(user?.uid) ? 'bg-brand-primary text-white hover:text-gray-900' : ''" 
                        class="p-2 cursor-pointer hover:bg-gray-100 active:bg-[#FF4B40] active:text-white leading-7 rounded-[4px] my-2 truncate"
                        @click="$emit('handleAddUser', user?.uid)"
                        >
                        <p @click="onClick" class="text-sm">
                            {{ user?.label }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['onClick', 'filterUsers', 'clientUsers', 'filterUsers', 'filteredAccounts', 'showModal', 'handleRemoveUser', 'handleAddUser', 'selectedUsers', 'isActiveLabel', 'addUser', 'removeUser', 'handleClicked', 'handleClick'],
        data: function() {
            return {
                accordionData: this.data ? this.data : null,
                isActive: false,
                query: this.propsQuery ? this.propsQuery : '',
            }
        },
        watch: {
            showModal(newShowModal) {
                if (newShowModal) {
                    this.$nextTick(() => {
                        this.$refs.searchbox.focus();
                    });
                }
            }
        },
        mounted() {
        },
        computed: {
            filteredUsers() {
                const fuse = new Fuse(this.filterUsers, {
                    keys: ['label'],
                    includeScore: true,
                    threshold: 0.3,
                });
                return this.query ? fuse.search(this.query).map(result => result.item) : this.filterUsers;
            }
        },
        methods: {
            close(){
                this.showModal = false
            },
            // handleClicked(userId) {
            //     console.log('Clicked:', userId);
            //     this.$emit('handleAddUser', userId.uid);
            // },
        }
    }

</script>


<style lang="scss" scoped>

</style>