<script setup>
    import IconChevronDown from '~icons/my-icons/chevron-down';
</script>

<template>
    <div class="bg-white border border-bd rounded-lg">
        <button class="px-6 py-4 w-full text-left text-sm font-bold relative" :class="{'border-b border-grey-200' : isOpened}" type="button" @click="isOpened = !isOpened">
            {{ heading }}
            <IconChevronDown class="transition absolute top-1/2 right-3 -translate-y-1/2 pointer-events-none" :class="{'rotate-180': isOpened }" />
        </button>
        <div class="p-6" v-if="isOpened">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: ['title', 'opened'],
    data: function () {
        return {
            heading: this.title ? this.title : '',
            isOpened: this.opened ? this.opened : false
        }
    },
    created() {
    },
    methods: {

    }
};

</script>


<style lang="scss" scoped></style>