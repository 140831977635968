import store from "../store";
import axios from "axios";
import e from "cors";
import { getAuth } from "../tenant";

const baseApi = import.meta.env.VITE_BASE_API;

export default async function teamValueToLabel(value, isEverhourId) {

    // Edge cases here
    if(value == 'internal-user') {
        return 'Internal User';
    }
    
    if(store.getters.getAllTeams.length == 0) {
        const auth = await getAuth(); 
        return axios.get(`${baseApi}/client/all`, {
            headers: {
                'authorization': auth.currentUser.accessToken
            }        
        }).then(res => {
            if(res.data.length > 0) {
                store.commit('updateAllTeams', res.data);
                return convertValueToLabels(value);
            }
            else {
                return convertValueToLabels(value);
            }
        }).catch(err => {
            console.log(err);
            return 'AJAX error';
        });
    } else {
        return convertValueToLabels(value);
    }

    function convertValueToLabels(value) {        
        const allTeams = store.getters.getAllTeams;
        let thisTeam;
        if(isEverhourId) {
            thisTeam = allTeams.filter(item => { 
                return item.externalClientId == parseInt(value) 
            });
        } else {
            thisTeam = allTeams.filter(item => { 
                return item.id == parseInt(value) 
            });
        }
        return thisTeam.length > 0 ? thisTeam[0].name : '';
    }

}