import allSettings from "@/data/accountSettingsData";
import staticData from "@/data/staticData";
import convertTimeHelper from '@/helpers/ConvertTimeHelper.js';
import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
import { getAuth, tenantDatabase } from "../tenant";
import axios from "axios";

export function ClientAlertCounter(retainer) {

    const alerts = {}

    // Meeting frequency warning
    let previousMeetingUnix = 0;
    let futureMeetingUnix = 0;
    if(retainer.meetings && retainer.meetings.length > 0) {
        const dateArrays = retainer.meetings?.map(a => {
            return new Date(a.date).setHours(a.time.hours, a.time.minutes, a.time.seconds);
        });
        const allPreviousMeetings = dateArrays.filter(a => a < Math.floor(new Date().getTime())).sort((a, b) => b - a);
        if (allPreviousMeetings.length > 0) {
            previousMeetingUnix = allPreviousMeetings[0];
        }
        const allFutureMeetings = dateArrays.filter(a => a > Math.floor(new Date().getTime())).sort((a, b) => a - b);
        if (allFutureMeetings.length > 0) {
            futureMeetingUnix = allFutureMeetings[0]
        }
    } 


    if(previousMeetingUnix > 0 && futureMeetingUnix > 0) {
        // Calculate if frequency target is met
        const thisDateFrequencies = staticData.dateFrequencies.find(a => a.value === retainer.meetingFrequency).calculateDayValue;
        const daysDifference = Math.ceil((futureMeetingUnix - previousMeetingUnix) / (1000 * 3600 * 24))
        if(thisDateFrequencies < daysDifference) {
            // Check if snoozed
            if (new Date().valueOf() > new Date(retainer?.snoozeNextMeetingAlert || 0).valueOf()) {
                alerts.meetingFrequency = true
            }
        }
    } 
    
    // Quote update alert confirm past snooze
    if (convertTimeHelper(retainer.lastQuoteEditDate, true) > 365 && new Date().valueOf() > new Date(retainer?.snoozeQuoteRateAlert || 0).valueOf()) {
        alerts.quoteUpdate = true
    }

    if (futureMeetingUnix == 0) {
        alerts.noFutureMeetingScheduled = true
    }

    return alerts
}

export function RetainerAlertCounter(retainer) {

    const alerts = {}

    if (retainer?.contractExpiry == 'Invalid Date' || Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 30 || !retainer?.contractExpiry) {
        alerts.contractExpiry = true
    }

    if (retainer?.remaining?.includes('-')) {
        alerts.negativeHours = true
    }

    return alerts
}

export async function AlertTotal(client) {
    const dbRef = ref(tenantDatabase());
    const auth = await getAuth();
    const retainerAlerts = 0

    const childrenRetainerAlerts = client.retainers ? await client.retainers.filter(retainer => retainer.retainerId).reduce(async (val, retainer) => {
        const res = await get(child(dbRef, `retainers/${retainer.id}`))
        const data = res.val();

        const mappedData = {...data, ...retainer}


        return await val + Object.keys(RetainerAlertCounter(mappedData)).length
    }, 0) : 0

    return Object.keys(ClientAlertCounter(client)).length + childrenRetainerAlerts
}