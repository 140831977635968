import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    plugins: [createPersistedState()],
    state: {
        authInstance: {},
        userState: {},
        roles: [],
        allProjects: [],
        allTeams: [],
        firestore: {},
        retainerDetails: null,
        retainerUsers: [],
        monthListingState: '',
        activeRetainerId: null,
        appSettings: null,
        viewAsClient: null,
        sendReportRetainerData: null,
        allRetainers: []
    },
    mutations: {
        updateAllProjects(state, store) {
            state.allProjects = store
        },
        updateFirestore(state, store) {
            state.firestore = store
        },
        updateUserState(state, userState) {
            state.userState = userState;
        },
        updateAllTeams(state, store) {
            state.allTeams = store;
        },
        updateRetainerDetails(state, data) {
            state.retainerDetails = data;
        },
        updateRetainerUsers(state, data) {
            state.retainerUsers = data;
        },
        updateUserProfileImage(state, data) {
            state.userState.profileImage = data;
        },
        updateMonthListingState(state, data) {
            state.monthListingState = data;
        },
        updateActiveRetainerState(state, data) {
            state.activeRetainerId = data;
        },
        updateAppSettings(state, data) {
            state.appSettings = data;
        },
        updateViewAsClient(state, data) {
            state.viewAsClient = data;
        },
        updateSendReportRetainerData(state, data) {
            state.sendReportRetainerData = data;
        },
        updateAllRetainers(state, data) {
            state.allRetainers = data
        },
    },
    getters: {
        getUserState(state) {
            return state.userState;
        },
        getUserRetainerId(state) {
            return state.userState.retainer
        },
        getUserTeamId(state) {
            return state.userState.team
        },
        getAuthInstance(state) {
            return {...state.authInstance};
        },
        getFirestore(state) {
            return {...state.firestore};
        },
        getAllProjects(state) {
            return state.allProjects;
        },
        getAllTeams(state) {
            return [...state.allTeams];
        },
        getRetainerDetails(state) {
            return state.retainerDetails;
        },
        getRetainerUsers(state) {
            return state.retainerUsers;
        },
        getMonthListingState(state) {
            return state.monthListingState;
        },
        getActiveRetainerState(state) {
            return state.activeRetainerId;
        },
        getAppSettings(state) {
            return state.appSettings;
        },
        getViewAsClient(state) {
            return state.viewAsClient;
        },
        getSendReportRetainerData(state) {
            return state.sendReportRetainerData;
        },
        getAllRetainers(state) {
            return state.allRetainers
        }
    }
});