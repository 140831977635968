import allSettings from "../data/accountSettingsData";

export default function yearMonthFormat(source) {
    let date

    if (typeof source === "object") {
        date = `${source.getFullYear()}-${source.getMonth() + 1}`;
    } else {
        const formattedSource = new Date(`1 ${source}`);
        date = `${formattedSource.getFullYear()}-${formattedSource.getMonth() + 1}`;
    }

    return date
}