<script setup>

import { getAuth } from "@/tenant";
    import axios from 'axios';
    import store from '@/store';

    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    import Container from '@/components/containers/Container.vue';
    import Header from '@/components/objects/Header.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    
    import PermissionsHelpers from '@/helpers/PermissionHelpers';
	import yearMonthFormat from '@/helpers/YearMonthFormat';

	import LogAuditLog from "@/helpers/logAuditLog";
    import checkDigit from "@/helpers/CheckDigit";  
</script>

<template>
  <div class="relative">
    <div class="py-9 px-8 bg-white border-b border-solid border-bd">
        <Container>
            <div class="w-full flex justify-between items-center text-3xl font-bold">
                <router-link :to="{name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(activeMonthState)}}">
                    <span class="inline-flex items-center">
                        <ArrowLeft class="mr-8 text-xl" />
                        <span>{{$route.params.dataid == 'new' ? 'Add' : 'Edit'}}</span>&nbsp;entry
                    </span>
                </router-link>
            </div>
        </Container>
    </div>
    <div class="pt-20 pb-20">
        <Container>
            <div class="max-w-[840px] mx-auto border border-solid border-bd rounded-lg p-12 bg-white u-content-wrapped">
                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Submitting...'">
                    <div class="mb-3 flex items-center">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            Date *
                        </div>
                        <div class="text-grey-900 flex-1">
                            <Datepicker
                                v-model="dataObj.date"
                                :autoApply="true"
                                :enableTimePicker="false"
                                :format="'dd MMM yyyy'" />
                        </div>
                    </div>
                    <div class="my-3 flex" :class="{'my-6': $route.params.dataid == 'new'}">
                        <div class="w-40 mt-3 pr-6 flex-shrink-0 text-grey-300 text-xs font-bold">
                            Task *
                        </div>
                        <div class="text-grey-900" v-if="$route.params.dataid !== 'new'">
                            <select v-model="dataObj.externalTaskId" class="appearance-none px-4 py-3 border border-solid border-bd rounded block w-full">
                                <option v-for="t in taskData" :value="t.externalTaskId" :key="t.externalTaskId">{{t.taskName}}</option>
                            </select>
                        </div>
                        <div class="w-full" v-else>
                            <input type="text" class="px-4 py-3 border border-solid border-bd rounded w-full block" v-model="dataObj.taskName" />
                        </div>
                    </div>
                    <div class="my-3 flex">
                        <div class="w-40 mt-3 pr-6 flex-shrink-0 text-grey-300 text-xs font-bold">
                            Comments
                        </div>
                        <div class="w-full">
                            <textarea v-model="dataObj.comment" class="px-4 py-3 border border-solid border-bd rounded w-full block h-32"></textarea>
                        </div>
                    </div>
                    <div class="my-3 flex">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-3">
                            Submitted *
                        </div>
                        <div class="text-grey-900 flex-1">
                            <div class="text-grey-400 block w-full">
                                <select v-model="dataObj.user" class="appearance-none px-4 py-3 border border-solid border-bd rounded block w-full">
                                    <option value=""></option>
                                    <option v-for="t in allUsers" :value="t.externalUserId" :key="t.externalUserId">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="my-6 flex">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            Billable *
                        </div>
                        <div class="">
                            <input id="billable" type="radio" class="px-3 py-2 w-4 mr-2 text-grey-900" :value="true" v-model="dataObj.billable" />
                            <label for="billable" class="mr-8 text-sm">Yes</label>
                            <input id="nonbillable" type="radio" class="px-3 py-2 w-4 mr-2 text-grey-900" :value="false" v-model="dataObj.billable" />
                            <label for="nonbillable" class="text-sm">No</label>
                        </div>
                    </div>
                    <div class="my-3 flex items-center">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            Time *
                        </div>
                        <div class="">
                            <input @keydown="checkDigit" class="px-3 py-2 w-16 mr-2 border border-solid border-bd rounded text-grey-900" v-model="dataObj.time.hours" />
                            <span class="mr-4">hrs</span>
                            <input @keydown="checkDigit" class="px-3 py-2 w-16 mr-2 border border-solid border-bd rounded text-grey-900" v-model="dataObj.time.minutes" />
                            <span>m</span>
                        </div>
                    </div>
                    <div class="my-3 flex border-y border-bd py-4 mt-8">
                        <span class="w-40 pr-6 text-grey-300 text-xs font-bold flex-shrink-0 mt-1">Change Log</span>
                        <div v-if="changeLog.length === 0" class="text-sm">No changes have been made.</div>
                        <span>
                            <div class="text-sm mb-2 last:mb-0" v-for="l in changeLog" :key="l.data">
                                <strong>{{convertDateTimeString(l.createdOn)}}{{!!l.createdBy ? (" by " + l.createdBy) : ""}}</strong>
                                <div v-for="d in l.data" :key="d">
                                    {{d}}
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="pt-8 flex justify-end items-center">
                        <router-link :to="{name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(activeMonthState)}}">
                            <div class="mr-8 text-blue-500">
                                Cancel
                            </div>
                        </router-link>
                        <button :disabled="isSubmitting" v-if="$route.params.dataid !== 'new'" class="text-red-500 mr-8" type="button" v-on:click="deleteItem">Delete</button>
                        <button :disabled="isSubmitting || !formValidation" class="py-3 px-10 bg-orange-500 rounded text-white disabled:text-grey-300 disabled:bg-grey-100" type="button" v-on:click="saveItem">Save</button>
                    </div>
                </Loading>
            </div>
        </Container>
    </div>
  </div>
</template>

<script>

export default {
    props: ['dataid'],
    data() {
        return {
            dataObj: {
            time:{}
            },
            retainerId: 0,
            taskData: [],
            allUsers: [],
            changeLog:[],
            isSubmitting: false,
            activeMonthState: store.getters.getMonthListingState
        }
    },
    mounted() { 
        this.listUsers();
        if(this.dataid && this.dataid > 0) {
            this.getItem(this.dataid);
        } else {
            const taskId = new URLSearchParams(window.location.search).get('task');
            this.getItem(taskId);
        }

        let userRetainer;
		if(store.getters.getActiveRetainerState) {
			userRetainer = store.getters.getActiveRetainerState;
			this.retainerId = userRetainer;
		} else {
			userRetainer = store.getters.getUserRetainerId;
			this.retainerId = userRetainer?.[0];
		}
    },
    computed: {
        dateISOString() {
            return this.dataObj.date.toISOString();
        },
        hours() {
            return this.dataObj.time.hours;
        },
        minutes() {
            return this.dataObj.time.minutes;
        },
        formValidation() {
            const { date, billable, taskName, user, time } = this.dataObj;
            return date && (billable == true || billable == false) && user && taskName && (time.hours || time.minutes);
        }
    },
    methods: {
        convertDateString(ds) {
            if(!ds) {
                return ''
            }
			var options = { day: 'numeric', month: 'short', year: 'numeric' };
			var date = new Date(ds);
			return date.toLocaleString('en-GB', options)
		},
        convertDateTimeString(ds) {
            if(!ds) {
                return ''
            }
			var options = { day: 'numeric', month: 'short', year: 'numeric' };
			var date = new Date(ds);
			return date.toLocaleString('en-GB', options) + " " + date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds();
		},
        async getItem(id){

            const auth = await getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {

					const currentToken = auth.currentUser.accessToken;

                    if (id > 0) {
                        axios.get(`${this.$root.apiBaseUrl}/timerecord/${id}`,
                        {
                            headers: {
                                'authorization': currentToken
                            }
                        }
                        ).then(res => {
                            this.dataObj = res.data;
                        }).catch(err => {
                            console.log(err);
                        });

                        axios.get(`${this.$root.apiBaseUrl}/changelog`,
                        {
                            headers: {
                                'authorization': currentToken
                            },
                            params: {
                                entityType:"TimeRecord",
                                entityID: id

                            }
                        }).then(res => {
                            this.changeLog = res.data;
                        }).catch(err => {
                            console.log(err);
                        });
                    }

                    if (!PermissionsHelpers.isWebcodaAdmin())
                    {
                        const userRetainer = store.getters.getUserRetainerId;
                        if (userRetainer.indexOf(this.retainerId) < 0){
                            this.retainerId = userRetainer?.[0];
                        }
                    }

                    axios.get(`${this.$root.apiBaseUrl}/projecttask/${this.$route.params.retainerId}`,
					{
						headers: {
							'authorization': currentToken
						},
                        params:{
                            currentTimeRecordID: id
                        }
					}).then(res => {
						this.taskData = res.data;
					}).catch(err => {
						console.log(err);
					});

                }});
        },
        saveItem() {
            if (this.$route.params.dataid == 'new'){
                this.createItem();
            } else{
                this.updateItem();
            }
        },
        async createItem() {
            if(!this.formValidation) {
                return;
            }
            this.isSubmitting = true;
            const auth = await getAuth();

			auth.onAuthStateChanged(user => {
                if(user) {

					const currentToken = auth.currentUser.accessToken;

                    axios({
                        method: 'post',
                        url: `${this.$root.apiBaseUrl}/timerecord`,
                        data: {
                            comment: this.dataObj.comment,
                            time: this.dataObj.time,
                            billable: (this.dataObj.billable == true || this.dataObj.billable === "true"),
                            taskID: this.dataObj.externalTaskId,
                            date: this.dataObj.date.toLocaleString(),
                            taskName: this.dataObj.taskName,
                            taskProjects: this.dataObj.taskProjects,
                            user: this.dataObj.user,
                            retainerID: this.retainerId
                        },
                        headers: {
                            'authorization': currentToken
                        }
                    }).then(res => {
						LogAuditLog({
                            type: "Retainer",
                            object: "Task",
                            action: "create",
                            data: {
                                id: res.data,
                                clientId: this.$route.params.clientId,
                                retainerId: this.$route.params.retainerId,
                                task: {
                                    ...this.dataObj,
                                }
                            }
						})

                        // this.dataObj = res.data;
                        this.isSubmitting = false;
                        this.$router.push({name: 'Beta Dashboard Reports Listing', params: { date: this.$route.params.date}});
                    }).catch(err => {
                        console.log(err)
                        this.isSubmitting = false;
                        alert('error')
                    });
                }});
        },
        async updateItem() {
            if(!this.formValidation) {
                return;
            }
            this.isSubmitting = true;
            const auth = await getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {

					const currentToken = auth.currentUser.accessToken;

                    axios({
                        method: 'put',
                        url: `${this.$root.apiBaseUrl}/timerecord/${this.dataObj.id}`,
                        data: {
                            comment: this.dataObj.comment,
                            time: this.dataObj.time,
                            billable: (this.dataObj.billable == true || this.dataObj.billable === "true"),
                            externalTaskId: this.dataObj.externalTaskId,
                            user: this.dataObj.user,
                            date: this.dataObj.date,
                            id: this.dataObj.id
                        },
                        headers: {
                            'authorization': currentToken
                        }
                    }).then(res => {
						LogAuditLog({
                            type: "Retainer",
                            object: "Task",
                            action: "edit",
                            data: {
                                id: this.$route.params.dataid,
                                clientId: this.$route.params.clientId,
                                retainerId: this.$route.params.retainerId,
                                task: this.dataObj
                            }
						})

                        this.dataObj = res.data;
                        this.isSubmitting = false;
                        this.$router.push({name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(this.activeMonthState)}});
                    }).catch(err => {
                        this.isSubmitting = false;
                        console.log(err)
                        alert('error')
                    });
                }});
        },
        async deleteItem() {
            this.isSubmitting = true;
            const auth = await getAuth();
            auth.onAuthStateChanged(user => {
                if(user) {
                    const currentToken = auth.currentUser.accessToken;
                    axios({
                        method: "delete",
                        url: `${this.$root.apiBaseUrl}/timerecord/${this.dataObj.id}`,
                        headers: {
                            'authorization': currentToken
                        }
                    }).then(res => {
						LogAuditLog({
                            type: "Retainer",
                            object: "Task",
                            action: "delete",
                            data: {
                                id: this.$route.params.dataid,
                                clientId: this.$route.params.clientId,
                                retainerId: this.$route.params.retainerId,
                                task: this.dataObj
                            }
						})

                        this.isSubmitting = false;
                        this.dataObj = res.data;
                        this.$router.push({name: 'Beta Dashboard Reports Listing', params: { date: yearMonthFormat(this.activeMonthState)}});
                    }).catch(err => {
                        this.isSubmitting = false;
                        console.log(err);
                        alert('error')
                    });
                }
            });
        },
        async listUsers() {
            const auth = await getAuth();
            auth.onAuthStateChanged(user => {
                if(user) {
                    const currentToken = auth.currentUser.accessToken;
                    axios.get(`${this.$root.apiBaseUrl}/users/active`,
                    {
                        headers: {
                            'authorization': currentToken
                        }
                    }
                    ).then(res => {
                        this.allUsers = res.data;
                    }).catch(err => {
                        console.log(err);
                    });
                }
            });
        }
    }
  }
</script>

<style lang="scss">
    .dp__main {
        font-family: inherit;
    }
    .dp__input {
        min-height: 50px;
        border: 1px solid theme('colors.bd');
        color: theme('colors.grey-900');
        font-family: inherit;
    }
</style>
