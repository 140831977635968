<script setup>
    import TaskList from "./TaskList.vue";
    import UserAvatar from './objects/UserAvatar.vue';
</script>

<template>
    <div class="block w-full">
        <div class="flex w-full items-start lg:items-center flex-col lg:flex-row" :class="[componentType === 'user' ? 'px-8' : '']">
            <div class="flex items-center max-w-[360px] w-full lg:mb-0 mb-6" v-if="componentType === 'user'">
                <div class="inline-block flex-shrink-0 relative mr-5 w-[100px] h-[100px]">
                    <span class="-translate-x-2 z-[2] absolute top-0 w-10 h-10 bg-orange-500 rounded-full text-white flex items-center justify-center text-lg font-bold">{{position}}</span>
                    <div class="rounded-full overflow-hidden">
                        <span class="mr-4 rounded-full overflow-hidden flex-shrink-0 min-w-[100px] min-h-[100px] inline-flex items-center justify-center bg-center bg-cover bg-black"
                            :style="{ backgroundImage: `url(${!user?.profileImageUrl || 'https://via.placeholder.com/100x100' ? '' : user?.profileImageUrl})` }"
                        >
                            <UserAvatar class="!text-xl" v-if="!user?.profileImageUrl" :name="`${user?.name} ${user?.name}`" />
                        </span>
                    </div>
                </div>
                <div class="max-w-[200px] w-full pr-4 mb-4 lg:sm-0">
                    <div class="text-xl font-bold">{{user?.name}}</div>
                    <div class="text-sm font-medium">{{user?.role}}</div>
                </div>
            </div>
            <div class="flex-1 flex w-full" :class="[styleModifier == 'compact' || styleModifier == 'small' ? '' : 'p-8']" v-if="parsedData">
                <div class="flex flex-1 flex-col gap-4 items-center sm:flex-row c-bar-overview" :class="[styleModifier ? 'c-bar-overview--' + styleModifier : '']">
                    <div class="c-bar-overview__container bg-grey-100 rounded flex-1 flex flex-col w-full order-2">
                        <div ref="topBar" class="c-bar-overview__bar h-8 bg-purple-500 rounded mb-1 min-w-[64px] text-white font-bold w-[64px] px-4 py-1 justify-end inline-flex items-center transition-all duration-700">
                            <span>{{topBarData ? topBarData : '0'}}</span>
                        </div>
                        <div class="c-bar-overview__bar-wrapper h-8 relative flex">
                            <div ref="bottomBar" :class="[bottomBarDataExceeded ? 'clip-bg' : 'clip-bg-round']" class="c-bar-overview__bar h-8 overflow-hidden rounded min-w-[64px] text-white font-bold w-[64px] px-4 py-1 justify-end inline-flex items-center transition-all duration-700">
                                <div class="absolute bg-gradient-to-l from-[#70BC41] to-[#B6B6B6] w-full h-full top-0 left-0 rounded"></div>
                                <span class="z-10">{{bottomBarData ? bottomBarData : '0'}}</span>
                            </div>
                            <span v-if="bottomBarDataExceeded" class="w-[2px] bg-transparent"></span>
                            <div v-if="bottomBarDataExceeded" ref="exceedBottomBar" class="c-bar-overview__bar rounded-r h-8 bg-[#317416] w-16 text-right px-4 inline-flex items-center justify-end text-white font-bold transition-all duration-700 delay-500 min-w-[60px]">
                                <span class="z-10">{{bottomBarDataExceeded ? bottomBarDataExceeded : '0'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center ml-auto flex-shrink-0 sm:ml-auto w-full sm:justify-end justify-between order-2 sm:w-[180px] md:w-[240px]" v-if="!barOnly">
                        <div class="c-bar-overview__total text-center px-1 min-w-[140px]">
                            <span class="c-bar-overview__total__label block text-grey-600 text-xs" v-if="componentType && componentType == 'user'">
                                Billable / Target
                            </span>
                            <span v-else class="c-bar-overview__total__label block text-grey-600 text-xs">
                                Billable target
                            </span>
                            <div class="c-bar-overview__total__number flex items-center text-center justify-center w-full">
                                <span v-if="componentType && componentType == 'user'" class="block text-lg font-medium mr-1.5">
                                    {{bottomBarDataExceeded ? parseFloat((parseFloat(bottomBarData) + parseFloat(bottomBarDataExceeded)).toPrecision(2)) : bottomBarData}}hr<span v-if="bottomBarData > 1">s</span> 
                                    /</span>
                                <span class="block text-lg font-medium">{{billableTarget}}hrs</span>
                            </div>
                        </div>
                        <button @click="handleAccordion" v-if="parsedData?.taskList" class="flex-shrink-0 w-10 h-10 border-2 rounded-full border-grey-900 text-3xl flex items-center justify-center sm:ml-3 md:ml-6">
                            <span v-if="!isActive">+</span>
                            <span v-else>-</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <TaskList class="flex-1 mt-6" v-if="isActive" :data="parsedData?.taskList" :dataType="displayDataType" />
    </div>
</template>

<script>

    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';

    const DEFAULT_MONTHLY_TARGET = 30;

    export default {
        data() {
            return {
                parsedData: this.chartData ? this.chartData : null,
                topBarData: this.chartData?.nonBillable ? this.chartData.nonBillable : 0,
                bottomBarData: 0,
                bottomBarDataAll: null,
                bottomBarDataExceeded: null,
                billableTarget: this.chartData?.billableTarget ? this.chartData?.billableTarget : DEFAULT_MONTHLY_TARGET,
                styleModifier: this.styling ? this.styling : null,
                componentType: this.type ? this.type : null,
                isActive: this.activeDetail ? this.activeDetail : false,
                user: this.userData ? this.userData : null,
                position: this.positionNumber ? this.positionNumber : 0,
                displayDataType: this.useData ? this.useData : null,
                retainerUsers: [],
            }
        },
        props: ['chartData', 'barOnly', 'styling', 'activeDetail', 'toggleHandler', 'type', 'userData', 'positionNumber', 'useData'],
        created() {
            const target = this.chartData?.billableTarget ? this.chartData?.billableTarget : DEFAULT_MONTHLY_TARGET;
            if(this.chartData) {
                if(this.chartData.billable > target) {
                    this.bottomBarData = target;
                    this.bottomBarDataExceeded = (this.chartData.billable - target).toFixed(2);
                    this.bottomBarDataAll = this.chartData.billable;
                } else {
                    this.bottomBarData = this.chartData.billable;
                }
            }
        },
        mounted() {
            setTimeout(() => {
                this.animateBars();
            }, 1);
        },
        methods: {
            initTooltip(ref, content) {
                tippy(ref, {
                    content: content,
                    placement: 'top'
                });
            },
            handleAccordion() {
                this.isActive = !this.isActive;
            },
            animateBars() { 
                // Animate top bar
                let topPercentageToTarget = (this.topBarData / this.billableTarget) * 100;
                topPercentageToTarget >= 100 ? topPercentageToTarget = 100 : ''; 
                if(this.$refs.topBar) {
                    this.$refs.topBar.style.width = topPercentageToTarget + '%';
                    this.initTooltip(this.$refs.topBar, 'Non-billable');
                }

                if(this.bottomBarDataExceeded) {
                    const exceededPercentageToTarget = ((this.bottomBarDataExceeded / this.bottomBarDataAll) * 100).toFixed(2);
                    this.$refs.bottomBar.style.width = 100 - exceededPercentageToTarget + '%';
                    if(this.$refs.exceedBottomBar) {
                        this.$refs.exceedBottomBar.style.width = 'calc(' + exceededPercentageToTarget + '% - 2px)';         // NOTE: 2px for the divider
                        this.initTooltip(this.$refs.exceedBottomBar, 'Exceeded');
                    }
                    this.initTooltip(this.$refs.bottomBar, 'Billable');
                } else {
                    // Animate bottom bar
                    const bottomPercentageToTarget = ((this.bottomBarData / this.billableTarget) * 100).toFixed(2);
                    if(this.$refs.bottomBar) {
                        this.$refs.bottomBar.style.width = bottomPercentageToTarget + '%';
                        this.initTooltip(this.$refs.bottomBar, 'Billable');
                    }
                }
            }
        }
    }

</script>


<style lang="scss" scoped>
    .clip-bg {
        clip-path: inset(0px round 0px);
    }
    .clip-bg-round {
        clip-path: inset(0px round 4px);
    }
    .tippy-content {
        padding: 1rem;
        margin: 0;
    }
    .c-bar-overview--compact {
        .c-bar-overview__bar {
            height: 20px;
            font-size: theme('fontSize.xs');
        }
        .c-bar-overview__bar-wrapper {
            height: auto;
        }
        .c-bar-overview__container {
            background-color: theme('colors.purple-800');
        }
    }
    .c-bar-overview--small {
        .c-bar-overview__bar {
            height: 24px;
            font-size: theme('fontSize.xs');
        }
        .c-bar-overview__bar-wrapper {
            height: auto;
        }
        .c-bar-overview__total__label {
            font-size: theme('fontSize.xs');
        }
        .c-bar-overview__total__number {
            font-size: theme('fontSize.base');
        }
    }
</style>