<script setup>
    import { toRef } from 'vue';
    import deleteUser from '../helpers/DeleteUser';
    import Loading from '../components/containers/Loading.vue';
    import { getDatabase, ref, update } from "firebase/database";
    import { getAuth, tenantDatabase } from "@/tenant";
</script>

<template>
    <Loading :isLoading="submitting" :type="'overlay'" :loadText="'Processing...'">
    <div v-if="isActive" class="fixed w-screen h-screen z-100 top-0 flex justify-center items-center">
        
            <div class="bg-[#111A1C] w-full absolute h-full opacity-40 z-1" @click="closeModal()"></div>
            <div class="bg-white px-4 py-8 z-10 max-w-[578px] w-full absolute text-center rounded-lg">
                <p class="pb-4 leading-[28px]">
                    Are you sure you want to delete this account?
                </p>
                <div class="mb-8 mt-3 inline-block text-left">
                    <span class="block mb-1">Name: <span class="font-medium">{{selectedUser.user.firstName}} {{selectedUser.user.lastName}}</span></span>
                    <span class="block">Email: <span class="font-medium">{{selectedUser.user.email}}</span></span>
                </div>
                <div>
                    <button class="w-[176px] mr-3 bg-grey-50 px-6 py-3 rounded-md" @click="closeModal()">Cancel</button>
                    <button class="w-[176px] bg-orange-500 px-6 py-3 rounded-md text-white" @click="handleDeleteUser()">Delete account</button>
                </div>
            </div>
    </div>
        </Loading>
</template>

<script>
    export default {
    props: ['isActive', 'selectedUser', 'admin'],
    emits: ['handleModalClose'],
    watch: {
    },
    created() {
    },
    data() {
        return {
            submitting: false
        }
    },
    mounted() {
    },
    methods: {
        async handleDeleteUser() {
            this.submitting = true;

            let selectedUserResponsibilities = [];
                if (this.selectedUser && this.selectedUser.user && this.selectedUser.user.responsibilities) {
                selectedUserResponsibilities = Object.keys(this.selectedUser.user.responsibilities).reduce((clientIds, key) => {
                    if (this.selectedUser.user.responsibilities[key]) {
                    clientIds.push(key);
                    }
                    return clientIds;
                }, []);
            }

            const db = tenantDatabase();

            // Create a batch update object
            let updates = {};

            // For each client, remove the user from their accountManagers
            selectedUserResponsibilities.forEach(clientId => {
            updates[`/clients/${clientId}/accountManagers/${this.selectedUser.userId}`] = null;
            });

            // Update the database
            await update(ref(db), updates);

            await deleteUser(this.selectedUser.userId, this.selectedUser.user);

            this.$notify({
            title: "User deleted",
            text: "User successfully deleted",
            duration: 5000,
            });

            this.submitting = false;
            this.modalActive = false;
            this.$emit('handleModalClose');
            document.body.classList.remove('overflow-hidden');
        },    
        closeModal(success) {
            this.modalActive = false;
            this.$emit('handleModalClose');
            document.body.classList.remove('overflow-hidden');
        }
    }
}
</script>

<style lang="scss">

</style>
