import { getAuth, tenantDatabase } from "../tenant";
import { getDatabase, get, child, ref } from "firebase/database";

export default async function getAllClients() {

    const db = ref(tenantDatabase());
    return get(child(db, `clients`)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        } else {
            console.log("No data available");
            return {}
        }
        }).catch((error) => {
            console.error(error);
    });

}