<script setup>
    import { getAuth, tenantDatabase } from "@/tenant";
    import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
    import HappinessRenderer from "./objects/HappinessRenderer.vue";
    import Dropdown from './objects/Dropdown.vue';
    import Tooltip from './objects/Tooltip.vue';
    import UserDisplay from './objects/UserDisplay.vue';
    import Editor from '@tinymce/tinymce-vue'
	
	import axios from "axios";

    import GeneralHelper from "./../helpers/GeneralHelpers";
    import convertTimeHelper from './../helpers/ConvertTimeHelper.js';
    import staticData from "../data/staticData";
    import RetainerExtendDropdown from "@/components/objects/RetainerExtendDropdown.vue";
    import { isToday, format } from 'date-fns'
    import UserAvatar from '@/components/objects/UserAvatar.vue';
    import PermissionsHelpers from '@/helpers/PermissionHelpers';

    import { Skeletor } from "vue-skeletor";

    import LogAuditLog from "@/helpers/logAuditLog";

    import IconHappinessVeryHappy from '~icons/my-icons/happiness-v-happy';
    import IconHappinessHappy from '~icons/my-icons/happiness-happy';
    import IconHappinessModerate from '~icons/my-icons/happiness-moderate';
    import IconHappinessSad from '~icons/my-icons/happiness-sad';
    import IconHappinessVerySad from '~icons/my-icons/happiness-v-sad';

    import IconChevronRight from '~icons/my-icons/chevron-right';
    import IconChevronDown from '~icons/my-icons/chevron-right';
    import IconWarning from '~icons/my-icons/warning';
    import TextboxIcon from '~icons/my-icons/textbox';
    import Cross from '~icons/my-icons/notesCross';

	import store from '@/store';
</script>

<template>
    <tr :class="formattedRetainerData?.archived && 'bg-grey-100'" class="border-b border-grey-200 last:border-b-0 group" ref="listItem">
        <td class="py-4 px-4 text-center">
            <button
                class="w-[36px] h-[36px] shrink-0 cursor-pointer flex items-center justify-center rounded-full border border-grey-200 text-grey-600 bg-grey-50"
                type="button"
                @click="handleToggleItem"
            >
                <IconChevronRight
                    class="transition h-4 w-4"
                    :class="[isActive ? 'rotate-90' : '']"
                />
            </button>
        </td>
        <td class="py-4 px-2">
            <span class="font-normal flex items-center" :class="{'text-warning': isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf()}">
                <Tooltip 
                    v-if="
                        isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf()"
                    class="no-underline text-center flex" 
                    position="top"
                    :content="'Quote rate not updated in over 365 days'"
                >
                    <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="nextMeeting" />
                    <span class="font-medium">{{formattedRetainerData.name}}</span>
                </Tooltip>
                <span v-else class="font-medium">{{formattedRetainerData.name}}</span>
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs group-hover:inline hidden">
                {{ formattedRetainerData.previousMeeting }}
            </span>
            <span class="text-xs group-hover:hidden inline">
                {{ formattedRetainerData.previousMeeting !== "—" ? convertTimeHelper(formattedRetainerData.previousMeeting) : "—" }}
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs">
                <span v-if="formattedRetainerData.futureMeeting" class="flex items-center" :class="{'font-medium text-warning': isFrequencyTargetWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()}">
                    <Tooltip v-if="isFrequencyTargetWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()"
                        class="no-underline text-center flex "
                        :content="`Does not meet client’s “${GeneralHelper.convertFrequencyLabel(formattedRetainerData.meetingFrequency)}” meeting frequency goal`"
                        position="top"
                    >
                        <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="nextMeeting" @click="hideSnoozeNextMeetingAlert($event)" />
                        {{ isToday(new Date(formattedRetainerData.futureMeeting)) ? "Today " + format(new Date(formattedRetainerData.futureMeeting), "h:mm a") :  formattedRetainerData.futureMeeting }}
                    </Tooltip>                    
                </span>
                <span class="font-normal flex items-center" :class="{'text-warning': new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()}" v-else>
                    <Tooltip v-if="new Date().valueOf() > new Date(formattedRetainerData.snoozeNextMeetingAlert || 0).valueOf()" class="no-underline flex" content='No meeting scheduled' position="top">
                        <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="nextMeeting" @click="hideSnoozeNextMeetingAlert($event)" />
                        Not scheduled
                    </Tooltip>
                   
                </span>
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs">
                ${{ formattedRetainerData.rate }}
            </span>
        </td>
        <td class="py-4 px-2">
            <span class="text-xs flex items-center" :class="{'text-warning': isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf() }">
                <Tooltip v-if="isQuoteWarning && new Date().valueOf() > new Date(formattedRetainerData.snoozeQuoteRateAlert || 0).valueOf()" class="no-underline flex" content='Quote rate not updated in over 365 days' position="top">
                    <RetainerExtendDropdown :clientId="formattedRetainerData.clientId" snoozeType="quote" @click="hideSnoozeQuoteRateAlert($event)" />
                    <span class="text-xs group-hover:inline hidden">{{ formattedRetainerData.lastQuoteEditDate }}</span>
                    <span class="text-xs group-hover:hidden inline">{{ convertTimeHelper(formattedRetainerData.lastQuoteEditDate) }}</span>
                </Tooltip>
            </span>
            
        </td>
        <td class="py-4 px-2 relative" v-click-outside-element="close">
            <span v-if="!formattedRetainerData.archived" @click="handleShowHappinessDropdown" class="text-[30px] flex justify-center cursor-pointer">
                <HappinessRenderer :value="formattedRetainerData.happiness"/>
            </span>
            <span v-if="formattedRetainerData.archived" class="text-[30px] flex justify-center">
                <HappinessRenderer :value="formattedRetainerData.happiness"/>
            </span>
            <div v-if="happinessDropdown" class="inline-flex p-3 mb-3 last:mb-0 border bg-white border-grey-200 rounded absolute left-1/2 transform -translate-x-1/2 top-12 z-10">
                <div @click="saveChanges(formattedRetainerData.clientId, 'v-sad', 'happiness')" class="inline-flex mr-1 last:mr-0">
                    <input id="v-sad" v-model="formattedRetainerData.happiness" name="happiness" type="radio" class="sr-only peer" value="v-sad" />
                    <label for="v-sad" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                        <IconHappinessVerySad />
                    </label>
                </div>
                <div @click="saveChanges(formattedRetainerData.clientId, 'sad', 'happiness')" class="inline-flex mr-1 last:mr-0">
                    <input id="sad" v-model="formattedRetainerData.happiness" name="happiness" type="radio" class="sr-only peer" value="sad" />
                    <label for="sad" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                        <IconHappinessSad />
                    </label>
                </div>
                <div @click="saveChanges(formattedRetainerData.clientId, 'moderate', 'happiness')" class="inline-flex mr-1 last:mr-0">
                    <input id="moderate" v-model="formattedRetainerData.happiness" name="happiness" type="radio" class="sr-only peer" value="moderate" />
                    <label for="moderate" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                        <IconHappinessModerate />
                    </label>
                </div>
                <div @click="saveChanges(formattedRetainerData.clientId, 'happy', 'happiness')" class="inline-flex mr-1 last:mr-0">
                    <input id="happy" v-model="formattedRetainerData.happiness" name="happiness" type="radio" class="sr-only peer" value="happy" />
                    <label for="happy" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                        <IconHappinessHappy />
                    </label>
                </div>
                <div @click="saveChanges(formattedRetainerData.clientId, 'v-happy', 'happiness')" class="inline-flex mr-1 last:mr-0">
                    <input id="v-happy" v-model="formattedRetainerData.happiness" name="happiness" type="radio" class="sr-only peer" value="v-happy" />
                    <label for="v-happy" class="inline-block cursor-pointer transition text-2xl p-0.5 border-2 peer-checked:border-[#FF4B40] rounded-full border-white opacity-40 peer-checked:opacity-100 hover:opacity-100 focus:opacity-100">
                        <IconHappinessVeryHappy />
                    </label>
                </div>
            </div>
        </td>
        <td class="py-4 px-2">
            <div class="flex items-center gap-1 relative">
                    <button :disabled="formattedRetainerData.archived" @click="handleTextboxPopup" :class="textboxPopup ? 'border-2 border-purple-900' : 'border border-gray-200 bg-gray-50' + formattedRetainerData.archived ? '': 'cursor-pointer'" class="flex items-center p-2.5 rounded">
                        <TextboxIcon v-if="!textboxPopup" class="w-3 h-3" />
                        <Cross
                        v-else
                            class="cursor-pointer w-3 h-3"
                        />
                    </button>
                    <div v-if="textboxPopup" class="absolute top-9 z-50 p-4 bg-white rounded border border-gray-200 shadow left-1/2 transform -translate-x-1/2">
                        <div class="flex items-center justify-between pb-2">
                            <p class="text-sm font-bold truncate max-w-[200px]">Notes: <span class="font-normal ">{{ formattedRetainerData.name }}</span></p>
                            <Cross
                            @click="handleTextboxPopup" 
                            class="cursor-pointer"
                            />
                        </div>
                        <Editor
                            v-model="currentNotes"
                            api-key="2m9gvnt4gi3wlqn57wwmcn24llv29udji8c93chueqi45q6k"
                            :init="{
                                menubar: false,
                                plugins: 'lists link',
                                toolbar: 'link bullist',
                                min_height: 200,
                                height: 200,
                                width: 228,
                                backgroundColor: 'white',
                                zIndex: 50
                            }"
                        />
                    <button @click="saveTextbox(formattedRetainerData.clientId)"  type="button" :class="isTextChanged ? 'inline-flex' : 'hidden'" class="transition float-right text-xs disabled:text-grey-300 disabled:bg-grey-100 items-center relative bg-orange-500 rounded mt-2 font-medium text-white py-3 px-6">
                        <span>Save</span>
                    </button>
                </div>
                <Dropdown text="Edit" :isDisabled="formattedRetainerData.archived" :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client' } }" dropdownClass="flex items-center px-4 py-2 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded-l text-xs">
                    <!-- <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Send email</button> -->
                    <router-link v-if="!formattedRetainerData?.archived" :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client' } }" class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Edit client</router-link>
                    <!-- <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">View client changelog</button> -->
                    <button @click="$emit('archiveClient', formattedRetainerData.clientId, !formattedRetainerData?.archived)" class="text-left border-b last:border-0 px-4 py-1.5 text-sm">{{ formattedRetainerData?.archived ? "Unarchive client" : "Archive client"}}</button>
                </Dropdown>
            </div>
        </td>
    </tr>
    <tr v-if="isActive" class="border-b border-grey-200">
        <td class="p-0" colspan="8">
            <div class="p-6 bg-grey-50">
                <div class="bg-white border border-bd rounded-lg p-4 grid grid-cols-7 gap-4">
                    <div class="text-sm col-span-1 flex flex-col gap-2">
                        <span class="block font-medium">Client</span>
                        <div>
                            <router-link :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client' } }" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">Edit</router-link>
                        </div>
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-3">Contacts</strong>
                        <div>
                            <div v-if="expandedContactView">
                                <UserDisplay
                                    v-for="contact in sortedClientUsers"
                                    :key="contact.uid"
                                    type="clientPageContact"
                                    class="w-full mb-1.5 last:mb-0"
                                    :imageUrl="contact.userSettings.profileImage ? contact.userSettings.profileImage : ''"
                                    :topText="`${contact.userSettings.firstName} ${contact.userSettings.lastName} ${ contact.userSettings.accountSettings ? `(${getGroupType(contact.userSettings.accountSettings)})` : ''}`"
                                    :bottomText="contact.userSettings.email"
                                    :userId="contact.uid"
                                    size="s"
                                />
                                <button @click="runExpandedContactView" class="font-medium text-gray-900 underline flex items-center justify-center text-xs mt-2 pb-4">Collapse <IconChevronDown class="-rotate-90 w-4 h-4 ml-1" /></button>
                                <div class="border-t border-gray-200">
                                    <strong class="block mb-3 pt-4">Account managers</strong>
                                    <UserDisplay
                                        v-for="contact in mappedActiveClientManagers"
                                        :key="contact?.uid"
                                        type="clientPageAdmin"
                                        class="w-full mb-1.5 last:mb-0"
                                        :imageUrl="contact?.profileImage ? contact?.profileImage : ''"
                                        :topText="`${contact?.firstName} ${contact?.lastName}`"
                                        :bottomText="contact?.email"
                                        :userId="contact?.uid"
                                        size="s"
                                    />
                                </div>
                            </div>
                            <div v-else @click="runExpandedContactView" class="flex -space-x-2 overflow-hidden cursor-pointer">
                                <div v-for="(contact, index) in filteredClientUsers.slice(0, 3)" :key="contact.uid">
                                    <div v-if="!contact.userSettings.profileImage" class="inline-block w-[36px] h-[36px] rounded-full ring-2 ring-white">
                                        <span class="overflow-hidden bg-black text-white rounded-full bg-center bg-cover inline-flex items-center justify-center w-[36px] h-[36px] text-xs">
                                            <UserAvatar :name="`${contact.userSettings.firstName} ${contact.userSettings.lastName}`" text-size="text-xs" />
                                        </span>
                                    </div>
                                    <img v-else class="inline-block w-[36px] h-[36px] rounded-full ring-2 ring-white" :src="contact.userSettings.profileImage ? contact.userSettings.profileImage : ''" alt="" />
                                </div>
                                <div v-if="filteredClientUsers.length > 3" class="font-medium w-[36px] h-[36px] rounded-full ring-2 ring-white bg-purple-900 text-white flex items-center justify-center text-xs">
                                    +{{ filteredClientUsers.length - 3 }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-1">Yearly goals</strong>
                        <div class="o-richtext prose prose-li:list-disc text-xs" v-html="formattedRetainerData.goals"></div>
                        <router-link :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client', scrollTo: 'goals' }}"  class="inline-block mt-1 underline font-medium hover:no-underline">
                            View history
                        </router-link>
                    </div>
                    <div class="text-xs col-span-2">
                        <strong class="block mb-1">Notes</strong>
                        <div class="o-richtext prose prose-li:list-disc text-xs" v-html="formattedRetainerData.notes"></div>
                        <router-link :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client', scrollTo: 'notes' }}" class="inline-block mt-1 underline font-medium hover:no-underline">
                            View history
                        </router-link>
                        <div class="border-t border-gray-200 mt-4 pt-4">
                            <strong class="block ">Meeting frequency goal</strong>
                            <span class="mt-1 inline-block">{{ GeneralHelper.convertFrequencyLabel(formattedRetainerData.meetingFrequency) }}</span>
                        </div>
                    </div>
                </div>
                <div class="bg-white border border-bd rounded-lg p-4 grid grid-cols-7 gap-4 mt-2" v-if="validRetainers && !loadingRetainers">

                    <div class="text-sm col-span-1 flex flex-col gap-2">
                        <span class="block font-medium">Retainer</span>
                        <div>
                            <router-link :to="{ name: 'Admin - Edit client', params: { clientId: formattedRetainerData.clientId || 'no-client', activePanel: 'services' } }" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">Edit</router-link>
                        </div>
                    </div>
                    <div class="text-xs col-span-6">
                        <table class="text-left w-full">
                            <thead class="border-b border-grey-200">
                                <tr>
                                    <th class="pb-2 w-52">Name</th>
                                    <th class="pb-2 w-56">Comments</th>
                                    <th class="pb-2 w-15">Rate</th>
                                    <th class="pb-2 w-20">Monthly value</th>
                                    <th class="pb-2 w-[100px]">Contract renewal</th>
                                    <th class="pb-2 w-20">Remaining</th>
                                    <th class="pb-2 w-5"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="retainer in retainerData" :key="retainer?.id" class="border-b border-gray-200 first-of-type:border-t ">
                                    <td class="py-2" :class="!(allRetainers.find(a => a.id === parseInt(retainer?.id))?.projectName) && 'text-red-500 font-bold'">
                                        <router-link class="hover:underline" :to="{ name: 'Beta Dashboard Reports Listing', params: { clientId: formattedRetainerData.clientId || 'no-client', retainerId: retainer?.id || 0, date: 'latest'} }">
                                            {{ allRetainers.find(a => a.id === parseInt(retainer?.id))?.projectName || "No Retainer Name" }}
                                        </router-link>
                                    </td>
                                    <td class="py-2">{{ retainer?.description }}</td>
                                    
                                    <td class="py-2">${{ retainer?.retainerRate || parseFloat(defaultPricing.retainer) }}</td>
                                    <td class="py-2">{{retainer?.value }}</td>
                                    <td
                                        class="py-2"
                                        :class="[Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 30 && 'text-warning', retainer?.contractExpiry == 'Invalid Date' && 'text-warning']"
                                    >
                                        <div class="flex items-center">
                                            <Tooltip v-if="retainer?.contractExpiry && Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 30 && Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) > 0" class="no-underline flex" content='Contract renewal within next 30 days' position="top">
                                                <IconWarning class="mr-1"/>
                                                {{ retainer?.contractExpiry && retainer?.contractExpiry !== "Invalid Date" ? retainer?.contractExpiry : "No contract expiry" }}
                                            </Tooltip>
                                            <Tooltip v-if="retainer?.contractExpiry && Math.ceil((new Date(retainer?.contractExpiry).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 0" class="no-underline flex" content='Contract is expired' position="top">
                                                <IconWarning class="mr-1"/>
                                                {{ retainer?.contractExpiry && retainer?.contractExpiry !== "Invalid Date" ? retainer?.contractExpiry : "No contract expiry" }}
                                            </Tooltip>
                                            <Tooltip v-if="!retainer?.contractExpiry || retainer?.contractExpiry == 'Invalid Date'" class="no-underline flex" content='Contract renewal is not set' position="top">
                                                <IconWarning class="mr-1"/>
                                                {{ retainer?.contractExpiry && retainer?.contractExpiry !== "Invalid Date" ? retainer?.contractExpiry : "No contract expiry" }}
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td
                                        class="py-2"
                                        :class="retainer?.remaining"
                                    >
                                        <div class="flex items-center">
                                            <Tooltip v-if="retainer?.remaining && retainer?.remaining.includes('-')" class="text-warning no-underline flex" content='Negative retainer hours' position="top">
                                                <IconWarning class="mr-1"/>
                                                {{ retainer?.remaining }}
                                            </Tooltip>
                                            <Tooltip v-else class="no-underline flex" content='Retainer hours' position="top">
                                                {{ retainer?.remaining }}
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td class="py-2 text-right">
                                        <router-link :to="{ name: 'Beta Dashboard Reports Listing', params: { clientId: formattedRetainerData.clientId || 'no-client', retainerId: retainer?.id || 0, date: 'latest'} }" class="inline-flex border text-xs border-gray-200 items-center py-1.5 px-4 rounded bg-gray-50 cursor-pointer">Reports</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else-if="validRetainers && loadingRetainers" class="pt-2">
                    <Skeletor height="160" />
                </div>
            </div>
        </td>
    </tr>
</template>

<script>

    export default {
        props: ['propData', 'retainersList', 'defaultExpanded', 'defaultRetainers'],
        emits: ["editClient", "archiveClient"],
        data: function() {
            return {
                loadingRetainers: false,
                barData: this.retainerData ? this.retainerData.hours : {},
                isActive: this.defaultExpanded ? this.defaultExpanded : false,
                isQuoteWarning: false,
                isFrequencyTargetWarning: false,
                isUnscheduledWarning: false,
                retainerData: this.defaultRetainers || [],
                expandedContactView: false,
                happinessDropdown: false,
                textboxPopup: false,
                initialNotes: '',
                currentNotes: '',
                clientContacts: [],
                allUsers: [],
            }
        }, 
        computed: {
            isTextChanged() {
                return this.currentNotes !== this.initialNotes;
            },
            validRetainers() {
                const len = Object.keys(this.retainerData).length

                return this.retainerData && Object.keys(this.retainerData).length > 0
            },
            defaultPricing() {
                return store.getters.getAppSettings.services;
            },
            allRetainers() {
                return this.retainersList;
            },
            mappedActiveClientManagers() {
                const accountManagers = this.formattedRetainerData.accountManagers || {};
                const accountManagersArray = Object.keys(accountManagers).filter(uid => accountManagers[uid]);
                let users = [];
                accountManagersArray?.forEach((uid) => {
                    const user = this.allUsers.find(user => user?.uid === uid);
                    if(user) {
                        users.push({
                            uid: user?.uid,
                            firstName: user?.userSettings?.firstName,
                            lastName: user?.userSettings?.lastName,
                            email: user?.userSettings?.email
                        });
                    }
                })
                users = users.filter(user => user?.firstName && user?.lastName && user?.email);
                return users;
            },
            sortedClientUsers() {
                return [...this.filteredClientUsers].sort((a, b) => {
                    const aHasPrimary = Array.isArray(a.userSettings.accountSettings) && a.userSettings.accountSettings.includes('primary-contact');
                    const bHasPrimary = Array.isArray(b.userSettings.accountSettings) && b.userSettings.accountSettings.includes('primary-contact');

                    if (aHasPrimary && !bHasPrimary) {
                        return -1;
                    } else if (!aHasPrimary && bHasPrimary) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            },
            filteredClientUsers() {
                const items = this.allUsers.filter(user => {
                    try {
                        return !!this.formattedClientUsers?.includes(user.uid)
                    } catch {
                        return !!this.formattedClientUsers?.[user.uid]
                    }
                }).map(user => {
                    return {
                        ...user,
                        group: user.userSettings || ""
                    }
                }) || [];

                console.log(items);

                return items
            },
            formattedClientUsers() {
                // Get all users for this client from users.accountsContact, users.itContacts, and users.primaryContacts
                const clientContacts = this.formattedRetainerData.users || [];
                return clientContacts;
            },
            formattedRetainerData() {
                // Calculate if warning state based on time
                if (!this.propData) {
                    return {}
                }

                // Calculate closest previous & future meeting.
                let previousMeetingUnix = 0;
                let futureMeetingUnix = 0;
                if(this.propData.meetings && this.propData.meetings.length > 0) {
                    const dateArrays = this.propData.meetings?.map(a => {
                        return new Date(a.date).setHours(a.time.hours, a.time.minutes, a.time.seconds);
                    });
                    const allPreviousMeetings = dateArrays.filter(a => a < Math.floor(new Date().getTime())).sort((a, b) => b - a);
                    if (allPreviousMeetings.length > 0) {
                        previousMeetingUnix = allPreviousMeetings[0];
                    }
                    const allFutureMeetings = dateArrays.filter(a => a > Math.floor(new Date().getTime())).sort((a, b) => a - b);
                    if (allFutureMeetings.length > 0) {
                        futureMeetingUnix = allFutureMeetings[0]
                    }
                } 


                if(previousMeetingUnix > 0 && futureMeetingUnix > 0) {
                    // Calculate if frequency target is met
                    const thisDateFrequencies = staticData.dateFrequencies.find(a => a.value === this.propData.meetingFrequency)?.calculateDayValue ?? 0;
                    const daysDifference = Math.ceil((futureMeetingUnix - previousMeetingUnix) / (1000 * 3600 * 24))
                    if(thisDateFrequencies < daysDifference) {
                        this.isFrequencyTargetWarning = true;
                    }
                } 

                this.isQuoteWarning = convertTimeHelper(this.propData.lastQuoteEditDate, true) > 365 ? true : false;
                
                let contacts = [];


                if (this.propData.users && typeof this.propData.users === "object") {
                    contacts = Object.keys(this.propData.users).filter(userId => this.propData.users[userId] === true);
                }

                return {
                    ...this.propData,
                    previousMeeting: previousMeetingUnix > 0 ? `${new Date(previousMeetingUnix).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} ${new Intl.DateTimeFormat("en-GB", { timeStyle: "short", hour12: true }).format(new Date(previousMeetingUnix))}` : "—",
                    futureMeeting: futureMeetingUnix > 0 ? `${new Date(futureMeetingUnix).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })} ${new Intl.DateTimeFormat("en-GB", { timeStyle: "short", hour12: true }).format(new Date(futureMeetingUnix))}` : null,
                    lastEditDate: convertTimeHelper(this.propData.lastEditDate),
                    lastQuoteEditDate: `${new Date(this.propData.lastQuoteEditDate).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}`,
                    contacts
                }
            },
            userInitials() {
                const initString = this.avatarName;
                const name = initString.split(' ');
                return name.length > 1 ? `${name[0].charAt(0).toUpperCase()}${name[1].charAt(0).toUpperCase()}` : `${name[0].charAt(0).toUpperCase()}${name[0].charAt(1).toUpperCase()}`
            }
        },
        mounted() {
            if (this.isActive) {
                this.getRetainerData()
            }
            if (this.defaultExpanded) {
                const el = this.$refs.listItem;

                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
            }
            const db = tenantDatabase();
            let userRef;
            userRef = ref(db, `/users`)


            onValue(userRef, snapshot => {
                let rawData = snapshot.val();
                if (rawData) {
                    let formattedData = Object.keys(rawData).map((key) => {
                        return {
                            uid: key,
                            userSettings: rawData[key]
                        }
                    });
                    this.allUsers = formattedData;
                }
                this.isLoading = false;
            });
        },
        created() {
        },
        methods: {
            getGroupType(types) {
                if (!Array.isArray(types)) {
                    console.log('getGroupType was called with a non-array argument:', types);
                    return '';
                }

                const typeNames = {
                    'primary-contact': 'Primary',
                    'it-contact': 'IT',
                    'accounts-contact': 'Accounts',
                }

                const typeOrder = ['primary-contact', 'accounts-contact', 'it-contact'];

                types.sort((a, b) => typeOrder.indexOf(a) - typeOrder.indexOf(b));

                return types.map(type => typeNames[type]).join(', ');
            },
            saveTextbox(clientId) {
                const db = tenantDatabase();
                const clientRef = ref(db, `clients/${clientId}`);

                update(clientRef, {
                    notes: this.currentNotes
                });

                this.$notify({
                    title: "",
                    text: "Notes successfully saved",
                    duration: 5000,
                });

                this.handleTextboxPopup();
            },
            saveChanges(clientId, clickedHappiness, saveType) {
                const db = tenantDatabase();
                const clientRef = ref(db, `clients/${clientId}`);
                
                if (saveType === "happiness") {
                    this.happinessDropdown = !this.happinessDropdown;
                    update(clientRef, {
                        happiness: clickedHappiness
                    });
                    LogAuditLog({
                        type: "Client",
                        object: "ClientHappiness",
                        action: "edit",
                        data: {status: clickedHappiness, clientId: clientId, id: clientId}
                    })
                }
            },
            handleShowHappinessDropdown() {
                this.happinessDropdown = !this.happinessDropdown
            },
            close() {
                this.happinessDropdown = false
            },
            handleTextboxPopup() {
                this.textboxPopup = !this.textboxPopup;
                if (this.textboxPopup) {
                    this.currentNotes = this.formattedRetainerData.notes;
                    this.initialNotes = this.formattedRetainerData.notes;
                }
            },
            closeTextboxPopup() {
                this.textboxPopup = false;
            },
            handleToggleItem() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.getRetainerData()
                } else {
                    this.$router.replace({'query': {'viewing': null}});
                }
            },
            async getRetainerData() {
                const { formattedRetainerData } = this;
                const db = tenantDatabase();
                const auth = await getAuth();
                const dbRef = ref(tenantDatabase());

                this.loadingRetainers = true;

                try {
                    const formattedRetainers = await Promise.all(
                        (formattedRetainerData.retainers ? Object.keys(formattedRetainerData.retainers) : []).map(async (retainerId) => {
                            const res = await get(child(dbRef, `retainers/${retainerId}`));

                            const retainer = {}

                            const data = res.val();
                            retainer.id = retainerId;
                            retainer.plans = data?.plans ? this.sortPlansByDateRolloverFrom(data?.plans) : [];
                            retainer.description = data?.description || "";
                            retainer.value = data?.value || 0;
                            retainer.contractExpiry = data?.contractExpiry || null;
                            retainer.startDate = data?.startDate || null;
                            retainer.retainerRate = data?.retainerRate || parseFloat(this.defaultPricing.retainer);
                            retainer.description = retainer.plans?.length ? retainer.plans[0].comment : "";

                            await axios({
                                method: 'post',
                                url: `${this.$root.apiBaseUrl}/retainer/stats/${retainerId}`,
                                data: [{ month: new Date().getMonth() + 1, year: new Date().getFullYear() }],
                                headers: {
                                    'authorization': auth.currentUser.accessToken,
                                    'timezone': this.$root.apiTimezone
                                },
                                params: {
                                    clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
                                }
                            })
                            .then(res => {
                                retainer.remaining = res.data[0].listData.find(item => item.itemName == "Remaining").itemValue;
                            })
                            .catch(err => {
                                console.log(`Error retrieving stats for retainer ${retainerId}:`, err);
                            });

                            return retainer;
                        })
                    );

                    this.loadingRetainers = false;

                    this.retainerData = formattedRetainers;
                } catch (error) {
                    console.log("Error fetching retainer data:", error);
                    this.loadingRetainers = false;
                }
            },
            sortPlansByDateRolloverFrom(plans) {
                return plans.sort((a, b) => new Date(b.rolloverFrom) - new Date(a.rolloverFrom));
            },
            hideSnoozeQuoteRateAlert(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                    this.isQuoteWarning = false
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            hideSnoozeNextMeetingAlert(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                    this.isFrequencyTargetWarning = false
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            handleOpenDropdown(e) {
                let targetClassList = e.target.closest('.js-dropdown').classList;
                if(targetClassList.contains('is-active')) {
                    targetClassList.remove('is-active')
                } else {
                    const dropdowns = document.getElementsByClassName('js-dropdown');
                    for(let item of dropdowns) {
                        item.classList.remove('is-active');
                    }
                    targetClassList.add('is-active')
                }
            },
            runExpandedContactView() {
                this.expandedContactView = !this.expandedContactView
            }
        }
    };

</script>
<style scss>
    .o-richtext.prose li::marker {
            color: #000 !important;
    }
</style>