<script setup>

import { getAuth, tenantDatabase } from "@/tenant";
    import { getDatabase, ref, update, onValue, query, orderByChild, equalTo } from "firebase/database";
    import axios from 'axios';
    import store from '@/store';

    import Container from '@/components/containers/Container.vue';
    import Loading from '@/components/containers/Loading.vue';
    import ArrowLeft from '~icons/my-icons/arrow-left';
    import ProjectValueToLabel from '@/helpers/ProjectValueToLabel';
    import DashboardEditItem from '@/components/DashboardEditItem.vue';
    import GetFormattedClient from "@/helpers/GetFormattedClient";

	import { Skeletor } from "vue-skeletor";

</script>

<template>
  <div class="relative">
    <div class="py-9 px-8 bg-white border-b border-solid border-bd">
        <Container>
            <div class="w-full flex justify-between items-center text-3xl font-bold">
                <router-link :to="{ name: 'Beta Dashboard Reports Listing', params: { retainerId: $route.params.retainerId, date: $route.params.date }}">
                    <span class="inline-flex items-center">
                        <ArrowLeft class="mr-8 text-xl" />
                        <span>Send report</span>
                    </span>
                </router-link>
            </div>
        </Container>
    </div>
    <div class="pt-20 pb-20">
        <Container>
            <div class="max-w-[840px] mx-auto border border-solid border-bd rounded-lg bg-white u-content-wrapped">
                <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Loading...'">
                    <div class="flex items-center px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold">
                            Subject
                        </div>
                        <div class="text-grey-900 flex-1">
                            <strong>
                                {{ProjectValueToLabel($route.params.retainerId)}} - {{$route.params.date}} Retainer Report
                            </strong>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 pb-4 border-b border-grey-200 last:border-none">
                        <div class="w-5 pr-6 text-grey-300 text-xs font-bold mt-2">
                            To
                        </div>
                        <div class="text-grey-900 flex-1">
                            <span class="block mb-3">
                                <DashboardEditItem theme="dark"
                                    name="contacts"
                                    label=""
                                    input-type="clientSelect"
                                    :filterUsers="mappedClientUsers"
                                    :isActiveLabel="isActiveLabelPrimary"
                                    :filteredClientAccounts="filteredClientContacts()" 
                                    @handleAddUser="userId => addUser(userId)"
                                    @handleRemoveUser="userId => removeUser(userId)"/>
                            </span>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Retainer details
                        </div>
                        <div class="text-grey-900 flex-1">
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Used:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.billableHours || 0}}hrs</div>
                                <Skeletor v-else class="rounded" width="32" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Remaining:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.remainingHours}}hrs</div>
                                <Skeletor v-else class="rounded" width="48" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Rollover to {{nextMonth}}:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.rolloverNextMonth}}hrs</div>
                                <Skeletor v-else class="rounded" width="48" height="20" />
                            </div>
                            <div class="mb-1 flex last:mb-0">
                                <div class="w-48 flex-shrink-0 mr-3">Tasks:</div>
                                <div v-if="!loadingSelectedMonthTile">{{selectedMonthTileData.tasks}}</div>
                                <Skeletor v-else class="rounded" width="24" height="20" />
                            </div>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Message
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => reportMessage = evt.target.value" :value="reportMessage" class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add message"></textarea>
                        </div>
                    </div> 
                    <div class="flex px-10 py-6 border-b border-grey-200 last:border-none">
                        <div class="w-40 pr-6 text-grey-300 text-xs font-bold mt-1">
                            Notes
                        </div>
                        <div class="text-grey-900 flex-1">
                            <textarea @input="evt => reportNotes = evt.target.value" :value="reportNotes"  class="w-full border border-bd rounded p-3 min-h-[180px]" placeholder="Add note"></textarea>
                        </div>
                    </div> 
                    <div class="flex items-center justify-end px-10 py-6 border-b border-grey-200 last:border-none">
                        <router-link :to="{name: 'Beta Dashboard Reports Listing', params: { date: retainer ? retainer.reportMonth : 'latest' }}" class="text-blue-500 mr-6">
                            Cancel
                        </router-link>
                        <button 
                            class="ml-3 bg-orange-500 rounded text-white py-3 px-6 inline-flex items-center disabled:text-grey-300 disabled:bg-grey-100"
                            @click="handleSendReport()"
                            :disabled="!hasSelectedUser" 
                        >
                            Send
                        </button>
                    </div> 
                </Loading>
            </div>
        </Container>
    </div>
  </div>
</template>

<script>

    const defaultDetailsObject = {
        name: "",
        description: "",
        logo: "",
        urls: "",
        rate: 0,
        happiness: "moderate",
        notes: "",
        goals: "",
        meetingFrequency: "",
        meetings: [
            {
                date: '',
                time: ''
            }
        ],
        retainers: [
            // defaultRetainerObject
        ],
        lastEditDate: "",
        accountManagers: [],
        users: []
    }

  export default {
    props: ['retainerData'],
    data() {
      return {
          allLinkedContacts: [],
          appSettings: {},
          retainer: null,
          isSubmitting: false,
          reportMessage: '',
          reportNotes: '',
          selectedMonthTileData: [],
          loadingSelectedMonthTile: true,
          details: defaultDetailsObject,
      }
    },
    async mounted() {
        this.getMonthTileData();

        await this.fetchRetainerUsers()

        if(store.getters.getAppSettings) {
            this.appSettings = {...store.getters.getAppSettings}
        }

        const db = tenantDatabase();
        if (this.$route.params.clientId) {
            const clientUsersRef = query(ref(db, 'users'), orderByChild("client"), equalTo(this.$route.params.clientId));

            onValue(clientUsersRef, snapshot => {
                let rawData = snapshot.val();
                if (rawData) {
                    let formattedData = Object.keys(rawData).map((key) => {
                        return {
                            uid: key,
                            userSettings: rawData[key]
                        }
                    });
                    this.allLinkedContacts = formattedData;
                }
            });
        }
    },
    computed: {
        nextMonth() {
            const now = new Date(this.$route.params.date)
            now.setMonth(now.getMonth() + 1);
            return now.toLocaleString('en-us', { month: 'long' })
        },
        getReportSubject() {
            const { retainer } = this;

            if(!retainer) {
                return ''
            }
            return `${retainer.retainerName.trim()} - ${retainer.reportMonth.trim()} Retainer Report`
        },
        mappedClientUsers() {
            return this.allLinkedContacts.map(user => {
                return {
                    uid: user?.uid,
                    firstName: user?.userSettings?.firstName,
                    lastName: user?.userSettings?.lastName,
                    label: `${user?.userSettings?.firstName} ${user?.userSettings?.lastName}`,
                    email: user?.userSettings?.email,
                };
            });
        },
        hasSelectedUser() {
            return this.filteredClientContacts()?.length;
        }
    },
    methods: {
        isActiveLabelPrimary(user) {
            return this?.filteredClientContacts().map(filteredUid => filteredUid.uid).includes(user);
        },
        filteredClientContacts() {
            const filteredUsers = this.allLinkedContacts?.filter(user => {
                return user?.userSettings?.client == this.details.clientId;
            });

            return filteredUsers?.map(user => {
                return {
                    uid: user?.uid,
                    firstName: user?.userSettings?.firstName,
                    lastName: user?.userSettings?.lastName,
                    email: user?.userSettings?.email,
                };
            });
        },
        addUser(id) {
            if (!this.details.users) {
                this.details.users = []; // Initialize `users` as an empty array
            }

            this.details.users[id] = true

            if (!this.allLinkedContacts.find(user => user.uid === id).userSettings?.accountSettings) {
                this.allLinkedContacts.find(user => user.uid === id).userSettings.accountSettings = [];
            }

            this.allLinkedContacts.find(user => user.uid === id).userSettings.client = this.details.clientId;
        },
        removeUser(id) {

            this.allLinkedContacts.find(user => user.uid === id).userSettings.client = ''

        },
        async fetchRetainerUsers() {
            if (this.$route.params.clientId) {
                this.details = await GetFormattedClient(this.$route.params.clientId);
            }
        },
		async getMonthTileData() {
			this.loadingSelectedMonthTile = true
			const auth = await getAuth();

			if(auth.currentUser) {
				const currentToken = auth.currentUser.accessToken;
				let currentFilterDate = new Date(`${this.$route.params.date}-1`);
				let month = currentFilterDate.getMonth() + 1;
				let year = currentFilterDate.getFullYear();

				const result = await axios.get(`${this.$root.apiBaseUrl}/Retainer/${this.$route.params.retainerId}/${year}/${month}`, {
					headers: {
						'authorization': currentToken,
						'timezone': this.$root.apiTimezone
					}
				}).then(res => {
					const selectedTile = res.data;

					return selectedTile;
				}).catch(err => {
					console.log(err);
				})
				this.selectedMonthTileData = result
				this.loadingSelectedMonthTile = false
			}
		},
        async handleSendReport() {

            const retainerId = this.$route.params.retainerId;

            if(!retainerId) {
                alert('no retainer specified');
                return;
            }

            const auth = await getAuth();
			auth.onAuthStateChanged(user => {
                if(user) {
                    this.isSubmitting = true;
					const currentToken = auth.currentUser.accessToken;
                    const activeMonth = new Date(this.$route.params.date)
                    const emails =  this.filteredClientContacts().map(s => s.email);
					var req = {
						message:this.reportMessage,
						notes:this.reportNotes,
						used: this.selectedMonthTileData.billableHours,
                        rollover: this.selectedMonthTileData.rolloverNextMonth,
                        remaining: this.selectedMonthTileData.remainingHours,
                        tasks: this.selectedMonthTileData.tasks,
                        subject: `${ProjectValueToLabel(this.$route.params.retainerId)} - ${this.$route.params.date} Retainer Report`,
                        projectId: retainerId,
                        year: activeMonth.getFullYear(),
                        month: activeMonth.getMonth() + 1,
                        url: `${window.location.origin}/dashboard/${this.$route.params.clientId}/retainer/${retainerId}/reports/listing/${this.$route.params.date}`,
                        emails: emails
                    };
					
					axios.post(`${this.$root.apiBaseUrl}/email/report`, req, {
						headers: {
							'authorization': currentToken,
							'timezone': this.$root.apiTimezone
						}
					}).then(res => {

                        const db = tenantDatabase();
                        const updates = {};
                        const retainerHistoryData = {
                            sentBy: user.displayName,
                            sentDate: new Date(),
                            recipients: emails
                        };

                        updates[`/retainerReportHistory/${this.$route.params.retainerId}/${activeMonth.getFullYear()}-${activeMonth.getMonth() + 1}`] = retainerHistoryData;
                        update(ref(db), updates).then(res => {
                            //this.notification = 'Success';
                            this.$notify({
                                title: "",
                                text: "Report has been successfully sent",
                                duration: 5000,
                            });
                            this.isSubmitting = false;
                            this.$router.push({
                                name: 'Beta Dashboard Reports Listing',
                                params: { date: this.$route.params.date }
                             });
                        }).catch(err => {
                            console.log(err);
                            this.isSubmitting = false;
                        });
						
					}).catch(err => {
                        this.isSubmitting = false;
						console.log(err);
					});					
				}
			});
        }
    }
  }
</script>

<style lang="scss">
    
</style>
