import { getAuth, tenantDatabase } from "../tenant";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import getFirebaseConfig from "./GetFirebaseConfig";

import LogAuditLog from "@/helpers/logAuditLog";

export default async function deleteUser(uid, user) {

    const app = getApp();
    const functions = getFunctions(app);
    const db = tenantDatabase();

    const deleteUser = httpsCallable(functions, "deleteUser");

    await deleteUser({ uid: uid })
    await set(ref(db, `users/${uid}`), null);

    if(user.retainer) {
        const promises = user.retainer?.map(item => new Promise( async resolve => {
            await set(ref(db, `retainerUsers/${item}/${uid}`), null);
            resolve(`Deleted user in retainer ${item}`);
        }));
        await Promise.all(promises);
    }

    LogAuditLog({
        type: "User",
        object: user?.permission == 'admin' ? "User" : "Contact",
        action: "delete",
        data: {
            id: uid,
            ...user
        }
    })

    return {
        status: 'success'
    }

}
