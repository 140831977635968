<script setup>
	import { getAuth, tenantDatabase } from "@/tenant";
	import Header from '@/components/objects/Header.vue';
	import AuthValidation from '@/components/containers/AuthValidation.vue';
	import { getDatabase, ref, onValue } from "firebase/database";
	import {authTokenUpdate} from '@/helpers/AuthTokenUpdate';

</script>

<template>
	<div class="text-grey-900 w-full">
		<AuthValidation>
			<Header />
			<router-view></router-view>
		</AuthValidation>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				auth: null,
			}
		},
		async mounted() {
			// Setup user token updater
			const auth = await getAuth();
			auth.onAuthStateChanged(currentUser => {

					if(currentUser) {
						// check if currentUser is admin
						
						const attrs = JSON.parse(currentUser.reloadUserInfo.customAttributes)

						const db = tenantDatabase();
						const userRef = ref(db, `/users/${currentUser.uid}`);

						onValue(userRef, async snapshot => {

							this.currentUser = await authTokenUpdate();
						});

						if(attrs.permission === 'admin') {
							return
						}
						this.$router.push({ name: 'Retainer Dashboard', params: { retainerId: 0, clientId: attrs.client } });
					}
			});
		}
	}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

