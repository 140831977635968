<script setup>

import axios from 'axios';
import { getDatabase, get, child, ref, onValue, update } from "firebase/database";
  import { Skeletor } from "vue-skeletor";
  import Dropdown from '@/components/objects/Dropdown.vue';
    import Tile from '@/components/objects/Tile.vue'

    import { getAuth, tenantDatabase } from "@/tenant";
  import PermissionsHelpers from '@/helpers/PermissionHelpers';
  import SimpleShortDate from '@/helpers/SimpleShortDate';

  import ChevronRight from '~icons/my-icons/chevron-right';
  import ChevronLeft from '~icons/my-icons/chevron-left';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Navigation } from 'swiper/modules';
  import Info from '~icons/my-icons/Info';

  import Tooltip from '@/components/objects/Tooltip.vue';
</script>

<template>
  <tr class="retainer-list-item" ref="listItem">
    <td class="py-4 px-4 flex w-full">
      <div @click="toggleOpened()" class="w-[36px] h-[36px] shrink-0 cursor-pointer flex items-center justify-center mr-6 rounded-full border border-grey-200 text-grey-600 bg-grey-50">
        <ChevronRight :class="{'rotate-90': checkIsOpened}" class="transition h-4 w-4 transition-500 text-sm" />
      </div>
      <div class="w-full min-w-0">
        <p class="text-sm font-medium truncate min-w-[240px] w-full">{{ retainer.projectName }}</p>
        <p v-if="retainer.client.name" class="text-xs">{{ retainer.client.name }} <span v-if="retainer.clientName.toLowerCase() !== retainer.client.name.toLowerCase()">({{ retainer.clientName }})</span></p>
        <Tooltip
          v-else
          class="flex"
          content="There is no client attached to this retainer, you can link a retainer on the Clients Overview Page."
          position="top"
        >
          <p class="text-xs text-red-500 font-bold">{{ "No client attached" }} ({{ retainer.clientName }})</p>
        </Tooltip>
      </div>
    </td>
    <td class="py-4 px-4">
      <p class="text-center text-xs pr-6">
        {{ retainer.monthlyRetainer || 0 }}hrs
      </p>
    </td>
    <td class="py-4 px-4">
      <p class="text-center text-xs">
        {{ retainer.nonBillable || 0 }}hrs
      </p>
    </td>
    <td class="py-4 px-4">
      <p class="text-center text-xs">
        {{ retainer.billable || 0 }}hrs <span class="text-grey-300">/</span> {{ retainer.allowance || 0 }}hrs
      </p>
    </td>
    <td class="py-4 px-4 relative">
      <div
        class="text-center text-xs py-1 px-2 border font-bold rounded inline-flex justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute"
        :class="retainer.remaining >= 0 || !retainer?.remaining ? 'border-grey-200 text-purple-900' : 'bg-red-50 border-red-100 text-red-500'"
      >
        {{ retainer.remaining || 0 }}hrs
        <Tooltip
          v-if="showOverspend > 0"
          class="flex"
          position="top"
        >
          <Info class="ml-1 w-4 h-4 shrink-0" />
          <template v-slot:htmlContent>
            <div class="text-center">
              <span>Includes <span class="font-bold">{{ overspendHours }}hrs (${{ overspendPrice }})</span> invoiced to client <br /> for overhours.</span>
              <div v-for="(invoice, invoiceId) in retainer.overspendInvoices" :key="invoiceId">
                <div class="my-1 divide-y space-y-1 divide-purple-500">
                  <div class="text-xs font-bold">{{ invoice.invoiceNumber }} ({{ SimpleShortDate(invoice.effectiveDate) }})</div>
                  <div class="text-xs">{{ invoice.description }}</div>
                </div>
              </div>
            </div>
          </template>
        </Tooltip>
      </div>
    </td>
    <td class="py-4 px-4">
      <p v-if="retainer.unPublishedTasksNumber > 0" class="text-center text-warning text-xs font-medium">{{ retainer.unPublishedTasksNumber }}</p>
      <p v-else class="text-center text-purple-900">-</p>
    </td>
    <td class="py-4 px-4 flex justify-end">
      <Dropdown text="View report" :to="{name: 'Beta Dashboard Reports Listing', params: {date: `${date.year}-${date.month + 1}`, clientId: retainer.client.clientId || 'no-client', retainerId: retainer.id || 0}}" dropdownClass="flex items-center px-4 py-2 border text-grey-600 bg-grey-50 hover:bg-grey-100 disabled:border-grey-50 disabled:bg-grey-50 disabled:text-grey-200 border-grey-200 rounded-l text-xs">
        <template v-if="retainer.client.clientId">
          <router-link :to="{name: 'Admin - Retainer Overview - Edit client', params: { clientId: retainer.client.clientId }}">
            <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Edit client/retainer</button>
          </router-link>
          <router-link :to="{name: 'Admin - Beta clients', query: { viewing: retainer.client.clientId || null } }">
            <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">View client</button>
          </router-link>
        </template>
        <template v-else>
          <Tooltip
            class="flex"
            content="There is no client attached to this retainer, you can link a retainer on the Clients Overview Page."
            position="top"
          >
            <button class="cursor-default bg-grey-50 text-purple-900/50 w-full text-left border-b last:border-0 px-4 py-1.5 text-sm">Edit client/retainer</button>
          </Tooltip>
          <Tooltip
            class="flex"
            content="There is no client attached to this retainer, you can link a retainer on the Clients Overview Page."
            position="top"
          >
            <button class="cursor-default bg-grey-50 text-purple-900/50 w-full text-left border-b last:border-0 px-4 py-1.5 text-sm">View client</button>
          </Tooltip>
        </template>
        <router-link :to="{name: 'Admin - Retainer Overview - Add invoice', params: { retainerId: retainer.id || 0, clientId: retainer.client.clientId || 'no-client' } }">
          <button class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Log overspend invoice</button>
        </router-link>
        <button @click="syncMonth(retainer.id)" class="text-left border-b last:border-0 px-4 py-1.5 text-sm">Sync</button>
      </Dropdown>
    </td>
  </tr>
  
  <tr v-if="checkIsOpened" class="group">
    <td colspan="7" class="p-6 border-y group-last:border-b-0 group-last:rounded-b-lg bg-grey-50 border-grey-200">
      <div v-if="!loading" class="relative">
        <div class="swiper-action cursor-pointer absolute z-50 -left-5 top-1/2 -translate-y-1/2" :class="(swiper?.activeIndex || 0) <= 0 && 'opacity-50'" @click="swiper.slidePrev()">
          <ChevronLeft />
        </div>
        <swiper
          :slides-per-view="4"
          :space-between="16"
          @swiper="onSwiper"
        >
          <swiper-slide
            :key="tile.title"
            v-for="(tile) in sortedTileData"
          >
            <div>
              <Tile :data="tile" :retainerId="retainer.id || 0" :clientId="retainer.client.clientId || 'no-client'"/>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-action cursor-pointer absolute z-50 -right-5 top-1/2 -translate-y-1/2" :class="(swiper?.activeIndex || 0) >= (sortedTileData?.length - 4 || 0) && 'opacity-50'" @click="swiper.slideNext()">
          <ChevronRight />
        </div>
      </div>
      <div v-else class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <skeletor class="rounded-md" v-for="item in 4" :key="item" :count="4" :height="412" />
      </div>
    </td>
  </tr>
</template>

<script>


export default {
    props: {
      retainer: {},
      date: {},
    },
    data: function () {
        return {
          isOpened: false,
          tileData: [],
          swiper: null,
          loading: true,
          availableOverspendInvoices: {},
          allUsers: []
        }
    },
    created() {
    },
    async mounted() {
      if(this.$route.query.viewing == this.retainer.id || this.$route.query.search == this.retainer.projectName.trim()) {
        this.getStatistics();

        const el = this.$refs.listItem;

        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
      }

      this.loadAvailableOverspendInvoices();

      const db = tenantDatabase();
      let userRef;
      userRef = ref(db, `/users`)

      onValue(userRef, snapshot => {
        let rawData = snapshot.val();
        if (rawData) {
          let formattedData = Object.keys(rawData).map((key) => {
            return {
              uid: key,
              userSettings: rawData[key]
            }
          });
          this.allUsers = formattedData;
        }
        this.isLoading = false;
      });
    },
    computed: {
      checkIsOpened() {
        return this.$route.query.viewing == this.retainer.id || this.$route.query.search == this.retainer.projectName.trim() || this.isOpened;
      },
      sortedTileData() {
        return (this.tileData || []).sort((a,b) => {
          return new Date(b.title) - new Date(a.title)
        });
      },
      showOverspend() {
        return Object.keys(this.retainer?.overspendInvoices)?.length > 0
      },
      overspendHours() {
        return Object.keys(this.retainer?.overspendInvoices)?.reduce((acc, key) => {
          return acc + parseFloat(this.retainer?.overspendInvoices[key].hoursBilled)
        }, 0)
      },
      overspendPrice() {
        return Object.keys(this.retainer?.overspendInvoices)?.reduce((acc, key) => {
          return acc + parseFloat(this.retainer?.overspendInvoices[key].value)
        }, 0)
      },
    },
    methods: {
      loadAvailableOverspendInvoices() {
        const db = tenantDatabase();
        const overspendInvoicesRef = ref(db, `/overspendInvoices`);

        onValue(overspendInvoicesRef, snapshot => {
          if(snapshot.val()) {
             this.availableOverspendInvoices = snapshot.val()[this.retainer.id];
          } else {
            this.availableOverspendInvoices = {}
          }
        });
      },
      onSwiper(swiper) {
        this.swiper = swiper
      },
      async syncMonth(retainerId){
        const auth = await getAuth();
        auth.onAuthStateChanged(user => {
        if(user) {
          const currentToken = auth.currentUser.accessToken;
          let currentFilterDate = new Date(`${this.$route.params.date}-1`);

          let from = `${currentFilterDate.getFullYear()}-${currentFilterDate.getMonth() + 1}-1`;
          let nextMonth = new Date(currentFilterDate.getFullYear(), currentFilterDate.getMonth() + 1, 1);
          let to = `${nextMonth.getFullYear()}-${nextMonth.getMonth() + 1}-1`;
          this.isLoading = true;
          axios.get(`${this.$root.apiBaseUrl}/import/projectTimeRecords/${retainerId}/${from}/${to}`, {
            headers: {
              'authorization': currentToken,
              'timezone': this.$root.apiTimezone
            },
            params:{
              onlyApproved :this.syncOnlyApproved
            }
          }).then(res => {
          }).catch(err => {
            console.log(err)
            this.isLoading = false;
          });
        }});
      },
      toggleOpened() {
        if (this.$route.query.viewing == this.retainer.id) {
          this.$router.replace({ query: undefined });
        } else {
          this.isOpened = !this.isOpened;
        }

        if(this.isOpened) {
          this.getStatistics();
        }
      },
			async getStatistics(){
        this.loading = true;

				var filter = [];
				var today = new Date();
				var d;

				for(var i = 11; i >= 0; i -= 1) {
					d = new Date(this.date.year, this.date.month - i, 1);
					filter.push({
						month: d.getMonth() + 1,
						year: d.getFullYear()
					});
				}

				const auth = await getAuth();
				auth.onAuthStateChanged(user => {
					if(user) {
						const currentToken = auth.currentUser.accessToken;

						axios({
							method: 'post',
							url: `${this.$root.apiBaseUrl}/retainer/stats/${this.retainer.id || 0}`,
							data: filter,
							headers: {
								'authorization': currentToken,
								'timezone': this.$root.apiTimezone
							},
              params: {
                clientView: PermissionsHelpers.isWebcodaAdmin() ? 'false' : 'true'
              }
						}).then(res => {
							this.tileData = res.data;
							this.loading = false;
						}).catch(err => {
							console.log(err);
						});

					}});

			},

    }
};

</script>

<style>

  .retainer-list-item {
    scroll-margin-top: 64px;
  }

  .swiper-action {
    background-color: rgba(249, 249, 249, 1);
    border: 1px solid rgba(223, 223, 223, 1);
    border-radius: 1000px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>