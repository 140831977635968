<script setup>

    import { updatePassword, signInWithEmailAndPassword, isSignInWithEmailLink, signInWithEmailLink, verifyPasswordResetCode, confirmPasswordReset, signOut } from "firebase/auth";
    import { getApp } from "firebase/app";
    import { getAuth, tenantDatabase } from "@/tenant";
    import { getFunctions, httpsCallable } from "firebase/functions";
    import { getDatabase, ref, update, set } from "firebase/database";
    import Container from '../containers/Container.vue';
    import Header from '../objects/Header.vue';
    import Button from '../objects/Button.vue';
    import Loading from '../containers/Loading.vue';
    import Tick from '~icons/my-icons/tick';

    import firebaseSettings from '../../data/firebaseConfig';
    import axios from "axios";
    import BgImg from '../../assets/images/login-bg.png';

</script>

<template>
    <div class="pt-20 pb-20 flex-1 min-h-screen flex items-center w-full bg-cover sm:bg-auto bg-no-repeat bg-center bg-purple-900" :style="{ backgroundImage: `url('${BgImg}')`}">
        <div class="pb-20 w-full flex-1 flex items-center justify-center">
            <div v-if="notifications?.message.length > 0" class="absolute top-12 left-1/2 -translate-x-1/2 max-w-[480px] w-full mx-auto bg-orange-100 rounded-lg px-4 py-4 mb-8 flex items-center">
                <div class="self-start flex-shrink-0 mt-1 text-white mr-3 rounded-full bg-orange-400 w-7 h-7 inline-flex items-center justify-center text-sm">
                    !
                </div>
                <div class="pl-3 text-xs flex-1"><p>{{notifications.message}}</p></div>
                <button class="self-start ml-6 mr-0 font-medium text-[9px] text-orange-400" @click="notifications.message = ''"><Close/></button>
            </div>
            <Loading :isLoading="isSubmitting" type="overlay" :loadText="'Submitting...'">
                <Container class="w-full flex items-center justify-center">
                    <div class="flex-1 flex border border-bd rounded-lg border-solid p-16 bg-white max-w-[620px] w-full">
                        <form v-if="!isSubmitted" ref="form">
                            <h1 class="text-2xl font-bold mb-16 text-center">
                                {{ actionMode == 'resetPassword' ? "Reset password" : "Set up your account" }} 
                            </h1>
                            <div class="mb-6 relative" v-if="actionMode !== 'resetPassword'">
                                <label>
                                    <input :disabled="isDisabled" required v-model="email" id="email" type="email" class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Email address" /> 
                                </label>
                            </div>
                            <div class="mb-6 relative">
                                <label>
                                    <input :disabled="isDisabled" required @input="handlePasswordUpdate" :value="password" id="password" type="password" class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Password" /> 
                                </label>
                            </div>
                            <div class="mb-6 relative" v-if="actionMode == 'resetPassword'">
                                <label>
                                <input :disabled="isDisabled" required @input="handleConfirmPassword" :value="confirmPassword" id="confirmPassword" type="password" class="block rounded border border-solid border-bd w-full h-12 px-4 focus:outline-0 text-grey-500 placeholder-grey-500" placeholder="Confirm Password" /> 
                                </label>
                                <div class="ml-1">
                                    <div class="error text-red-500 mt-3 text-xs" v-if="error.confirmPassword">
                                        The password does not match the above password.
                                    </div>
                                </div>
                            </div>
                            <div class="ml-1">
                                <div class="mt-3 text-xs">
                                    Password must have a minimum of eight characters, at least one uppercase letter, one lowercase letter and one number.
                                </div>
                                <div class="error text-red-500 mt-3 text-xs" v-if="error.password">
                                    Please enter a password which satisfies the above requirements.
                                </div>
                            </div>
                            <div class="mt-8 text-center">
                                <button v-if="actionMode == 'resetPassword'" :disabled="(!validatePassword() || !validateConfirmPassword) || isSubmitting" type="button" class="transition-all mt-4 py-3 px-10 bg-orange-500 rounded text-white w-full disabled:text-grey-300 disabled:bg-grey-100" @click="submitForm">
                                    Submit 
                                </button>
                                <button v-else :disabled="!validatePassword() || isSubmitting || invalidOob" type="button" class="transition-all mt-4 py-3 px-10 bg-orange-500 rounded text-white w-full disabled:text-grey-300 disabled:bg-grey-100" @click="submitForm">
                                    Register
                                </button>
                            </div>
                        </form>
                        <div v-else class="w-full flex-1 text-center">
                            <h1 class="text-2xl font-bold mb-16 text-center">
                                <span class="text-white mr-3 rounded-full bg-green-600 w-10 h-10 inline-flex items-center justify-center text-lg">
                                    <Tick />
                                </span>
                                {{confirmationBox.title}}
                            </h1>
                            <p>{{confirmationBox.description}}</p>
                        </div>
                    </div>
                </Container>
            </Loading>
        </div>
    </div>
</template>

<script>
    
    export default {
        props: [],
        data() {
            return {
                email: "",
                password: "",
                confirmPassword: "",
                isFormValid: false,
                isSubmitting: false,
                isSubmitted: false,
                isDisabled: false,
                error: {
                    password: false,
                    confirmPassword: false
                },
                notifications: {
                    message: ''
                },
                invalidOob: false,
                missingEmail: false,
                actionMode: null,
                confirmCode: null,
                confirmationBox: {
                    title: '',
                    description: ''
                } 
            }
        },
        computed: { 
            validateConfirmPassword() {
                if(this.password === this.confirmPassword) {
                    this.error.confirmPassword = false;
                    return true;
                }
                this.error.confirmPassword = true;
                return false;
            }
        },
        created() {
        },
        async mounted() {                  
            const url = new URL(window.location.href);
            this.actionMode = url.searchParams.get("mode");
            this.confirmCode = this.actionMode === 'resetPassword' ? url.searchParams.get("oobCode") : null;
            
            if (this.actionMode == "signIn"){
                const auth = await getAuth();
                if (isSignInWithEmailLink(auth, window.location.href)) {
                        
                    this.email = url.searchParams.get("email");
                    
                    signInWithEmailLink(auth, this.email, window.location.href).then((result) => {

                        axios.get(`${this.$root.apiBaseUrl}/account/activation/${result.user.uid}`, {
                            headers: {   
                                'authorization': result.user.accessToken,                             
                                'timezone': this.$root.apiTimezone
                            }
                        }).then(res => {
                            
                        }).catch(err => {
                            console.log(err);
                        });	
                    
                    }).catch((error) => {
                        switch(error.code) {
                            case 'auth/missing-email':
                                this.missingEmail = true;
                                this.notifications.message = `Your email could not be found and automatically populated into the email field.
                                        Please enter the exact email that the registration was sent to manually below.`;
                                break;
                            case 'auth/invalid-action-code':
                                this.invalidOob = true;
                                this.isDisabled = true;
                                this.notifications.message = `The link you are currently using has expired. 
                                        Please contact an administrator for a new link to complete the registration.`;
                                break;
                            default: 
                        }
                    });
                }
            } else if(this.actionMode == "setupWithPassword") {
                const auth = await getAuth();
                auth.onAuthStateChanged(user => {
                    if(user) {
                        this.email = user.email;
                        this.notifications.message = `Please set up your account by updating the password to your account.`;
                    }
                });
            } else if(this.actionMode == "resetPassword") {
                this.notifications.message = `Please set up your account by updating the password to your account.`;
            } else {
                this.isDisabled = true;
                this.notifications.message = `The link you are currently using to access this page is not valid. 
                        Please contact an administrator for a new link to complete the registration.`;
            }
        },
        methods: {
            handlePasswordUpdate(e) {
                this.password = e.target.value;
                if(this.password.length === 0) {
                    this.error.password = false;
                    return;
                }
                this.error.password = !this.validatePassword() ? true : false;
            },
            handleConfirmPassword(e) {
                this.confirmPassword = e.target.value;
                if(this.confirmPassword === this.password) {
                    this.error.confirmPassword = false;
                } else {
                    this.error.confirmPassword = true;
                }
            },
            validatePassword() {
                // Password validation
                if(this.password.length > 0) {
                    const regexString = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/g;
                    if (!regexString.test(this.password)) {
                        this.error.password = true;
                        this.isFormValid = false;
                        return false;
                    } 
                } else {
                    this.error.password = false;
                    this.isFormValid = false;
                    return false;
                }
                this.error.password = false;
                return true;
            },
            async submitForm() {

                this.isFormValid = true;
                this.error.password = false;
                const form = this.$refs.form;
                
                if(this.actionMode === 'resetPassword') {
                    !this.validatePassword() && !this.validateConfirmPassword ? this.isFormValid = false : '';
                } else {
                    !this.validatePassword() ? this.isFormValid = false : '';
                }
                
                if(this.isFormValid && form.reportValidity()) {

                    this.isSubmitting = true;

                    if(this.actionMode === 'resetPassword') {

                        if(this.confirmCode) {
                            const auth = await getAuth();

                            verifyPasswordResetCode(auth, this.confirmCode).then((email, uid) => {
                                const accountEmail = email;

                                confirmPasswordReset(auth, this.confirmCode, this.password).then(() => {
                                    
                                    this.isSubmitted = true;
                                    this.isSubmitting = false;
                                    this.confirmationBox.title = 'Password reset complete.';
                                    this.confirmationBox.description = 'Your password has now been reset. You will now be re-directed to the Login page.';
                                    
                                    signInWithEmailAndPassword(auth, accountEmail, this.password).then(res => {
                                    
                                        const user = res.user;                            
                                        const db = tenantDatabase();
                                        const updates = {};

                                        updates[`/users/${user.uid}/initPasswordUpdated`] = true;
                                        update(ref(db), updates).then(res => {
                                            axios.get(`${this.$root.apiBaseUrl}/account/activation/${user.uid}`, {
                                                headers: {   
                                                    'authorization': user.accessToken,                             
                                                    'timezone': this.$root.apiTimezone
                                                }
                                            }).then(res => {
                                                signOut(auth).then(() => { }).catch((error) => { });
                                                setTimeout(() => {
                                                    this.$router.push({name: "Login"});
                                                }, 4000);
                                                
                                            }).catch(err => {
                                                console.log(err);
                                            });	
                                        });
                                    });
                                    
                                }).catch((error) => {
                                    this.isSubmitting = false;
                                    console.log(error)
                                });
                            });
                        }

                    } else if(this.actionMode === 'setupWithPassword') {
                        const auth = await getAuth();
                        
                        auth.currentUser.getIdTokenResult(true).then(tokenResult => {
                            const uid = tokenResult.claims.user_id;
                                
                            const app = getApp();
                            const functions = getFunctions(app);
                            updatePassword(auth.currentUser, this.password).then(() => {
                                
                                const verifyUser = httpsCallable(functions, "verifyUser");
                                verifyUser({ uid: uid, data: { uid: uid, tenantId: auth.tenantId } }).then(result => {
                                    
                                    this.isSubmitting = false;
                                    this.isSubmitted = true;
                                    this.confirmationBox.title = 'User setup complete.';
                                    this.confirmationBox.description = 'Redirecting you back to the login page. Please log in with your new password.';
                                    setTimeout(() => {
                                        signOut(auth).then(() => {
                                            this.$router.push({name: "Login"});
                                        });
                                    }, 4000);
                                }).catch(err => {
                                    this.isSubmitting = false;
                                    
                                    console.log(err)
                                });
                            }).catch(err => {
                                this.isSubmitting = false;
                                console.log(err);
                            });

                        }).catch(err => {
                            this.isSubmitting = false;
                            console.log(err);
                        });

                    } else {
                        
                        const auth = await getAuth();
                        if(this.missingEmail) {
                            const result = await signInWithEmailLink(auth, this.email, window.location.href);
                            await axios.get(`${this.$root.apiBaseUrl}/account/activation/${result.user.uid}`, {
                                headers: {   
                                    'authorization': result.user.accessToken,                             
                                    'timezone': this.$root.apiTimezone
                                }
                            });
                        }

                        auth.currentUser.getIdTokenResult(true).then(tokenResult => {
                            const uid = tokenResult.claims.user_id;
                                
                            const app = getApp();
                            const functions = getFunctions(app);

                            updatePassword(auth.currentUser, this.password).then(() => {
                                const verifyUser = httpsCallable(functions, "verifyUser");
                                verifyUser({ uid: uid, data: { uid: uid } }).then(result => {
                                    this.isSubmitting = false;
                                    this.isSubmitted = true;
                                    this.confirmationBox.title = 'User setup complete.';
                                    this.confirmationBox.description = 'Redirecting you back to the login page. Please log in with your new password.';
                                    setTimeout(() => {
                                        signOut(auth).then(() => {
                                            this.$router.push({name: "Login"});
                                        });
                                    }, 4000);
                                }).catch(err => {
                                    this.isSubmitting = false;
                                    console.log(err)
                                });
                            }).catch(err => {
                                this.isSubmitting = false;
                                console.log(err);
                            });

                        }).catch(err => {
                            this.isSubmitting = false;
                            console.log(err);
                        });
                    }

                }
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
